// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

// TODO: Import MUI icon
import { ArrowRight } from '@mui/icons-material';

const ListText = ({ lines, sxIcon,sxText }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component ListText Rendered');

    // TODO: Return component
    const RenderListItems = ({ lines, marginLeft }) => (
        <>
            {
                lines.map((item, i) => (
                    Array.isArray(item) ?
                        <RenderListItems key={i} lines={item} marginLeft={marginLeft + 4} /> :
                        <ListItem key={i} sx={{ alignItems: 'start', ml: marginLeft, p: 0 }}>
                            <ListItemIcon sx={{ minWidth: 'auto' }}>
                                <ArrowRight sx={{color:'primary.main',...sxIcon}} />
                            </ListItemIcon>
                            <ListItemText sx={{ my: 0, ...sxText }}>{item}</ListItemText>
                        </ListItem>
                ))
            }
        </>
    );

    return (
        <List dense={false} sx={{ pt: 0 }}>
            <RenderListItems lines={lines} marginLeft={0} />
        </List>
    );
}

export default React.memo(ListText);