// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Divider, List, Stack, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';

const BoxRecommended = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxRecommended Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const books = [
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20241015_3.jpg',
            Title: '變動思維(作者：艾波.瑞妮)',
            Intro: [
                '本書的內容主要講述人的思維其實是可以隨著時代轉變而變動的，作者鼓勵大家接受一些現有的狀態，並積極向前。其中一個題目講述「勇於迷途」，作者說：「在改變的風景之中，你得先迷失，才能找到方向。」亦提到「人生一半的樂趣來自於『迷失』」。「迷失」對很多人來說可能是一個負面的形容詞去表達自己的狀態。但作者卻以擁抱和積極的方向去解讀「迷失」，讓讀者有所啟發，是以另一個角度去思考人生。人生的確常有「迷失」的時候，此書能鼓勵讀者怎樣在未知的前路上有著勇敢的態度去面對。',
            ],
            Teacher: '劉欣欣老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20241015_2.jpg',
            Title: '誰在收藏中國(作者：吳樹)',
            Intro: [
                '中國是世界上文物流失最嚴重的國家之一！聯合國教科文組織表明，全球47個國家200多座博物館中，記錄在案的中國文物就有167萬件，而流散在海外民間的約為這個數目的10倍。20世紀80年代以後盜墓走私出境的文物，幾乎涵蓋所有文物種類，包括書法、繪畫、瓷器、陶器、雕塑、銅像等各類珍品。在分佈上，主要在英國、法國、美國、日本等國家，大英博物館就館藏中國文物2.3萬多件，其中不乏國寶級的珍品和絕品。日本1000餘座大小博物館共收藏中國歷代文物約200萬件，德國各大博物館總數大約30萬件，中國到底有多少文物流失海外，現存何處？確切的數字恐怕永遠是個謎。',
            ],
            Teacher: '高永儀老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20241015_1.jpg',
            Title: '放課後(作者：東野圭吾)',
            Intro: [
                '自從看了東野圭吾的《解憂雜貨店》之後，不禁一口氣追看他十多本的作品，他的小說已經令我欲罷不能。《放課後》是他早期的作品，是一校園推理故事，劇情佈局縝密細緻，結局精彩叫好。東野圭吾的小說除了寫實推理外，亦不乏對親情的描寫和對社會議題的控訴，題材創新多變，部分作品更曾被改編為電視劇或電影。東野圭吾現已年過60歲，但仍每年堅持推出新的推理小說，是一位小說天才。',
            ],
            Teacher: '黃偉豪老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20231117_1.jpg',
            Title: '圖解微人格心理學：教你發現身體裡另一個自己(作者：倪慢慢)',
            Intro: [
                '生活中我們常常遇到不同的人，每個人都擁有不同且獨特的人格。我們的人格更會成為我們的處世之道。例如：我們待人接物的方式都會被我們的人格有所影響。當一個樂觀者遇見新認識的人的時候，他傾向會主動和笑面迎人，希望帶給對方正面、舒服的感覺。相反，一個悲觀者認識新朋友的時候，他心裡可能惆悵著不同的負面思想，生怕別人不喜歡自己，因而變得被動。',
                '書本中分析了不同的人格，讓我們從心理學的角度學習和知道不同的人格擁有的特質，從而了解這些人格背後的動機和想法。不但可以更全面地認識自己，還可以了解到身邊的人的性格特質，以及他人的內心感受，有助我們培養同理心，更了解別人的需要。',
            ],
            Teacher: '陳子湘老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20231117_2.jpg',
            Title: '許三觀賣血記(作者：余華)',
            Intro: [
                '各位同學對捐血一詞可能耳熟能詳，但同學曾聽說過賣血嗎?同學能想像到這世界有人靠賣血維生嗎?書中主角許三觀一生苦難不斷。他為了渡過一個個難關，只能不顧身體健康，選擇多次賣血以換取微薄的金錢。多次的賣血令他身體出現各種毛病，但他為了親人，仍咬牙堅持，叫人不禁為之心惻。翻開本書，你將看到一位歷盡風塵的老人，正將他坎坷的一生向你娓娓道來。',
            ],
            Teacher: '朱亮老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20231117_3.jpg',
            Title: '記憶像鐵軌一樣長(作者：余光中)',
            Intro: [
                '本書摘錄了余教授二十篇文學散文，主要於八十年代創作，其描述豐富多變且容易入口，表達之感情淺白，用字細膩。最吸引我的地方是教授描寫景物之技巧，以《牛蛙記》為例，作者透過與牛蛙的叫聲對抗，抒發對鄉郊地區之喜愛。利用文字使我深深感受到作者對牛蛙叫聲之厭惡，令我仿佛聽到牛蛙的嗚鳴，代入作者之處境。其他篇章如《吐露港上》、《飛蛾山頂》等亦有對景物生動的描述，喜愛幻想和風景的同學定必喜愛。',
            ],
            Teacher: '劉得聰老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20231117_4.jpg',
            Title: '有一種禮物叫痛苦(作者：鄭凱尹)',
            Intro: [
                '作者Constance夢想成為空中小姐，中學畢業後便前往瑞士留學。可是，在留學期間，卻發現自己患上癌症。書中講述她抗癌四年多，接受了數十次電療、化療，雖然身體飽受痛苦，但她從未放棄。她只祈禱能有堅強的心去面對，沒有埋怨，心靈強壯。雖然她不能再實現夢想，但還是鼓勵自己要堅強，要有信心，要更愛惜生命。她希望透過這本書告訴大家，痛苦也可以是一份禮物，當人在痛苦中學會真正去愛人。她不是心情好的時候才去愛，她連沒有力氣的時候，也選擇愛身邊的人，因為她相信愛能跨越一切。心態決定境界，不一樣的態度帶來不一樣的結果，相信樂觀面對一切是好有力量的。',
            ],
            Teacher: '謝麗芬老師',
        },
        {
            Cover: 'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/REC20231117_5.jpg',
            Title: '往食只能回味(作者：蘇美璐)',
            Intro: [
                '《往食只能回味》是一本緬懷舊時美食的書。我們今天不愁衣食，自然不懂珍惜食物，這可從香港每日棄置的廚餘數量可見。在書中，作者回憶兒時美味的小食，將童年對小食萬分喜愛的神態寫得生動傳神，加上作者親筆的插畫，增加閱讀趣味。這書令我最感動的是，作者記述父母和自己及弟妹分享小食的溫馨情節，充滿濃厚的親情。我相信作者珍惜的不只是傳統小食，還有中國傳統文化最重視的親情。',
            ],
            Teacher: '阮竹權老師',
        },
    ];

    // TODO: Define event

    // TODO: Return component
    const RenderStackBook = ({ book }) => (
        <Stack sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ alignItems: 'start', display: 'flex', gap: 2, mb: 1 }}>
                <BoxImage
                    src={book.Cover}
                    sx={{ aspectRatio: 'auto', objectFit: 'contain', width: 120, }}
                    sxSkeleton={{height:'170px',width:'120px'}}
                />
                <Stack>
                    <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }} variant="body1">{book.Title}</Typography>
                    {
                        book.Intro.map((text, i) => (<Typography key={i} sx={{ mb: 1 }} variant="body2">{text}</Typography>))
                    }
                    <Typography sx={{ color: 'grey', }} variant="body2">
                        {`
                            ${Text.LearningAndTeacher.PromotionReading.Recommended.Teacher[context.language]}: 
                            ${book.Teacher}
                        `}
                    </Typography>
                </Stack>
            </Box>
            <Divider />
        </Stack>
    );

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.Recommended.Title[context.language]}
            </Typography>
            <List sx={{ width: 1 }}>
                {books.map((book, i) => (<RenderStackBook key={i} book={book} />))}
            </List>
        </Box>
    );
};
export default BoxRecommended;