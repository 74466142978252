// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';

import BoxItems from './ExchangeTour/BoxItems';

import Slide from './Slide_Activities.jpg';


const ExchangeTour = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component ExchangeTour Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="ExchangeTour"
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <BoxItems />
        </BoxSubPage>
    );
};
export default ExchangeTour;