// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// TODO: Import MUI module
import {  } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { fetchData } from '../Common/Context';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxContent from './BoxContent';
import DialogImage from './DialogImage';

const Album = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Album Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const [searchParams] = useSearchParams();
    const ID = searchParams.get('ID');

    const [info, setInfo] = React.useState([]);

    const [src, setSrc] = React.useState(null);

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'Album_GetInfo');
        formData.append('ID', ID);
        fetchData(formData, (result) => { setInfo(result) });
    }, [ID]);

    const handleDialogClosed = () => { setSrc(null) }


    // TODO: Return component
    return (
        <BoxSubPage
            id="Album"
            image={`https://www.pohtyh.edu.hk/Files/Album/${ID}/Slide.jpg`}
            disableMenu={true}
            disableSubtitle={true}
            title={info.eventName}
        >
            {
                Object.keys(info).length > 0 &&
                <BoxContent info={info} onClick={setSrc} />
            }
            {
                src &&
                <DialogImage src={src} onClose={handleDialogClosed} />
            }
        </BoxSubPage>
    );
};
export default Album;