// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../../Common/Context';

export default function Intro() {
    // TODO: Define useContext

    // TODO: Define variable
    const [searchParams] = useSearchParams();

    const [intro, setIntro] = React.useState('');

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'GetIntro');
        formData.append('id', searchParams.get('id'));

        fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => {
                setIntro(result);
            })
            .catch((error) => { console.error('Error:', error); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: Define function

    // TODO: Return component
    return (
        intro.length > 0 &&
        <Box sx={{ m: 2 }}>
            <Typography
                color="success.dark"
                component="p"
                gutterBottom
                sx={{ fontWeight: 'bold' }}
                variant="h5">
                簡介
            </Typography>
            <Typography
                component="p"
                gutterBottom
                sx={{ color: '#000' }}
                variant="body"
            >
                {intro}
            </Typography>
        </Box>

    );
}