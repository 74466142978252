// TODO: Import React module
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay } from 'react-swipeable-views-utils-react-18-fix';

// TODO: Import MUI module
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

// Import context

// TODO: Import custom component

export default function BoxTimelapse() {
    // TODO: Define useContext

    // TODO: Define variable
    const [loading, setLoading] = React.useState(true);
    const [images, setImages] = React.useState([]);
    const [firstTime, setFirstTime] = React.useState();
    const [lastTime, setLastTime] = React.useState();
    const [activeStep, setActiveStep] = React.useState(0);
    const [progress, setProgress] = React.useState(0);

    // TODO: Define event
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const handleStepChange = (step) => {
        console.log(((step + 1) / images.length) * 100);
        setActiveStep(step);
        setProgress(((step + 1) / images.length) * 100);
    };

    React.useEffect(GetImages, []);

    // TODO Define function
    function GetImages() {
        setLoading(true);

        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetImages');

            fetch(
                'https://www.pohtyh.edu.hk/MyTYH5/webservices/EGarden.php',
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log('GetImages:', 'Data received');

                        setImages(result.images);
                        setFirstTime(result.first);
                        setLastTime(result.last);
                    } else {
                        console.log('GetImages:', 'Can not get data');
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })();
    }

    // TODO: Return component
    return (
        <>
            {
                loading &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 5,
                }}>
                    <CircularProgress />
                </Box>
            }
            {
                !loading &&
                <Box sx={{ flexGrow: 1, mt: 5 }}>
                    <Typography variant="subtitle2" sx={{ textAlign: 'center' }} >
                        {firstTime + ' - ' + lastTime} {'期間縮時攝影'}
                    </Typography>
                    <AutoPlaySwipeableViews
                        axis="x"
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        interval={100}
                    >
                        {images.map((step, index) => (
                            <div key={index}>
                                <Box
                                    component="img"
                                    sx={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/BlessedBotanicals/Timelapse/' + step}
                                    alt={step}
                                />
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <LinearProgress
                        sx={{ height: 25 }}
                        variant="determinate"
                        value={progress}
                    />
                </Box>
            }
        </>
    );
}