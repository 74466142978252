// TODO: Import React module
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

// TODO: Import MUI module
import { Box, Tabs, Tab, } from '@mui/material';

// TODO: Import MUI icon
import { AccountCircle } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows, } from '../Common/TypographyTYH';

const Recruit = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Recruit Rendered');

    // TODO: Define useContext
    //const context = React.useContext(Context);

    // TODO: Define variable
    const [value, setValue] = React.useState(0);

    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const orientation = isSmallScreen ? 'vertical' : 'horizontal';

    // TODO: Define event
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // TODO: Return component
    const BoxStudent1 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            //'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/羅子陽1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/羅子陽2.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/羅子陽3.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 0 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['整體來說尚算滿意，只是一些科目與預計成績有些出入，感到有點可惜。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['接下來將會升讀香港大學工程系，期待會有不錯的大學生活，也希望自己畢業後能成為一位出色的工程師 。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={[
                            '我認為長遠的規劃和堅持最重要。語文科目是我較弱的科目，覺得學習這些科目很吃力，但同時語文科是升讀大學的必要科目，於是由中五開始我便投放很多時間提升自己中、英文能力，即使辛苦，我也會堅持學習。',
                            '另外，保持積極樂觀的心態十分重要，就算學習總是遇到困難，只要咬咬牙就能過關，因為大部分的難關並沒有我們想像的那麼可怕，最終都是可以跨過去的。不要去擔憂我們改變不了的事情，應該要著眼我們現在力能所及的事情上。',
                        ]} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['我最想感謝我的家人，在準備DSE時我經歷了無數次的自我懷疑和焦慮，在我十分難過的時候，他們肯定了我的努力，說無論結果如何，都會支持我。有他們的鼓勵，我才能振作。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['希望你們在迷惘時能重新振作，堅持就是勝利，加油!']} />
                        <ContentTitle text={'6.你在物理和數學科取都得5* 成績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={[
                            '我在學習物理、數學時，都是追求完全理解其中的概念。若有題目不明白，我會問老師或觀看網上一些講解物理問題的影片，以求令自己完全明白相關的物理概念，只要明白其中原理，以後面對相似概念的題目就能得心應手。如果遇到太難的題目，一時三刻理解不了，我會先放下，之後再回頭看，不浪費時間糾纏。',
                            '此外，為了更透徹地學習數學、物理和化學這些科目，我有一本「錯題簿」，把做錯過的，值得再做的歷屆試題寫在上面，並且寫好解題思路，方便以後重溫。這個方法十分重要，因為在考DSE期間，通常只有一、兩日的時間準備下一科，要極快地調整狀態，然後預備下一科的考試，運用「錯題簿」就能更有重點地重溫，提高溫習效率。',
                        ]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    const BoxStudent2 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/吳錦樞1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/吳錦樞2.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/吳錦樞3.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/吳錦樞4.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 1 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['整體而言是滿意的，有幾科成績比預期好，知道結果後頗覺驚喜。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['我將在香港中文大學修讀生物醫學工程學，並透過中學累積的基礎知識以及自身在數理方面的天賦與熱誠，完成大學課程，將來在醫學或工程界繼續奮鬥，貢獻社會。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={['我覺得想取得好成績，最重要是學習的心態。良好的學習心態即是能刺激自己學習、進步的「樂業」心態。若想減輕自己對學習的厭惡，可以嘗試將整個學習過程視為一場遊戲，而你是其中一個要闖關的玩家，當中有許多挑戰、曲折，在克服難關後，便會產生成就感和滿足感，畢竟這就是苦盡甘來的過程，你在過程中所承受的痛苦與得到的快樂將成正比，而恰好這種快樂是會上癮的。由此，你將步入「樂業」的狀況，結合我個人經驗，一旦在學習中尋到樂趣，在高中三年保持良好的學習狀態也就理所應當了。']} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['我想感謝所有教導過我的老師，你們的教導與陪伴像一塊塊拼圖造就現在的我，而我想特別鳴謝蔡睿溱老師，在我最困難、失落的時候，是你的關懷令我破碎的拼圖重新凝聚，並振作起來。在我生起放棄的念頭時，我的班主任兼中文老師溫澤謙老師送了一本書給我，雖然這本書不及老師的中文筆記，但卻令我感受到關懷，而到後來一次又一次的對話，以及額外用心的補課，讓我再次振作並堅持至今。也許我平時表現比較冷淡，沉默寡言，但我是真的真的很感謝你們，只是我不善言辭，心中的謝意在最後也只能化作一句簡單的「多謝」。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['在文憑試面前，學校大大小小的測驗和考試不過是人生盛宴的前菜罷了，不必因成績一時的好壞而喜或悲，希望你們能夠在這場沒有硝烟的戰爭凱旋回歸！']} />
                        <ContentTitle text={'6.你在中文科、物理科、化學科 取得5* 成績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={[
                            '中文科雖然有指定篇章，但最主要還是以考核理解能力和寫作能力為主，我除了不斷做歷屆文憑試試題和坊間不同的練習，提升答題能力外，也會在空閒的時候多看書，如多閱讀著名小說，以提升語文能力，效果可能因人而異，但對我而言有立竿見影的效果。',
                            '至於物理和化學科，我只是不斷做歷屆文憑試試題和不同的練習，但做過的選擇題和長題目加起來應該有數千題之多，因為就我個人而言，這兩科的題型已公式化，而且就算有新題型也只是用作選拔能取得5**的學生，整體上不會有太多變化，所以只要題目做得多，熟能生巧，便可應付絕大部分的文憑試題目。',
                        ]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    const BoxStudent3 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/王皓文1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/王皓文2.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/王皓文3.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/王皓文4.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 2 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['尚算滿意。有些科目成績比預期好，雖然亦有科目與預期有出入，不過整體上都滿意成績。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['我將升讀中文大學的地理與資源管理系，將來成為老師或者投身與地理相關的職業。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={['高中三年的學習比初中更需要用心。剛升上高中，我的成績上不算突出，中五開始訂立目標，下定決心，自此向着目標進發，不斷堅持同努力，最終取得理想成績。']} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['我希望感謝所有曾教導我的老師。其中我最想感謝溫澤謙老師，因為有溫老師，我才有決心同毅力堅持落去。他教導我中文5年，一開始中文幾乎是不及格的程度，但最後取得了4級的成績，全因溫老師的努力和堅持，才令我覺得自己更不應該放棄，所以真的很感謝溫老師在我中學生涯裡出現。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['要有恆毅力和決心！本來我是一個沒有決心，亦欠缺毅力的學生。當DSE考試的日子愈來愈迫在眉睫，我才醒覺到讀書的重要性，開始為自己訂立不同的目標，然後一直堅持，不斷努力，最後成功考取心目中的理想成績。所以，各位學弟學妹，大家記得好好努力，即使途中遇到大大小小的挫折，也只是微不足道的絆腳石，只要你不放棄，最終必定可以成功！']} />
                        <ContentTitle text={'6.你在旅遊與款待科取得5**成績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={['首先，要多謝林雯娣老師的筆記，旅遊與款待科需要記住的知識點十分多，亦要求十分準確，稍有差池就會失分。而我溫習的時候，會不斷重溫林老師的筆記，亦不斷去請教林老師，再不斷去審視自己在作答上的問題，然後加以改進。透過老師精心製作的筆記，我學習到更加多的酒店知識、地理名勝以及旅款科上不同的知識。另外，我也會上網去搜尋一些相關資訊，提升自己對旅遊與款待科不同知識層面的認知，並在考試中加以運用。',]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    const BoxStudent4 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/林倖而1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/林倖而2.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 3 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['滿意，與預期的成績差不多，而部分科目在考試結束後，其實已經大概能估計成績，所以做好了心理準備面對放榜。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['我將入讀香港中文大學的翻譯系，探索大學生活，作出更多嘗試，繼續探索不一樣的自己，也希望大學時期可以到不同的國家外遊，了解及認識不同文化。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={[
                            '對我而言，高中三年最難的並不是書本上的知識，而是如何平衡學業和課外活動的時間。兩者皆需要投入大量的時間和精力，起初我像初中時一樣，將頗多精力投放在課外活動上，上課的精神大不如前。直至老師來關心我，傾談之後，我才意識到自己根本無法應付太多的課外活動，於是重整了時間分配，以應付高中的DSE課程。',
                            '另外，我明白即使老師會在課堂安排試卷操練，但畢竟課堂的時間有限，所以我會在回家後整理當日所學，分析試卷中的難點，方便自己溫習。同時，我會利用學習日記記錄每天溫習的課題，確保自己跟上學習進度，亦可以在後期更快知道哪些課題需要再次重溫。',
                        ]} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['在準備應考文憑試的路上，我特別想感謝我的家人。我的父親在我感到迷惘或失去動力時，總會抽出時間和我聊天，聽我傾訴。他常說：「放鬆點，盡力就好了。」這句話我起初還不太懂，備試後期經常操練試卷的時候，我腦海經常想起這句說話，因為看著飄忽不定的分數，我只可以告訴自己盡力便無悔，繼續檢討自己的錯處。確實放鬆點會表現的較好，做卷的時候也可以更集中思考，避免不必要的錯處。很感謝我的家人願意聆聽我這一路上的煩惱，並鼓勵我繼續向前，我很高興能與他們分享這份喜悅。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['經歷完文憑試，我才明白許多人當初鼓勵我的說話。也許大家覺得面臨考試的緊張，只有考前幾個月才感受到，但這並不代表你不需要提前準備好自己。學習對許多人而言是一件難事，但無論最後結果如何，只要自己盡力就好了，只有不斷地努力才可以令自己無悔。希望各位可以在精彩豐富的中學生活找到自己的目標，努力實踐，在自己喜歡的領域大展所長，享受樂趣！']} />
                        <ContentTitle text={'6.你在中文科取得5* 成績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={['中文科能夠把握的分數集中在範文部分，因此我在考前做了許多範文題目，分析題目，並按照題目要求根據範文內容寫出答案，部分題目會需要引用文章的時代背景或作者背景，所以我亦會額外標註及記下，方便作答時使用。背誦好範文能夠在分析好題目後鎖定答題依據，引用原文解釋答案，在較短時間內完成，將餘下時間用於作答文言文和白話文部分。另外，我會抽取部分時間了解儒家和道家的文化思想，以便深入理解文章內容，方便在作答長題目作為拓展，亦能幫助列舉較合適的生活例子答題。',]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    const BoxStudent5 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/張拓䤭1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/張拓䤭2.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/張拓䤭3.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/張拓䤭4.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 4 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['滿意，今次的成績超出了我的預期，竟然能夠在旅遊及款待科取得5*，真的很意外和驚喜。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['接下來我打算報讀理工大學商業管理相關課程。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={['當上課和做功課，遇到任何不明白的地方，就要主動向老師請教，問清楚整個概念。而且在平時，我亦會主動購買補充練習和上網尋找一些學習資源，例如模擬試卷，定時做練習，令自己進步。當我遇到困難的時候，我不會放棄，會堅持學習新的知識。因為如果我放棄，沒有釐清最初步的概念的話，會影響之後的學習進度，長遠更會影響我學習的動機，所以有不明白地方我會去問清楚。',]} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['我特別想感謝我的班主任鄧雅文老師。因為當我遇到任何困難的時候，她都會叫我要相信自己，不要放棄。我憑著她這句說話，每一次做練習和溫習都不會放棄，成績不理想時，也會選擇相信自己是可以做到，我抱着這種心態，才能在文憑試中獲取好的成績。此外，我亦都想感謝任教我旅款科的林雯娣老師，每當我有問題的時候，她總會很有耐性地向我解釋，從而令我更加明白當中的概念，而且她還經常根據我做的練習，給予我非常具體的建議，讓我改善問題，令我能夠更進步。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['當你們遇到任何困難的時候，要記着「方法總比困難多」，要保持積極樂觀的心態去解決問題，不要逃避，困難很快會過去的。遇到任何功課上不明白的地方，要堅持下去，一定有很多方法去解決，切記不要放棄。']} />
                        <ContentTitle text={'6.你在旅遊及款待科取得5* 成績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={['在這個選修科上，有大量的課題和關鍵詞彙需要去背誦，同學們平時要多花時間去背誦，還需要理清整個概念，而且需要很細心，如果寫錯了一個關鍵字，那麼該題就可能沒有分了。我平時會花時間去背誦旅款知識，而且還會做大量的練習，之後我會交給老師核對批評，讓她給予意見，避免下次犯同樣的錯誤，令成績有所突破。而且這科需要我們從日常生活中吸收一些旅遊知識，例如多留意香港和外國的著名景點，也可以透過遊覽世界各地的過程中認識相關知識，所以我會鼓勵大家從日常生活中多連繫學習。',]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    const BoxStudent6 = ({ tabValue }) => {
        // TODO: Define variable
        const images = [
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙1.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙2.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙3.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙4.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙5.jpg',
            'https://www.pohtyh.edu.hk/Files/DSEOutstandingStudentVisit/2024/梅文熙6.jpg',
        ];

        // TODO: Return component
        return (
            <Box sx={{ display: tabValue === 5 ? 'block' : 'none', width: 1, }}>
                <BoxGridColumnsContainer>
                    <BoxGridColumnsFullWidth>
                        <ContentTitle text={'1.滿意成績嗎?是否預期之內呢?'} />
                        <MultipleRows rows={['滿意，今次的文憑試的表現比預期中更好。']} />
                        <ContentTitle text={'2.接下來有何人生目標?'} />
                        <MultipleRows rows={['接下來我會升讀廣州中醫藥大學中醫學系，全力學習關於中醫藥方面的課程，希望能學習到專業的知識和技能，同時滿足自己對中醫行業的熱情，亦期望能在日後在醫療行業懸壺濟世，幫助病人。此外，我亦會不斷學習有關中國玄學及武術的知識，讓自己有更廣闊的視野。']} />
                        <ContentTitle text={'3.能否分享一下學習心得?高中三年，如何保持良好的學習狀態?'} />
                        <MultipleRows rows={['我認為要認清自己的目標及要學會接受失敗。剛升上高中時，因為中四學習模式與初中並不相同，我在一時之間未能適應而令成績一落千丈，起初的我並不能接受失敗，後來因為得到身邊師長及朋友鼓勵，自己又尋找到人生志向，立志成為中醫師，因而重獲信心學習，亦令我有動力在高中三年中不斷努力學習。故此無論任何情況下，都不應因為一時的失敗失去動力，相反要學習面對失敗，定好目標，令自己不斷進步。',]} />
                        <ContentTitle text={'4.有沒有甚麼人想特別鳴謝?'} />
                        <MultipleRows rows={['我要感謝我身邊所有的師長及益友在這六年的陪伴。此外，我特別要鳴謝我的班主任兼英文老師劉子健老師，劉老師不只在教學方面非常優秀，也十分關愛學生。在學習上，劉老師提供的英文筆記內容非常豐富，有效地幫助我學習英文。更重要的是，劉老師在我考試失敗時會鼓勵我，使我重拾信心學習。在中六臨近公開試時，劉老師為了提升我們的英語會話能力，常會在百忙中抽空為我們不斷練習。在準備公開試時我一直非常大壓力，幸得劉老師的肯定和鼓勵，一句句的鼓勵說話把我從壓力的巨石下拉起。因此，我要特別感謝劉老師在這三年的教導和陪伴。']} />
                        <ContentTitle text={'5.鼓勵一下學弟學妹吧!'} />
                        <MultipleRows rows={['有不少的學弟學妹會為日後升上高中而感到擔憂，憂慮要承受沉重的學習壓力，但我們將來都會成為社會的棟樑，因此我希望學弟學妹能勇敢面對挑戰，只要願意堅持，一定能夠成功！']} />
                        <ContentTitle text={'6.你在化學科取得5佳績，可以分享一下你學習相關科目的方法嗎?'} />
                        <MultipleRows rows={['學習化學科要抱持不斷嘗試的心態和對化學的熱情。我自覺思維模式偏向文科，一時之間無法適應化學科的思考方式。幸好得到化學科吳老師的鼓勵及幫助，他提供內容豐富的化學筆記外更有海量的化學試卷，使我習慣化學科獨有的思考方式。仍記得吳老師會就每一個課題提供一份歷屆試題，一星期內要交回答卷，有時候為了完成答卷，我會一直操練到凌晨，雖說辛苦，但解題的快樂卻是令人回味。而在文憑試前有一個月多的溫習假期，我每天早上準時在7時半，校園開放時回到學校自修室一直學習到學校關門。在自修期間，吳老師會給予額外的練習並深入解釋困難的題目，讓我在考試前更熟習考試的答題技巧，最後我成功在文憑試中獲得佳績。',]} />
                    </BoxGridColumnsFullWidth>
                    {
                        images.map((image, i) => (
                            <BoxImage key={i} src={image} />
                        ))
                    }
                </BoxGridColumnsContainer>
            </Box>
        )
    };

    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            id="2024 DSE優秀學生訪問"
            title="2024 DSE優秀學生訪問"
        >
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' } }}>
                <Tabs
                    onChange={handleChange}
                    orientation={orientation}
                    sx={{ borderBottom: 1, borderColor: 'divider', mb: { xs: 0, md: 2 }, mr: { xs: 2, md: 0 } }}
                    value={value}
                    variant="scrollable"
                >
                    <Tab icon={<AccountCircle />} iconPosition="start" label="狀元 羅子陽" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                    <Tab icon={<AccountCircle />} iconPosition="start" label="榜眼 吳錦樞" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                    <Tab icon={<AccountCircle />} iconPosition="start" label="探花 王皓文" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                    <Tab icon={<AccountCircle />} iconPosition="start" label="林倖而" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                    <Tab icon={<AccountCircle />} iconPosition="start" label="張拓䤭" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                    <Tab icon={<AccountCircle />} iconPosition="start" label="梅文熙" sx={{ fontSize: { xs: '1rem', lg: '1.5rem' } }} />
                </Tabs>
                <BoxStudent1 tabValue={value} />
                <BoxStudent2 tabValue={value} />
                <BoxStudent3 tabValue={value} />
                <BoxStudent4 tabValue={value} />
                <BoxStudent5 tabValue={value} />
                <BoxStudent6 tabValue={value} />
            </Box>
        </BoxSubPage>
    );
}
export default Recruit;