// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

import Slide from './Slide_StudentDevelopment.jpg';

const StudentsUnion = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component StudentsUnion Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentsUnion/Img01.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentsUnion/Img02.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentsUnion/Img03.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentsUnion/Img04.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="StudentsUnion"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.StudentsUnion.Purpose.Title[context.language]}/>
                    <ListText lines={Text.StudentDevelopment.StudentsUnion.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.StudentsUnion.Vision.Title[context.language]}/>
                    <MultipleRows rows={Text.StudentDevelopment.StudentsUnion.Vision.Content[context.language]}/>
                </BoxGridColumnsFullWidth>
                {images.map((image, i) => (<BoxImage key={i} src={image} />))}
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default StudentsUnion;