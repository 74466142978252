// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Article, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';
import StackImage from '../../Common/StackImage';

const BoxItems = ({ onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxItems Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const coverages = [
        {
            Title: '聚焦大灣區 :‌粵港澳三地中小學校長分享經驗心得',
            Intro: '於11月29至30日在江門市新會市舉行的「粵港澳大灣區中小學校長交流活動」上，本校李慧校長作為香港其中一位校長代表，以《以中華文化之萃‧育至美至善之心》為題，向粵港澳三地多位教育代表和專家作報告。詳情請參閱相關報導。<a href="https://bau.com.hk/site/article/1312745190526738432/app/content_1312745190526738432.html?from=share" target="_blank">按此</a>參閱原文。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20241201.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20241201.pdf',
        },
        {
            Title: '仁愛堂田家炳小學 X 博愛醫院八十週年鄧英喜中學 地區推廣冰球的情感牽絆',
            Intro: '<a href="https://mag.sportsoho.com/%E4%BB%81%E6%84%9B%E5%A0%82%E7%94%B0%E5%AE%B6%E7%82%B3%E5%B0%8F%E5%AD%B8%20X%E5%8D%9A%E6%84%9B%E9%86%AB%E9%99%A2%E5%85%AB%E5%8D%81%E9%80%B1%E5%B9%B4%E9%84%A7%E8%8B%B1%E5%96%9C%E4%B8%AD%E5%AD%B8-%E5%9C%B0%E5%8D%80%E6%8E%A8%E5%BB%A3%E5%86%B0%E7%90%83%E7%9A%84%E6%83%85%E6%84%9F%E7%89%BD%E7%B5%86?fbclid=IwZXh0bgNhZW0CMTEAAR1E2k-u01AQz_HkMdEoZa27CYpnJGJd4n7GJmwr9Wi3SWXOspgqJD3kORM_aem_AT8HRAFwQoEr39vXxqhKjUETI1UDJ8nz_nX4knxRFkKMQkg2gYQX7ae25X3idABGFxRRwl--FIzt2hhWwWRTRRvl" target="_blank">按此</a>參閱原文。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20240425.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20240425.pdf',
        },
        {
            Title: '本校同學喜獲「博愛好聲音」青少年歌唱比賽冠軍',
            Intro: '「博愛好聲音」青少年歌唱比賽決賽已於2024年2月25日假元朗劇院演藝廳舉行，本校同學顏浩然於少年組及青年組勇奪冠軍。<a href="https://www.hk01.com/%E7%A4%BE%E5%8D%80%E5%B0%88%E9%A1%8C/995135/%E5%8D%9A%E6%84%9B%E5%A5%BD%E8%81%B2%E9%9F%B3-%E9%9D%92%E5%B0%91%E5%B9%B4%E6%AD%8C%E5%94%B1%E6%AF%94%E8%B3%BD2023-24-%E9%9D%92%E6%98%A5%E6%9C%89%E5%A4%A2-%E6%88%91%E5%8B%87%E6%95%A2%E5%94%B1?utm_source=01appshare&utm_medium=referral" target="_blank">按此</a>查閱報章內容。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20240301.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20240301「博愛好聲音」青少年歌唱比賽2023_24 青春有夢，我勇敢唱.pdf',
        },
        {
            Title: '校長專訪︱設冰球隊馬術班多元化活動　鄧英喜中學校長助學生找強項建自信發光發亮',
            Intro: '經濟日報早前到校訪問我校校長李慧女士。專訪當日，李校長分享她的教學理想，以及她從事教育30年來的點滴。記者們對一所津貼學校能開辦冰球隊、馬術班還有法文美食班，均感驚喜不已。<a href="https://topick.hket.com/article/3663156" target="_blank">按此</a>查閱報章內容。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20231201.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20231201TopickV2.mp4',
        },
        {
            Title: '華服學會專訪文章',
            Intro: '本校華服學會成員先前訪問旗袍師傅文英傑先生，相關專訪文章已刊登於雙月刊《文路》。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20220719.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20220719.png',
        },
        {
            Title: '英喜茶藝舍',
            Intro: '明報日前到訪本校，訪問校長和中文科老師有關英喜茶藝舍在學校的推行情況，訪問於家長資源中心內的茶藝舍進行。訪問當日，本校的兩位青少年茶藝師4A凌躍月和4E張雪瑩同學在接受訪問之外更即席獻技，展示中華傳統的茶藝。報道已於2022年1月4日於明報副刊刊登，<a href="https://news.mingpao.com/pns/%e5%89%af%e5%88%8a/article/20220104/s00005/1641232066559/%e6%95%99%e5%ad%b8%e6%9c%89%e8%be%a6%e6%b3%95-%e4%bb%a5%e8%8c%b6%e4%ba%a4%e6%b5%81-%e5%95%9f%e7%99%bc%e6%bd%9b%e8%83%bd-%e4%b8%ad%e5%ad%b8%e8%8c%b6%e8%97%9d%e8%88%8d-%e5%ad%95%e8%82%b2%e5%b0%91%e5%b9%b4%e8%8c%b6%e8%97%9d%e5%b8%ab" target="_blank">按此</a>查閱報章內容。',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20220104.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20220104.pdf',
        },
        {
            Title: '【校長專欄】「浪子」學生上課經常分心 鄧英喜中學校長鼓勵中文作文獲5**',
            Intro: '<a href="https://topick.hket.com/article/3031768/" target="_blank">報章連結</a>',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/Item20210813.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Achievements/MediaPressCoverage/20210813.pdf',
        },
    ];

    // TODO: Define event
    const handleClicked = (coverage) => {
        onClick(coverage);
    };

    // TODO: Return component
    const RenderStackImage = ({ coverage }) => (
        <StackImage onClick={() => { handleClicked(coverage) }} hover={true}>
            <BoxImage src={coverage.Thumb} />
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <Article sx={{ color: 'white' }} />
                {coverage.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                coverages.map((coverage, i) => (
                    <Grid key={i} xs={6}>
                        <RenderStackImage coverage={coverage} />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default React.memo(BoxItems);