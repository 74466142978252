// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_S1Admission.jpg';
//import BoxVideo from './S1Admission/BoxVideo';
import BoxSSPA from './S1Admission/BoxSSPA';

import BoxSubPage from '../Common/BoxSubPage';

const S1Admission = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component S1Admission Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            id="S1Admission"
            image={Slide}
            title={Text.Top.Menu['ADM'].Title[context.language]}
            subtitles={Text.Top.Menu['ADM'].Subtitles}
        >
            <BoxSSPA />
        </BoxSubPage>
    );
}
export default S1Admission;