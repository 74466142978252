import * as React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

// TODO: Import MUI module
import CircularProgress from '@mui/material/CircularProgress';

// Import context

// TODO: Register ChartJS
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BoxChartHeight() {
    // TODO: Define useContext

    // TODO: Define variable
    const [loading, setLoading] = React.useState(true);
    const [labels, setLabels] = React.useState([]);
    const [humidity, setHumidity] = React.useState([]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '土壤濕度',
            },
            datalabels: {
                align: 'top',
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: '濕度%'
                },
            }
        }
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: '濕度',
                data: humidity,
                borderColor: 'rgb(0, 80, 255)',
                backgroundColor: 'rgba(0, 80, 255, 0.5)',
            },
        ],
    };

    // TODO: Define event
    React.useEffect(GetHumidity, []);

    // TODO Define function
    function GetHumidity() {
        setLoading(true);

        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetHumidity');

            fetch(
                'https://www.pohtyh.edu.hk/MyTYH5/webservices/EGarden.php',
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log('GetHumidity:', 'Data received');

                        setLabels(result.labels);
                        setHumidity(result.data);
                    } else {
                        console.log('GetHumidity:', 'Can not get data');
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })();
    }

    return (
        <>
            {
                loading && <CircularProgress color="success" />
            }
            {!loading && <Line options={options} data={chartData} />}
        </>
    );
}