// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, SpeedDial, SpeedDialAction } from '@mui/material';

// TODO: Import MUI icon
import { Description, Groups, Language, MenuBook, MilitaryTech, Navigation, } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component

const SpeedDialAnchor = ({ refIntro, refLibrarian, refEBook, refRecommended, refRanking, }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SpeedDialAnchor Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const actions = [
        {
            Title: Text.LearningAndTeacher.PromotionReading.Navigate.Ranking,
            Icon: <MilitaryTech />,
            Navigate: refRanking
        },
        {
            Title: Text.LearningAndTeacher.PromotionReading.Navigate.Recommended,
            Icon: <MenuBook />,
            Navigate: refRecommended
        },
        {
            Title: Text.LearningAndTeacher.PromotionReading.Navigate.Librarians,
            Icon: <Groups />,
            Navigate: refLibrarian
        },
        {
            Title: Text.LearningAndTeacher.PromotionReading.Navigate.ELearning,
            Icon: <Language />,
            Navigate: refEBook
        },
        {
            Title: Text.LearningAndTeacher.PromotionReading.Navigate.Intro,
            Icon: <Description />,
            Navigate: refIntro
        },
    ];

    // TODO: Define event
    const handleClicked = (object) => {
        console.log(object.current);
        if (object.current) {
            const componentPosition = object.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY
                    + componentPosition.top
                    - document.getElementById('Top_BoxName').offsetHeight
                    - document.getElementById('Top_BoxMenu').offsetHeight,
                behavior: 'smooth',
            });
        }
    }

    // TODO: Return component
    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            FabProps={{ color: 'tertiary', variant: 'extended', }}
            sx={{ alignItems: 'end', position: 'fixed', bottom: 16, right: 16 }}
            icon={
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {<Navigation />}
                    {Text.Common.Navigate[context.language]}
                </Box>
            }
        >
            {
                actions.map((action, i) => (
                    <SpeedDialAction
                        key={i}
                        FabProps={{
                            size: 'large',
                            sx: { backgroundColor: 'secondary.main', '&:hover': { backgroundColor: 'secondary.light', }, },
                            variant: 'extended'
                        }}
                        icon={<Box sx={{ display:'flex',gap:0.5,}} >{action.Icon}{action.Title[context.language]}</Box>}
                        onClick={() => { handleClicked(action.Navigate) }}
                    />
                ))
            }
        </SpeedDial>
    );
};

export default React.memo(SpeedDialAnchor);