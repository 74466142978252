// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';

// TODO: Import MUI icon
import CancelIcon from '@mui/icons-material/Cancel';

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../../Common/Context';

export default function ImageGrid() {
    // TODO: Define useContext

    // TODO: Define variable
    const [searchParams] = useSearchParams();

    const [images, setImages] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState(false);

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'GetImages');
        formData.append('id', searchParams.get('id'));

        fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => {
                setImages(result);
                console.log(result);
            })
            .catch((error) => { console.error('Error:', error); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOpen = (image) => {
        setOpen(true);
        setCurrentImage(image);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ m: 2 }}
            >
                {images.map((image, i) => (
                    <Grid xs={6} sm={4} md={3} key={i}>
                        <Box
                            sx={{
                                backgroundImage: (`url(https://www.pohtyh.edu.hk/YiYuan/images/plants/${searchParams.get('id')}/${image})`),
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: ({ xs: 100, sm: 150, md: 200, lg: 300 }),
                            }}
                            onClick={() => { handleClickOpen(`https://www.pohtyh.edu.hk/YiYuan/images/plants/${searchParams.get('id')}/${image}`) }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogActions sx={{ p: 0 }}>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ p: 0, mb:2 }}>
                    <Box
                        sx={{
                            backgroundImage: (`url(${currentImage})`),
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '70vh',
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
