// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// TODO: Import custom component
import BoxChartHumidity from './BoxChartHumidity.js';
import BoxTimelapse from './BoxTimelapse.js';

export default function GridEGarden() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Container
            sx={{
                pb: 5,
                pl: { xs: 0, sm: 0, md: 0 },
                pr: { xs: 0, sm: 0, md: 0 },
                pt: 10,
            }}
        >
            <Typography
                sx={{
                    color: 'success.dark',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
                variant="h4"
            >
                智慧莊園
            </Typography>
            <Grid
                columns={{ xs: 6, md: 12 }}
                container
            >
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <BoxTimelapse />
                </Grid>
                <Grid
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <BoxChartHumidity />
                </Grid>
            </Grid>
        </Container >
    );
}