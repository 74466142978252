// TODO: Import React module
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// Import context
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import BoxDrawer from './BoxDrawer';
import SchoolLogo256 from '../Common/SchoolLogo256.png';

export default function BoxName() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const location = useLocation();

    // TODO: Define event
    const navigate = useNavigate();

    React.useEffect(InsertBrowseLog, [location.pathname]);

    // TODO: Define function
    function InsertBrowseLog() {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'InsertBrowseLog');
            formData.append('page', location.pathname);

            fetch(Webservices, { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    return;
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    }

    // TODO: Return component
    return (
        <Box id="Top_BoxName" sx={{ backgroundColor: 'white', display: 'flex', height: { xs: '50px', md: '100px' } }}>
            <BoxDrawer />
            <Box sx={{ alignItems: { xs: 'center', xl: 'start' }, display: 'flex', zIndex: context.Top_BoxName_ZIndex }}>
                <Box onClick={() => { navigate('/Home'); }}>
                    <BoxImage
                        src={SchoolLogo256}
                        sx={{ aspectRatio: 'auto', mx: 2, width: { xs: '48px', md: '104px', xl: '152px' }, }}
                        sxSkeleton={{ aspectRatio: '1/1', mx: 2, width: { xs: '48px', md: '104px', xl: '152px' }, }}
                    />
                </Box>
                <Box>
                    <Stack sx={{ mt: { xl: 2 } }}>
                        <Typography
                            sx={{
                                color: 'black',
                                fontFamily: 'DM Serif Display',
                                fontSize: { xs: '0.5rem', sm: '0.75rem', md: '1.5rem' },
                                fontWeight: 'bold',
                            }}
                            variant="h5"
                        >
                            {Text.Common.SchoolName.ENG}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                fontSize: { xs: '0.5rem', sm: '0.75rem', md: '1.5rem' },
                                fontWeight: 'bold',
                                letterSpacing: '0.5rem'
                            }}
                            variant="h6"
                        >
                            {Text.Common.SchoolName.CHI}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
}