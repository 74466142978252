// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { AppBar, Box, Tab, Tabs, Tooltip, Typography } from '@mui/material';

// TODO: Import MUI icon
import { AutoStories, Stars, VideoCameraFront } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxChart from './Characteristics/BoxChart';
import Slide from './Slide_S1Admission.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';

// TODO: Register ChartJS

const Characteristics = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Characteristics Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [value, setValue] = React.useState(0);

    // TODO: Define event
    const handleChange = (event, newValue) => { setValue(newValue) };

    // TODO: Define function

    // TODO: Return component
    const BoxIntro = () => {
        return (
            <Box sx={{ mt: 2 }}>
                {
                    Text.Admission.Characteristics.Intro[context.language].map(item =>
                        <Typography key={item} sx={{ color: 'black', mb: 2, }} variant="body1">{item}</Typography>
                    )
                }
                <Typography sx={{ color: 'primary.dark', textAlign: 'center' }} variant="h6">
                    {Text.Admission.Characteristics.DisplayModel[context.language]}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: -5 }}>
                    <BoxChart />
                </Box>
            </Box>
        );
    };
    const BoxPublication = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Tooltip
                    arrow
                    placement="left"
                    title={<Typography variant="body1">{Text.Admission.Characteristics.Tooltip[context.language]}</Typography>}
                >
                    <Box
                        onClick={() => { window.open('https://www.pohtyh.edu.hk/Files/SchoolIntroDay/2024Publicity.pdf', '_blank') }}
                        sx={{ cursor: 'pointer', }}
                    >
                        <BoxImage
                            hover={true}
                            src="https://www.pohtyh.edu.hk/Files/SchoolIntroDay/2024Publicity.jpg"
                            sx={{ aspectRatio: 'auto', boxShadow: '10px 10px 20px 0px rgba(0, 0, 0, 0.75)', flex: 1, my: 2, }}
                            sxSkeleton={{ aspectRatio: 'auto', mt: 2, height: '890px', width: 1 }}
                        />
                    </Box>
                </Tooltip>
            </Box>
        );
    };

    const BoxVideo = () => {
        return (
            <Box sx={{ mt: 2 }}>
                <iframe
                    src="https://www.youtube.com/embed/MfGzf-Bx0e8"
                    style={{ aspectRatio: '16/9', border: 0, flex: 1, width: '100%', height: '100%' }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="�H�R����A�{ģ���"
                >
                </iframe>
            </Box>
        );
    };

    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['ADM'].Title[context.language]}
            subtitles={Text.Top.Menu['ADM'].Subtitles}
        >
            <Box id="Characteristics">
                <AppBar position="static">
                    <Tabs
                        centered
                        indicatorColor="secondary"
                        onChange={handleChange}
                        textColor="inherit"
                        value={value}
                    >
                        <Tab icon={<Stars />} label={Text.Admission.Characteristics.Tabs.Intro[context.language]} />
                        <Tab icon={<AutoStories />} label={Text.Admission.Characteristics.Tabs.Publication[context.language]} />
                        <Tab icon={<VideoCameraFront />} label={Text.Admission.Characteristics.Tabs.Video[context.language]} />
                    </Tabs>
                </AppBar>
            </Box>
            {value === 0 && <BoxIntro />}
            {value === 1 && <BoxPublication />}
            {value === 2 && <BoxVideo />}
        </BoxSubPage>
    );
}
export default Characteristics;