// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Tab, Tabs, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component

const TabsYears = ({ onChange }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component TabsYears Rendered');

    // TODO: Define useContext

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const years = [2023, 2022,];
    const [selectedYear, setSelectedYear] = React.useState(years[0]);

    // TODO: Define event
    const handleYearChanged = (e, value) => {
        setSelectedYear(value);
        onChange(value);
    }

    React.useEffect(() => {
        if (onChange && selectedYear) {
            onChange(selectedYear);
        }
    }, [onChange, selectedYear]);

    // TODO: Return component
    return (
        <Tabs onChange={handleYearChanged} value={selectedYear}>
            {years.map((year) => (<Tab key={year} value={year} label={year.toString()} />))}
        </Tabs>
    );
};

export default TabsYears;