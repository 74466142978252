// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_StudentDevelopment.jpg';

const SocialServicesCommittee = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SocialServicesCommittee Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/SocialServicesCommittee/Img20230001.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/SocialServicesCommittee/Img20230518161930.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/SocialServicesCommittee/Img20230805111038.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/SocialServicesCommittee/Img20231003164741.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="SocialServicesCommittee"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.SocialServicesCommittee.PurposeTitle[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.SocialServicesCommittee.PurposeContent[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.SocialServicesCommittee.VisionTitle[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.SocialServicesCommittee.VisionContent[context.language]} />
                </BoxGridColumnsFullWidth>
                {images.map((image, i) => (<BoxImage key={i} src={image} />))}
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default SocialServicesCommittee;