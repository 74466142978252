// TODO: Import React module
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// TODO: Import MUI module
import { } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component
import Home from './Home/Home';
//import HomeHarmoniousCampusPolicy from './Home/和諧校園政策(諮詢稿)';
import PrivacyPolicyOfMyTYH from './Home/PrivacyPolicyOfMyTYH';
import DSEOutstandingStudentVisit from './DSEOutstandingStudentVisit/DSEOutstandingStudentVisit';

import ACHAwardsExternal from './Achievements/AwardsExternal';
import ACHMediaPressCoverage from './Achievements/MediaPressCoverage';
import ACHDSEResult2024 from './Achievements/DSEResult2024';

import ACTExchangeTour from './Activities/ExchangeTour';
import ACTHappyFriday from './Activities/HappyFriday';
import ACTLifeWideLearning from './Activities/LifeWideLearning';
import ACTMusic from './Activities/Music';
import ACTPE from './Activities/PE';
import ACTVA from './Activities/VA';

import ADMCharacteristics from './Admission/Characteristics';
import ADMS1Admission from './Admission/S1Admission';
import ADMS1Application from './Admission/S1Application';
import ADMS1Registration from './Admission/S1Registration';

import Album from './Album/Album';

import Alumni from './Alumni/Alumni';

import CDACE from './CampusDevelopment/ACE';
import CDBlessedBotanicals from './CampusDevelopment/BlessedBotanicals';
import CDBlessedBotanicalsPlant from './CampusDevelopment/BlessedBotanicals/Plant/PagePlant';
import CDCafeGeographie from './CampusDevelopment/CafeGeographie';
import CDCarbonFootprint from './CampusDevelopment/CarbonFootprint';
import CDEnvironmental from './CampusDevelopment/Environmental';
import CDIT from './CampusDevelopment/IT';
import CDLanTingJu from './CampusDevelopment/LanTingJu';
import CDMusicRoom from './CampusDevelopment/MusicRoom';
import CDSolarPhotovoltaicSystems from './CampusDevelopment/SolarPhotovoltaicSystems';

import LTAppliedLearning from './LearningAndTeacher/AppliedLearning';
import LTCurriculum from './LearningAndTeacher/Curriculum';
import LTElectiveSubjectIntro from './LearningAndTeacher/ElectiveSubjectIntro';
import LTEnglishEducation from './LearningAndTeacher/EnglishEducation';
import LTGiftedEducation from './LearningAndTeacher/GiftedEducation';
import LTLearningSupportProgramme from './LearningAndTeacher/LearningSupportProgramme';
import LTPromotionReading from './LearningAndTeacher/PromotionReading';
import LTSubjectsDSE from './LearningAndTeacher/SubjectsDSE';
import LTSTEAMEducation from './LearningAndTeacher/STEAMEducation';

import MusicalWork from './MusicalWork/MusicalWork';

import MusicContest from './MusicContest/MusicContest';

import News from './News/News';

import PTA from './PTA/PTA';

import Recruit from './Recruit/Recruit';

import SchoolProfileSchoolCalendar from './SchoolProfile/SchoolCalendar';
import SchoolProfileMissionVision from './SchoolProfile/MissionVision';
import SchoolProfileMottoSchoolSong from './SchoolProfile/MottoSchoolSong';
import SchoolProfilePolicy from './SchoolProfile/Policy';
import SchoolProfilePrincipalsWords from './SchoolProfile/PrincipalsWords';
import SchoolProfileSchoolPlansReports from './SchoolProfile/SchoolPlansReports';
import SchoolProfileSchoolProfile from './SchoolProfile/SchoolProfile';

import SDClassBuilding from './StudentDevelopment/ClassBuilding';
import SDDisciplineCounselling from './StudentDevelopment/DisciplineCounselling';
import SDHouses from './StudentDevelopment/Houses';
import SDIE from './StudentDevelopment/IE';
import SDLifePlanning from './StudentDevelopment/LifePlanning';
import SDMoralCivicNationalEducation from './StudentDevelopment/MoralCivicNationalEducation';
import SDSocialServicesCommittee from './StudentDevelopment/SocialServicesCommittee';
import SDUniformedGroups from './StudentDevelopment/UniformedGroups';
import SDStudentLeadership from './StudentDevelopment/StudentLeadership';
import SDStudentsUnion from './StudentDevelopment/StudentsUnion';

import UniPass from './UniPass/UniPass';
import UniPassParent from './UniPassParent/UniPassParent';

import { ContextProvider } from './Common/Context';
import { ThemeTYH } from './Common/Theme';

export default function TYH() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    //const basename = window.location.hostname === 'www.pohtyh.edu.hk' ? '/' : '/';

    return (
        <ThemeProvider theme={ThemeTYH}>
            <CssBaseline />
            <ContextProvider>
                <Router>
                    <Routes>
                        <Route element={<Home />} index path="/" />
                        <Route element={<Home />} path="*" />
                        <Route element={<PrivacyPolicyOfMyTYH />} path="/PrivacyPolicyOfMyTYH" />
                        <Route element={<DSEOutstandingStudentVisit />} path="/DSEOutstandingStudentVisit" />

                        <Route element={<ACHAwardsExternal />} path="/ACH_AwardsExternal" />
                        <Route element={<ACHDSEResult2024 />} path="/ACH_DSEResult2024" />
                        <Route element={<ACHMediaPressCoverage />} path="/ACH_MediaPressCoverage" />
                        <Route element={<ACTExchangeTour />} path="/ACT_ExchangeTour" />
                        <Route element={<ACTHappyFriday />} path="/ACT_HappyFriday" />
                        <Route element={<ACTLifeWideLearning />} path="/ACT_LifeWideLearning" />
                        <Route element={<ACTMusic />} path="/ACT_Music" />
                        <Route element={<ACTPE />} path="/ACT_PE" />
                        <Route element={<ACTVA />} path="/ACT_VA" />
                        <Route element={<ADMCharacteristics />} path="/ADM_Characteristics" />
                        <Route element={<ADMS1Admission />} path="/ADM_S1Admission" />
                        <Route element={<ADMS1Application />} path="/ADM_S1Application" />
                        <Route element={<ADMS1Registration />} path="/ADM_S1Registration" />
                        <Route element={<Album />} path="/Album" />
                        <Route element={<Alumni />} path="/Alumni" />
                        <Route element={<CDACE />} path="/CD_ACE" />
                        <Route element={<CDBlessedBotanicals />} path="/CD_BlessedBotanicals" />
                        <Route element={<CDBlessedBotanicalsPlant />} path="/CD_BlessedBotanicalsPlant" />
                        <Route element={<CDCafeGeographie />} path="/CD_CafeGeographie" />
                        <Route element={<CDCarbonFootprint />} path="/CD_CarbonFootprint" />
                        <Route element={<CDEnvironmental />} path="/CD_Environmental" />
                        <Route element={<CDIT />} path="/CD_IT" />
                        <Route element={<CDLanTingJu />} path="/CD_LanTingJu" />
                        <Route element={<CDMusicRoom />} path="/CD_MusicRoom" />
                        <Route element={<CDSolarPhotovoltaicSystems />} path="/CD_SolarPhotovoltaicSystems" />
                        <Route element={<LTAppliedLearning />} path="/LT_AppliedLearning" />
                        <Route element={<LTCurriculum />} path="/LT_Curriculum" />
                        <Route element={<LTElectiveSubjectIntro />} path="/LT_ElectiveSubjectIntro" />
                        <Route element={<LTEnglishEducation />} path="/LT_EnglishEducation" />
                        <Route element={<LTGiftedEducation />} path="/LT_GiftedEducation" />
                        <Route element={<LTLearningSupportProgramme />} path="/LT_LearningSupportProgramme" />
                        <Route element={<LTPromotionReading />} path="/LT_PromotionReading" />
                        <Route element={<LTSubjectsDSE />} path="/LT_SubjectsDSE" />
                        <Route element={<LTSTEAMEducation />} path="/LT_STEAMEducation" />
                        <Route element={<MusicalWork />} path="/MusicalWork" />
                        <Route element={<MusicContest />} path="/MusicContest" />
                        <Route element={<News />} path="/News" />
                        <Route element={<PTA />} path="/PTA" />
                        <Route element={<Recruit />} path="/Recruit" />
                        <Route element={<SchoolProfileSchoolCalendar />} path="/SchoolProfile_SchoolCalendar" />
                        <Route element={<SchoolProfileMissionVision />} path="/SchoolProfile_MissionVision" />
                        <Route element={<SchoolProfileMottoSchoolSong />} path="/SchoolProfile_MottoSchoolSong" />
                        <Route element={<SchoolProfilePolicy />} path="/SchoolProfile_Policy" />
                        <Route element={<SchoolProfilePrincipalsWords />} path="/SchoolProfile_PrincipalsWords" />
                        <Route element={<SchoolProfileSchoolPlansReports />} path="/SchoolProfile_SchoolPlansReports" />
                        <Route element={<SchoolProfileSchoolProfile />} path="/SchoolProfile_SchoolProfile" />
                        <Route element={<SDClassBuilding />} path="/SD_ClassBuilding" />
                        <Route element={<SDDisciplineCounselling />} path="/SD_DisciplineCounselling" />
                        <Route element={<SDHouses />} path="/SD_Houses" />
                        <Route element={<SDIE />} path="/SD_IE" />
                        <Route element={<SDLifePlanning />} path="/SD_LifePlanning" />
                        <Route element={<SDMoralCivicNationalEducation />} path="/SD_MoralCivicNationalEducation" />
                        <Route element={<SDSocialServicesCommittee />} path="/SD_SocialServicesCommittee" />
                        <Route element={<SDStudentLeadership />} path="/SD_StudentLeadership" />
                        <Route element={<SDStudentsUnion />} path="/SD_StudentsUnion" />
                        <Route element={<SDUniformedGroups />} path="/SD_UniformedGroups" />
                        <Route element={<UniPass />} path="/UniPass" />
                        <Route element={<UniPassParent />} path="/UniPassParent" />
                    </Routes>
                </Router>
            </ContextProvider>
        </ThemeProvider>
    );
}