// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Link from '@mui/material/Link';

// TODO: Import MUI icon;

// TODO: Import custom component
import BoxFirst from './BoxFirst.js';
import GridImages from './GridImages.js';
import Intro from './Intro.js';
import Medic from './Medic.js';
import PlantInfo from './PlantInfo.js';
import TopButton from './TopButton.js';
import BoxIntroBoard from './BoxIntroBoard.js';

export default function Plant() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            <Link id="BoxFirst" />
            <BoxFirst />
            <Intro />
            <Medic />
            <PlantInfo />
            <GridImages />
            <TopButton />
            <BoxIntroBoard />
        </>
    );
}