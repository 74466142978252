// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../../Common/BoxGridColumns';
import BoxImage from '../../Common/BoxImage';
import { ContentTitle, MultipleRows } from '../../Common/TypographyTYH';
import ListText from '../../Common/ListText';

const BoxAirScout = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxAirScout Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS01.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS02.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS03.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS04.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS05.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/AS06.jpg',
    ];

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxGridColumnsContainer>
            <BoxGridColumnsFullWidth>
                <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/StudentDevelopment/UniformedGroups/ASLogo.png"
                        sx={{ aspectRatio: '1/1', objectFit: 'contain', width: '24px', }}
                        sxSkeleton={{ aspectRatio: '1/1', width: '24px', }}
                    />
                    <ContentTitle text={Text.StudentDevelopment.UniformedGroups.AirScout.Title[context.language]} />
                </Box>
                <MultipleRows rows={Text.StudentDevelopment.UniformedGroups.AirScout.Intro[context.language]} />
                <ListText lines={Text.StudentDevelopment.UniformedGroups.AirScout.Activities[context.language]} />
            </BoxGridColumnsFullWidth>
            {
                images.map((image, i) => (<BoxImage key={i} src={image} />))
            }
        </BoxGridColumnsContainer>
    );
}

export default BoxAirScout;