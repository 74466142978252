// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

// TODO: Import MUI icon
import { InsertPhoto } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_StudentDevelopment.jpg';

const MoralCivicNationalEducation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MoralCivicNationalEducation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    /*const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20220512.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img2022051202.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20221115.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20221202151150.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20221219135802.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20230117150006.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20230331133149.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20230404102414.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20230404121052.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/MoralCivicNationalEducation/Img20230422130439.jpg',
    ];*/

    const thumbs = [
        {
            Title: '中四全方位喜義日',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/2024LWLF4/Slide.jpg',
            URL: '/Album?ID=2024LWLF4',
        },
        {
            Title: 'VR AR輪椅運動競技體驗工作坊',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/20240319/Slide.jpg',
            URL: '/Album?ID=20240319',
        },
        {
            Title: '視障觀星體驗工作坊',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/20240319B/Slide.jpg',
            URL: '/Album?ID=20240319B',
        },
        {
            Title: '聾人文化體驗工作坊',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/20240312/Slide.jpg',
            URL: '/Album?ID=20240312',
        },
        {
            Title: '少數族裔特色手藝體驗工作坊',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/20240301/Slide.jpg',
            URL: '/Album?ID=20240301',
        },
        {
            Title: '網上同理心體驗活動',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Album/20240314/Slide.jpg',
            URL: '/Album?ID=20240314',
        },
    ];

    // TODO: Define event
    const navigate = useNavigate();

    // TODO: Return component
    const RenderStackImage = ({ thumb }) => (
        <StackImage onClick={() => { navigate(thumb.URL) }} hover={true}>
            <BoxImage src={thumb.Thumb} />
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <InsertPhoto sx={{ color: 'white' }} />
                {thumb.Title}
            </Typography>
        </StackImage>
    );

    return (
        <BoxSubPage
            id="ClassBuilding"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.MoralCivicNationalEducation.CaringSchool.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.MoralCivicNationalEducation.CaringSchool.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.MoralCivicNationalEducation.LifeEducation.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.MoralCivicNationalEducation.LifeEducation.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.MoralCivicNationalEducation.ValuesEducation.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.MoralCivicNationalEducation.ValuesEducation.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.MoralCivicNationalEducation.PastActivities.Title[context.language]} />
                </BoxGridColumnsFullWidth>
                {/*
                    images.map((image, i) => (
                        <BoxImage key={i} src={image} />
                    ))
                */}
            </BoxGridColumnsContainer>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                {
                    thumbs.map((thumb, i) => (
                        <Grid key={i} xs={6}>
                            <RenderStackImage thumb={thumb} />
                        </Grid>
                    ))
                }
            </Grid>
        </BoxSubPage>
    );
}

export default MoralCivicNationalEducation;