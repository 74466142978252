// TODO: Import React module
import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MusicNoteIcon from '@mui/icons-material/MusicNote';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import { ContentTitle, } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';

const Graduation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Graduation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const data = [{
        name: '我的目標',
        description: '<p>作曲:陳曉明老師、溫新康先生<br>作詞:張樂勤小姐、陳曉明老師<br>主唱:全體音樂劇學生</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/8.mp3',

    }, {
        name: '偏見',
        description: '<p>作曲:朱慧敏老師<br>作詞:張樂勤小姐<br>主唱:陳恩聖同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/3.mp3',

    }, {
        name: '錢',
        description: '<p>作曲:溫新康先生<br>作詞:張樂勤小姐<br>主唱:歐俊同學、吳勤威同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/12.mp3',

    }, {
        name: '頌花',
        description: '<p>作曲:鄭翠珊同學<br>作詞:張樂勤小姐<br>主唱:全體音樂劇學生</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/13.mp3',

    }, {
        name: '童年摯友',
        description: '<p>作曲:陳曉明老師<br>作詞:張樂勤小姐<br>主唱:歐俊同學、黃淑儀同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/10.mp3',

    }, {
        name: '合作無間',
        description: '<p>作曲:霍祉霖同學<br>作詞:張樂勤小姐<br>主唱:全體音樂劇學生</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/4.mp3',

    }, {
        name: '當我們在一起',
        description: '<p>作曲:陳曉明老師<br>作詞:張樂勤小姐、陳曉明老師<br>主唱:全體音樂劇學生</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/9.mp3',

    }, {
        name: '屈到病',
        description: '<p>作曲:霍祉霖同學<br>作詞:張樂勤小姐<br>主唱:陳恩聖同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/8.mp3',

    }, {
        name: 'My Star',
        description: '<p>作曲:李夏同學<br>作詞:姚煒銓同學<br>主唱:陳恩聖同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/2.mp3',

    }, {
        name: '心中的寶石',
        description: '<p>作曲:陳曉明老師<br>作詞:陳曉明老師<br>主唱:歐俊同學、黃淑儀同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/6.mp3',

    }, {
        name: '想念你',
        description: '<p>作曲:溫新康先生<br>作詞:張樂勤小姐<br>主唱:歐俊同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/7.mp3',

    }, {
        name: 'Goodbye my love',
        description: '<p>作曲:崔思維同學<br>作詞:張樂勤小姐<br>主唱:歐俊同學、吳勤威同學</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/1.mp3',

    },];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Stack className="SectionMusical" spacing={2} sx={{ alignItems: 'center', m: 5 }}>
            <ContentTitle text={Text.MusicalWork.Musical[context.language]} />
            <Typography sx={{ color: 'black' }} variant="h6">
                {Text.MusicalWork.Story[context.language]}:
            </Typography>
            <Typography variant="body1">轉校生非凡由一間名校來到一間平凡的中學，原來有特別的目的，但當他遇到鬼伯、星兒和一班同學，經歷了多姿多彩且難忘的校園生活，令他有很大的改變，與此同時，他開始知道鬼伯的真正身份.........</Typography>
            <Grid container spacing={2}>
                {
                    data.map((item, i) => (
                        <Grid key={i} xs={12} md={6}>
                            <Stack direction="row" spacing={2}>
                                <Avatar>
                                    <MusicNoteIcon />
                                </Avatar>
                                <Box sx={{ width: 1 }}>
                                    <Typography
                                        color="black"
                                        dangerouslySetInnerHTML={{ __html: item.name }}
                                        variant="subtitle1"
                                    />
                                    <Typography
                                        color="text.secondary"
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                        variant="subtitle2"
                                    />
                                    <ReactAudioPlayer controls src={item.filename} style={{ width: '100%' }} />
                                </Box>
                            </Stack>
                        </Grid>
                    ))
                }
            </Grid>
            <Divider variant="inset" />
            <BoxImage
                src="https://www.pohtyh.edu.hk/Files/MusicalWork/Musical/Musical.png"
                sx={{ maxWidth: '640px', width: 1 }}
                sxSkeleton={{ maxWidth: '640px', width: 1 }}
            />
        </Stack>
    );
};
export default Graduation;