// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';

// TODO: Import MUI icon
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';

const DialogImage = ({ src, onClose }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DialogImage Rendered');

    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(src)}
            onClose={onClose}
            PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none', }, }}
        >
            <DialogActions sx={{ pb: 0 }}>
                <IconButton onClick={onClose} sx={{ p: 0 }}>
                    <CancelRoundedIcon />
                </IconButton>
            </DialogActions>
            <DialogContent sx={{
                display: 'flex',
                justifyContent: 'center',
                overflow: 'hidden',
                p: 0,
                pb: 0,
            }}>
                {
                    src.includes('mp4.jpg') ?
                        <Box sx={{ position: 'relative', width: 1, }}>
                            <video autoPlay controls width="100%" height="100%">
                                <source src={src.replace('.mp4.jpg', '.mp4')} type="video/mp4" />
                            </video>
                        </Box> :
                        <BoxImage
                            src={src}
                            sx={{ objectFit: 'contain', width: 1 }}
                            sxSkeleton={{ backgroundColor: 'rgba(255,255,255,0.5)', width: 1 }}
                        />
                }
            </DialogContent>
        </Dialog>
    );
};
export default DialogImage;