// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { lightGreen } from '@mui/material/colors';

// TODO: Import MUI icon

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../Common/Context';

export default function BoxPlant() {
    // TODO: Define useContext

    // TODO: Define variable
    const [filter, setFilter] = React.useState(
        {
            ornamental: false,
            succulent: false,
            fruit: false,
            spices: false,
            vegetable: false,
            cereal: false
        },
    );
    const [images, setImages] = React.useState([]);

    // TODO: Define event
    React.useEffect(GetActivitiies, [filter]);

    const handleClickOpen = (id) => {
        window.open('./CD_BlessedBotanicalsPlant?id=' + id + '#BoxFirst','_blank');
    };

    const handleChecked = (type) => {
        let object = { ...filter };
        object[type] = !object[type];
        setFilter(filter => ({ ...object }));
    };

    // TODO: Define function
    function GetActivitiies() {
        (async () => {
            let conditions = [];

            Object.keys(filter).map(function (key) {
                if (filter[key]) {
                    conditions.push(key);
                }
                return true;
            });

            const formData = new FormData();
            formData.append('action', 'GetPlants');
            formData.append('conditions', conditions);

            fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        setImages(result);
                    }
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    }

    // TODO: Return component
    return (
        <Box
            id="YiYuan_BoxPlant"
            sx={{
                backgroundColor: lightGreen['50'],
                px: 2,
                py: 2,
            }}
        >
            <Typography
                sx={{
                    color: 'success.dark',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
                variant="h4"
            >
                植物資訊
            </Typography>
            <Typography variant="p">
                由起初建設貽園到目前為止，我們培植過不同種類的植物，當中有些品種會隨著季節而生長與枯萎，而有些品種皆為全年生長，以下是它們的簡介與照片紀錄，大家可以按種類篩選植物觀看。
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center'
                }}
            >
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.ornamental}
                        color="success"
                        onClick={() => { handleChecked('ornamental'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🌼觀賞植物
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.succulent}
                        color="success"
                        onClick={() => { handleChecked('succulent'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🌵多肉植物
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.fruit}
                        color="success"
                        onClick={() => { handleChecked('fruit'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🍎水果
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.spices}
                        color="success"
                        onClick={() => { handleChecked('spices'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🧂香料
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.vegetable}
                        color="success"
                        onClick={() => { handleChecked('vegetable'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🥬蔬菜
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Switch
                        checked={filter.cereal}
                        color="success"
                        onClick={() => { handleChecked('cereal'); }}
                    />
                    <Typography
                        component="span"
                        gutterBottom
                        variant="body2"
                    >
                        🌾殼類
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Button
                        color="success"
                        onClick={() => {
                            let object = { ...filter };
                            object['ornamental'] = false;
                            object['succulent'] = false;
                            object['fruit'] = false;
                            object['spices'] = false;
                            object['vegetable'] = false;
                            object['cereal'] = false;
                            setFilter(filter => ({ ...object }));
                        }}
                        sx={{ lineHeight: '1rem' }}
                        variant="contained"
                    >
                        清除篩選<br />
                        顯示所有植物
                    </Button>
                </Box>
            </Box>
            <Grid
                container
                spacing={2}
                sx={{ m: 2 }}
            >
                {images.map((image, i) => (
                    <Grid xs={6} lg={4} key={i}>
                        <Box
                            onClick={() => handleClickOpen(image.ID)}
                            sx={{
                                backgroundImage: (`url(https://www.pohtyh.edu.hk/YiYuan/images/plants/${image.ID}/cover.jpg)`),
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'flex',
                                alignItems: 'end',
                                justifyContent: 'center',
                                height: ({ xs: 150, md: 200 }),
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    p: 1,
                                    width: '100%',
                                }}
                            >
                                <Typography color="white">
                                    {image.CName}
                                    {parseInt(image.Ornamental) === 1 && '🌼'}
                                    {parseInt(image.Succulent) === 1 && '🌵'}
                                    {parseInt(image.Fruit) === 1 && '🍎'}
                                    {parseInt(image.Spices) === 1 && '🧂'}
                                    {parseInt(image.Vegetable) === 1 && '🥬'}
                                    {parseInt(image.Cereal) === 1 && '🌾'}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
