// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_SchoolProfile.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

const PrincipalsWords = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component PrincipalsWords Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="SchoolProfile"
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <Box sx={{ my: 2 }}>
                <ContentTitle text={`涵養個性 激發潛能 為孩子未來幸福奠基`} sx={{ fontWeight: 'bold', textAlign: 'center' }} />
                <Box sx={{
                    aspectRatio: '1/1',
                    float: { xs: 'none', sm: 'right' },
                    height: 'auto',
                    maxWidth: { xs: 'auto', sm: '192px', md: '256px' },
                    mb: { xs: 2, sm: 0 },
                    mr: 2,
                    position: 'relative',
                    width: { xs: 0.8, sm: 0.4, md: 0.8 },
                }}>
                    <Box
                        component="img"
                        src="https://www.pohtyh.edu.hk/Files/PrincipalsWords/ProfilePic2024.jpg"
                        sx={{
                            aspectRatio: '1/1',
                            border: 'solid 5px',
                            borderColor: 'white',
                            borderRadius: '50%',
                            height: 'auto',
                            ml: 2,
                            objectFit: 'cover',
                            position: 'absolute',
                            width: 1,
                            zIndex: 10,
                        }}
                    />
                    <Box
                        sx={{
                            aspectRatio: '1/1',
                            backgroundColor: 'tertiary.main',
                            borderRadius: '50%',
                            position: 'absolute',
                            height: 'auto',
                            ml: 2,
                            width: 1,
                            top: 10,
                            left: 10,
                        }}
                    />
                </Box>
                <ContentTitle text={`以愛陪伴 用掌聲支持`} />
                <MultipleRows rows={['在「英喜」這個家，掌聲、笑聲不絕於耳。我們以愛陪伴，用掌聲支持，鼓勵孩子去嘗試；我們用掌聲，肯定孩子的進步和成長；我們用掌聲，賦予孩子迎難而上的勇氣和力量；我們用掌聲，慶祝孩子的成功。我們彼此激勵、互相成就，各展所長，追求卓越。',]} />
                <ContentTitle text={`中西合璧 以美育人`} />
                <MultipleRows rows={['我校環境怡人，鳥語花香，綠意盎然，現代化的校園與中式園林建築自然融合，處處散發著青春活力，又蘊含著高雅韻味。漫步校園，處處可見家長、學生、教職員一起佈置「家」的印記。秀美而具靈氣的學習環境，孕育了一代又一代既具品味又有青春活力的學子。',]} />
                <ContentTitle text={`閃耀潛能 因追夢而發光`} />
                <MultipleRows rows={['我們以「激發潛能」作為切入點，為孩子締造多元化的成功舞台，助他們找到強項，建立自信。讓孩子們把這份自信變成自主學習的熱情，變成克服人生各種挑戰的力量，為實現自己的夢想而努力不懈，因追夢而發光。',]} />
                <ContentTitle text={`海納百川 融古今中西丈量世界`} />
                <MultipleRows rows={['我們提供多元化平台，助孩子認識、體驗中國傳統及世界不同文化。在語言方面，設有日文班、韓文班、法文班。在生活藝術方面，設有茶藝班、咖啡拉花班、花藝班等。在運動方面，設有冰球隊、匹克球隊、馬術班等。在境外學習方面，建基學科所學，作深度研習，如：廣州暨南大學運動精英集訓營、英國劍橋大學英文及音樂課程、德國STEAM學習團等，藉此提高學生與世界對話的能力。',]} />
                <ContentTitle text={`培育領袖 擁抱未來`} />
                <MultipleRows rows={['為了助孩子提升人際相處技巧，更好地應對未來。我校設有多種領袖團隊，只要孩子願意嘗試，樂於奉獻，以成就他人為己任，就可獲得擔任領袖的機會。老師們除在課堂內外教導孩子待人接物技巧外，更創設多元化培訓領袖平台，對孩子賦予高度信任，讓他們自主發展，在有需要時，才適時介入，加以指導匡正，希望他們在追求個人成功時，也以服務他人、成就他人為己任，懷抱為人類謀福祉的使命。',]} />
                <ContentTitle text={`結語`} />
                <MultipleRows rows={['教育，是為了助孩子在未來人生中走得更紮實。願在英喜大家庭長大的孩子，在專業有愛的教學團隊的陪伴下，活出自我，堅定目標，積極面對生活中的挑戰，邁向圓滿人生。',]} />
            </Box>
        </BoxSubPage>
    );
};
export default PrincipalsWords;