// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth, } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';

const GiftedEducation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component GiftedEducation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/GiftedEducation/Img20210907132258.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/GiftedEducation/Img20211023112124.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/GiftedEducation/Img20211030100116.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/GiftedEducation/Img20211030125011.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="SocialServicesCommittee"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <Typography sx={{ color: 'primary.main', textAlign: 'center' }} variant="h6">
                        {Text.LearningAndTeacher.GiftedEducation.Slogan[context.language]}
                    </Typography>
                    <Typography sx={{ color: 'black' }} variant="body1">
                        {Text.LearningAndTeacher.GiftedEducation.Content[context.language]}
                    </Typography>
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default GiftedEducation;