// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context, fetchData } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';
import StackImage from '../../Common/StackImage';

const BoxRanking = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxRanking Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    //const [rank, setRank] = React.useState({ Date: '--', J1: '--', J2: '--', J3: '--', S1: '--', S2: '--', S3: '--', });
    const rank = { Date: '2024-10-15', J1: '2B', J2: '2A', J3: '2D', S1: '6A & 6C', S2: '4A', S3: '6E', }

    // TODO: Define event
    /*React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'LT_PromotionReading_GetRank');
        fetchData(
            formData,
            (result) => {setRank(result)}
        );
    }, []);*/

    // TODO: Return component
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.Ranking.Title[context.language]}
            </Typography>
            {
                <Grid container spacing={4}>
                    {
                        [1, 2, 3].map((i) => (
                            <Grid key={i} xs={12} sm={4}>
                                <StackImage key={i}>
                                    <BoxImage
                                        src={`https://www.pohtyh.edu.hk/Files/PromotionReading/LV${i}.png`}
                                        sx={{ aspectRatio: 'auto', objectFit: 'contain', width: 'auto' }}
                                    />
                                    <Typography sx={{ color: 'white', mt: 1, }} variant="body1">
                                        {`
                                            ${Text.Common.Classes[context.language]}:
                                            ${rank[`J${i}`]}(${Text.Common.FormJunior[context.language]})
                                        `}
                                    </Typography>
                                    <Typography sx={{ color: 'white', mt: 1, }} variant="body1">
                                        {`
                                            ${Text.Common.Classes[context.language]}:
                                            ${rank[`S${i}`]}(${Text.Common.FormSenior[context.language]})
                                        `}
                                    </Typography>
                                </StackImage>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Box>
    );
};
export default BoxRanking;