// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import { ContentTitle, MultipleRows, } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

export default function Environmental() {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Environmental Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="Environmental"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <ContentTitle text={Text.CampusDevelopment.Environmental.BackgroundTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.Environmental.BackgroundContent[context.language]} />
            <ContentTitle text={Text.CampusDevelopment.Environmental.MissionTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.Environmental.MissionContent[context.language]} />
            <ContentTitle text={Text.CampusDevelopment.Environmental.StrategiesTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.Environmental.StrategiesContent[context.language]} />
            <ContentTitle text={Text.CampusDevelopment.Environmental.StatementTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.Environmental.StatementContent[context.language]} />
            <ListText lines={Text.CampusDevelopment.Environmental.StatementContentList[context.language]} />
            <BoxImage
                src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/Environmental/Statement.jpg"
                sx={{ width: 1, }}
                sxSkeleton={{ width: 1 }}
            />
            <ContentTitle text={Text.CampusDevelopment.Environmental.PracticeTitle[context.language]} />
            <MultipleRows
                rows={Text.CampusDevelopment.Environmental.Practice.Content.Student[context.language]}
                sx={{ textDecoration: 'underline' }}
            />
            <ListText lines={Text.CampusDevelopment.Environmental.Practice.List.Student[context.language]} />
            <MultipleRows
                rows={Text.CampusDevelopment.Environmental.Practice.Content.Staff[context.language]}
                sx={{ textDecoration: 'underline' }}
            />
            <ListText lines={Text.CampusDevelopment.Environmental.Practice.List.Staff[context.language]} />
            <BoxImage
                src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/Environmental/Practice.jpg"
                sx={{ width: 1, }}
                sxSkeleton={{ width: 1 }}
            />
            <ContentTitle text={Text.CampusDevelopment.Environmental.DailyTitle[context.language]} />
            <ListText lines={Text.CampusDevelopment.Environmental.DailyContent[context.language]} />
        </BoxSubPage>
    );
}