// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// TODO: Import MUI module
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// TODO: Import MUI icon

// Import context
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';

export default function BoxNews() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [news, setNews] = React.useState([]);

    // TODO: Define event
    const theme = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        const GetNews = () => {
            (async () => {
                const formData = new FormData();
                formData.append('action', 'Home_GetNews');

                fetch(Webservices, { method: 'POST', body: formData, })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.length > 0) {
                            setNews(result);
                        }
                    }).catch((error) => { console.error('Error:', error); });
            })();
        }
        GetNews();
    }, []);

    // TODO: Define function

    // TODO: Return component
    return (
        <Box id="Home_BoxNews">
            <Typography
                component="p"
                sx={{
                    fontSize: { xs: '1.5rem', sm: '2.125rem' },
                    fontWeight: 'bold',
                    my: 2,
                    textAlign: 'center',
                }}
            >
                {Text.Home.News.Title[context.language]}
            </Typography>
            {
                news.length > 0 &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ maxWidth: { xs: '328px', sm: '416px', md: '688px', lg: '1096px', xl: '1484px' } }}>
                        <Swiper
                            autoplay={{ delay: 2500, disableOnInteraction: false, }}
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{ clickable: true, }}
                            breakpoints={{
                                0: { slidesPerView: 2, spaceBetween: 16, },
                                900: { slidesPerView: 3, spaceBetween: 16, },
                                1536: { slidesPerView: 4, spaceBetween: 16, },
                            }}
                            modules={[Autoplay, Navigation, Pagination]}
                            navigation={false}
                            style={{ '--swiper-pagination-color': theme.palette.primary.main, paddingBottom: '36px', }}
                        >
                            {/*<SwiperSlide style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                                <Box sx={{
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    p: 1,
                                    width: context.Common_Image_Width,
                                }}>
                                    <Box onClick={() => { navigate("/和諧校園政策(諮詢稿)"); }}>
                                        <BoxImage
                                            hover
                                            src={"https://www.pohtyh.edu.hk/Files/Home/和諧校園政策(諮詢稿).jpg"}
                                        />
                                    </Box>
                                    <Typography sx={{ textAlign: 'center' }} variant="body1">和諧校園政策(諮詢稿)</Typography>
                                </Box>
                            </SwiperSlide>*/}
                            {
                                news.map((item, i) => (
                                    <SwiperSlide
                                        key={i}
                                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                                    >
                                        <Box sx={{
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            p: 1,
                                            width: context.Common_Image_Width,
                                        }}>
                                            <Box onClick={() => { navigate(`/Album?ID=${item.ID}`); }}>
                                                <BoxImage
                                                    hover
                                                    src={`https://www.pohtyh.edu.hk/Files/Album/${item.ID}/Slide.jpg`}
                                                />
                                            </Box>
                                            <Typography sx={{ textAlign: 'center' }} variant="body1">
                                                {item.Title}
                                            </Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))
                            }
                            <SwiperSlide style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        backgroundColor: 'primary.main',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: { xs: '90px', sm: '113px', md: '126px', lg: '203px' },
                                        justifyContent: 'center',
                                        p: 1,
                                        width: context.Common_Image_Width,
                                    }}
                                    onClick={() => { navigate(`/News`); }}
                                >
                                    <Box>
                                        <Typography sx={{
                                            color: 'white',
                                            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem', lg: '2.125rem' },
                                            textAlign: 'center',
                                        }}>
                                            {Text.Home.News.More[context.language]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        </Swiper>
                    </Box>
                </Box>
            }
        </Box>
    );
}