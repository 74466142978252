// TODO: Import React module
import * as React from 'react';
/*import { useNavigate } from 'react-router-dom';*/

// TODO: Import MUI module
import Box from '@mui/material/Box';
/*import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';*/

// TODO: Import MUI icon
/*import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';*/

// Import context
/*import { Context } from '../Common/Context';*/
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxMenuItems from './BoxMenuItems';

export default function BoxMenu() {
    // TODO: Define useContext
    //const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Box
            id="Top_BoxMenu"
            sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(0, 171, 148,0.9)',
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                position: 'absolute',
                width: 1,
            }}
        >
            {
                Object.keys(Text.Top.Menu).map((key, i) => {
                    const depthLevel = 0;
                    return (
                        <BoxMenuItems key={i} depthLevel={depthLevel} items={Text.Top.Menu[key]} />
                    );
                })
            }
        </Box>
    );
}