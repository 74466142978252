// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context, } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import DialogVideo from '../../Common/DialogVideo';
import BoxImage from '../../Common/BoxImage';
import StackImage from '../../Common/StackImage';

const BoxGoodBooks = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxGoodBooks Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const videos = [
        { Book: '圖解微人格心理學:教你發現身體裡另一個自己', Filename: 'Video_CTS_圖解微人格心理學', Staff: '陳子湘老師', URL: 'https://youtube.com/shorts/6jnTXKnBLvE', VO: '4B 李紫盈', Production: '5D 楊家榆', },
        { Book: '變動思維', Filename: 'Video_LYY_變動思維', Staff: '劉欣欣老師', URL: 'https://youtube.com/shorts/mIYa2pRchfE', VO: '4B 何諾熹', Production: '5D 楊家榆', },
        { Book: '目送', Filename: 'Video_TF_目送', Staff: '鄧菲老師', URL: 'https://youtube.com/shorts/gPfrpomNzsI', VO: '4B 何諾熹', Production: '5D 楊家榆', },
        { Book: '許三觀賣血記', Filename: 'Video_CL_許三觀賣血記', Staff: '朱亮老師', URL: 'https://youtube.com/shorts/qTS8xTtuXRg', VO: '5A 邱錦承', },
        { Book: '北歐式的自由生活提案', Filename: 'Video_LWS_北歐式的自由生活提案', Staff: '李慧詩老師', URL: 'https://youtu.be/nGP4CYc-1Vc', VO: '李慧詩老師', },
        { Book: '有一種禮物叫痛苦', Filename: 'Video_TLF_有一種禮物叫痛苦', Staff: '謝麗芬老師', URL: 'https://youtu.be/DjyIBBiKxKI', VO: '5E 楊智溦', },
        { Book: '往食只能回味', Filename: 'Video_YCK_往食只能回味', Staff: '阮竹權老師', URL: 'https://youtu.be/jVTr7Oicz7k', VO: '5A 蔡智華', },
    ];

    // TODO: Define event

    // TODO: Return component
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.Video.Title[context.language]}
            </Typography>
            {
                <Grid container spacing={2}>
                    {
                        videos.map((item) => (
                            <Grid key={item.rID} xs={6}>
                                <StackImage>
                                    <Box sx={{ position: 'relative', width: context.Common_Image_Width, }}>
                                        <BoxImage
                                            hover={true}
                                            src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/${item.Filename}.jpg`}
                                        />
                                        <DialogVideo url={item.URL} />
                                    </Box>
                                    <Typography component="span" sx={{ color: 'white', mt: 1, }} variant="body1">
                                        {`${Text.LearningAndTeacher.PromotionReading.Video.Bookname[context.language]}: 
                                        ${item.Book}`}
                                    </Typography>
                                    <Typography component="span" sx={{ color: 'white', }} variant="body1">
                                        {`${Text.LearningAndTeacher.PromotionReading.Video.Staff[context.language]}:
                                        ${item.Staff}`}
                                    </Typography>
                                    <Typography component="span" sx={{ color: 'white', }} variant="body1">
                                        {`${Text.LearningAndTeacher.PromotionReading.Video.VO[context.language]}:
                                        ${item.VO}`}
                                    </Typography>
                                    {
                                        item.Production &&
                                        <Typography component="span" sx={{ color: 'white', }} variant="body1">
                                            {`${Text.LearningAndTeacher.PromotionReading.Video.Production[context.language]}:
                                            ${item.Production}`}
                                        </Typography>
                                    }
                                </StackImage>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Box>
    );
};
export default BoxGoodBooks;