// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';
import ListText from '../Common/ListText';

import Slide from './Slide_Activities.jpg';

const VA = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component VA Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172325.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172444.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172521.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172633.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172749.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/VA/Img20231129172850.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="VA"
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.Activities.VA.Purpose.Title[context.language]} />
                    <MultipleRows rows={Text.Activities.VA.Purpose.Content[context.language]} />
                    <ListText lines={Text.Activities.VA.Purpose.Lines[context.language]} />
                    <ContentTitle text={Text.Activities.VA.Optimisation.Title[context.language]} />
                    <MultipleRows rows={Text.Activities.VA.Optimisation.Content[context.language]} />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default VA;