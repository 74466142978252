// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Chip } from '@mui/material';

// TODO: Import MUI icon
import { Face } from '@mui/icons-material';

// TODO: Import context
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, } from '../Common/TypographyTYH';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import ListText from '../Common/ListText';

import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import Slide from './Slide_StudentDevelopment.jpg';

const LifePlanning = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component LifePlanning Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/LifePlanning/Img01.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/LifePlanning/Img02.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/LifePlanning/Img04.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="LifePlanning"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.LifePlanning.Purpose.Title[context.language]} />
                    <ListText lines={Text.StudentDevelopment.LifePlanning.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.LifePlanning.Strategy.Title[context.language]} />
                    <ListText lines={Text.StudentDevelopment.LifePlanning.Strategy.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.LifePlanning.Blueprint.Title[context.language]} />
                    <ListText lines={Text.StudentDevelopment.LifePlanning.Blueprint.Content[context.language]} />
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                        <Chip
                            color="info"
                            onClick={() => { window.open("https://forms.gle/yJxpHkVhDDaFXz9V7") }}
                            icon={<Face />}
                            label={Text.StudentDevelopment.LifePlanning.GoogleForm[context.language]}
                            sx={{ my: 2, '& span': { fontSize: '1rem' } }}
                            variant="filled"
                        />
                    </Box>
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (
                        <BoxImage key={i} src={image} />
                    ))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}

export default LifePlanning;