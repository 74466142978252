// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Article, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

const BoxUsefulInfo = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxHighlightedProgrammes Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const items = [
        {
            Title: '校園‧好精神 - 家長「守門人」訓練',
            Intro: '',
            Thumb: 'https://mentalhealth.edb.gov.hk/uploads/tc/mh/content/thumbs/320x180/video1_thumb_20230310155123310873.png',
            URL: 'https://mentalhealth.edb.gov.hk/tc/support-programmes/parent-workshop-on-gatekeeper-training.html#1',
        },
        {
            Title: '家長智NET',
            Intro: '',
            Thumb: 'https://www.parent.edu.hk/images/default-source/hero-banner/2024spn-mobile-banner.jpg?sfvrsn=4efe0fb7_5',
            URL: 'https://www.parent.edu.hk/',
        },
    ];

    // TODO: Define event
    const handleClicked = (url) => {
        window.open(url, '_blank');
    };

    // TODO: Return component
    const RenderStackImage = ({ item }) => (
        <StackImage onClick={() => { handleClicked(item.URL) }} hover={true}>
            <BoxImage src={item.Thumb} />
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <Article sx={{ color: 'white' }} />
                {item.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                items.map((item, i) => (
                    <Grid key={i} xs={6} md={4}>
                        <RenderStackImage item={item} />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default React.memo(BoxUsefulInfo);