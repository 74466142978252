// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon
import { Article } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import { ContentTitle, } from '../../Common/TypographyTYH';

const BoxDocument = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxDocument Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Box sx={{ my: 2 }}>
            <ContentTitle text={Text.Admission.S1Application.Document.Title[context.language]}/>
            {
                Text.Admission.S1Application.Document.Documents.map((item, i) => (
                    <Box
                        key={i}
                        sx={{ alignItems: 'center', color: 'black', display: 'flex', gap: 1 }}
                    >
                        {<Article />}
                        <a
                            href={item.URL}
                            rel="noreferrer"
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                        >
                            {item[context.language]}
                        </a>
                    </Box>
                ))
            }
        </Box>
    );
}
export default BoxDocument;