// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Fab } from '@mui/material';

// TODO: Import MUI icon
import {Navigation} from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_Activities.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';

const Music = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Music Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20211210131308.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20211218122752.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20220808114431.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20221005100533.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20221029094228.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20221029114835.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20221029115718.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img20221219162107.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/Music/Img_02.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="MusicRoom"
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.Activities.Music.Intro.Title[context.language]} />
                    <MultipleRows rows={Text.Activities.Music.Intro.Content[context.language]} />
                    <Box sx={{display:'flex',justifyContent:'center'}} >
                        <Fab
                            color="primary"
                            onClick={() => { window.open('/MusicalWork', '_blank') }}
                            sx={{ zIndex: 0 }}
                            variant="extended"
                        >
                            <Navigation sx={{ mr: 1 }} />
                            {Text.MusicalWork.Title[context.language]}
                        </Fab>
                    </Box>
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default Music;