// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component

const BoxSSPA = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxSSPA Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    const RenderTableData = () => (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                {Text.Admission.S1Admission.DateTitle[context.language]}
                            </TableCell>
                            <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                {Text.Admission.S1Admission.ScheduleTitle[context.language]}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Text.Admission.S1Admission.Schedule.map((row, i) => (
                                <TableRow hover key={i}>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {row.Date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {row.Schedule[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

    return (
        <Box>
            <Typography
                sx={{ color: 'primary.main' }}
                variant="h6"
            >
                {Text.Admission.S1Admission.SSPATitle[context.language]}
            </Typography>
            <Typography
                dangerouslySetInnerHTML={{ __html: Text.Admission.S1Admission.Intro[context.language] }}
                sx={{ color: 'black' }}
                variant="body1"
            />
            <RenderTableData />
            <Typography
                dangerouslySetInnerHTML={{ __html: Text.Admission.S1Admission.More[context.language] }}
                sx={{ color: 'black' }}
                variant="body1"
            />
        </Box>
    );
}
export default BoxSSPA;