// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

// TODO: Import MUI module
import BoardBackground from './BoardBackground.png';
import { Box, Rating, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WaterDrop, WaterDropOutlined, WbSunny, WbSunnyOutlined } from '@mui/icons-material';

// TODO: Import custom component
import { WebservicesYiYuan } from '../../../Common/Context';

// Import context

export default function BoxIntroBoard() {
    // TODO: Define useContext

    // TODO: Define variable
    const [info, setInfo] = React.useState({});
    const [searchParams] = useSearchParams();
    const isAdmin = searchParams.get('admin') === 'true';

    const printArea = React.useRef();

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'GetIntroBoard');
        formData.append('id', searchParams.get('id'));

        fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => {
                setInfo(result);
            })
            .catch((error) => { console.error('Error:', error); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrint = () => {
        const box = printArea.current;
        if (box) {
            html2canvas(box, { useCORS: true }).then(canvas => {
                const link = document.createElement('a');
                link.download = `${info.Title}.png`;
                link.href = canvas.toDataURL();
                link.click();
            });
        }
    };

    // TODO: Return Component
    return (
        isAdmin &&
        <Box
            className="IntroBoard"
            sx={{
                backgroundImage: `url(${BoardBackground})`,
                backgroundSize: 'contain',
                m: 2,
                width: '1200px',
                height: '800px',
            }}
            onClick={handlePrint}
            ref={printArea}
        >
            <Typography sx={{
                fontFamily: 'JasonHandwriting1',
                fontSize: '10rem',
                fontWeight: 'bold',
                mt: 2,
                mx: 2,
                textAlign: 'center',
            }}>
                {info.Title}
            </Typography>
            <Typography sx={{
                fontFamily: 'JasonHandwriting1',
                fontSize: '3rem',
                fontWeight: 'bold',
                mx: 2,
                textAlign: 'center',
            }}>
                {info.Intro}
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ m: 2, width: '700px', height: '380px' }}>
                    <Box
                        component="img"
                        src={`https://www.pohtyh.edu.hk/YiYuan/images/plants/${searchParams.get('id')}/cover.jpg`}
                        sx={{ width: 1, height: 1, objectFit: 'cover' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'center', m: 2, maxHeight: '380px', }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Rating
                            value={parseInt(info.Water)}
                            emptyIcon={<WaterDropOutlined fontSize="inherit" />}
                            icon={<WaterDrop fontSize="inherit" />}
                            readOnly
                            sx={{ color: '#05F', fontSize: '56px' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Rating
                            value={parseInt(info.Sunshine)}
                            emptyIcon={<WbSunnyOutlined fontSize="inherit" />}
                            icon={<WbSunny fontSize="inherit" />}
                            readOnly
                            sx={{ color: '#F50', fontSize: '56px' }}
                        />
                    </Box>
                    <QRCode
                        bgColor="transparent"
                        size={224}
                        style={{ height: "auto", maxWidth: "100%", marginTop:'8px', width: "100%" }}
                        value={`https://www.pohtyh.edu.hk/CD_BlessedBotanicalsPlant?id=${searchParams.get('id')}`}
                        viewBox={`0 0 224 224`}
                    />
                    <Typography sx={{
                        fontFamily: 'JasonHandwriting1',
                        fontSize: '3rem',
                        fontWeight: 'bold',
                        mx: 2,
                        textAlign: 'center',
                    }}>
                        Scan我知多D
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}