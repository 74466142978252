// TODO: Import React module
import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Unstable_Grid2 as Grid } from '@mui/material/';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import { ContentTitle, } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';
import ListText from '../Common/ListText';

const Creation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Creation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const items = [
        {
            Works: [
                {
                    Name: '風之子',
                    Description: ['創作：賴曉藍，顏浩然'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/風之子.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/風之子.jpg',
        },
        {
            Works: [
                {
                    Name: '纏',
                    Description: ['創作：黃柏銘'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/纏.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/纏.jpg',
        },
        {
            Works: [
                {
                    Name: 'The Treasure you should love',
                    Description: ['創作：司徒紀嵐校友'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/TheTreasureYouShouldLove.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/TheTreasureYouShouldLove.jpg',
        },
        {
            Works: [
                {
                    Name: '城市漫遊',
                    Description: ['創作：郭思潁，劉詩詠，郭雅雯，潘鎬賢'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/城市漫遊.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/城市漫遊.jpg',
        },
        {
            Works: [
                {
                    Name: '以恆毅力堅持',
                    Description: ['創作：梁鎮朗，陳子謙', '主唱：梁鎮朗，陳子謙，本校體育科老師，本校精英運動員，啦啦隊，及資訊日嘉賓'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/以恆毅力堅持.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/以恆毅力堅持.jpg',
        },
        {
            Works: [
                {
                    Name: '最有趣的事',
                    Description: ['曲、詞: 郭思潁/潘鎬賢/劉詩泳/郭雅雯'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/zui_you_qu_de_shi_20231128.mp3',
                },
            ],
            Image: null,
        },
        {
            Works: [
                {
                    Name: '告訴我，我聆聽',
                    Description: ['曲、詞: 司徒紀嵐'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/gao_su_wo_wo_ling_ting.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/gao_su_wo_wo_ling_ting.jpg',
        },
        {
            Works: [
                {
                    Name: '傳回',
                    Description: ['唱、曲詞創作: 呂依銘校友'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/chuen_wui.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/chuen_wui20230207.jpg',
        },
        {
            Works: [
                {
                    Name: '第九味',
                    Description: ['作曲: 庾鎧榮', '填詞: 姚煒銓'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/di_jiu_wei.mp4',
                    isVideo: true,
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/di_jiu_wei.jpg',
        },
        {
            Works: [
                {
                    Name: '美好的陽光',
                    Description: ['唱: 2021-2022年度鄧英喜合唱團', '曲詞創作: 劉珮娜/朱慧敏老師', '音樂: 顏浩然'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/beautiful_sunshine.mp3',
                },
                {
                    Name: '又到聖誕',
                    Description: ['唱: 2021-2022年度鄧英喜合唱團', '曲詞創作: 朱慧敏老師'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/christmas2022.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/beautiful_sunshine.jpg',
        },
        {
            Works: [
                {
                    Name: 'BETTER DAY',
                    Description: ['唱、曲詞創作: 顏浩然'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/better_day.mp3',
                },
                {
                    Name: 'Weather',
                    Description: ['唱、曲詞創作: 顏浩然'],
                    URL: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/weather.mp3',
                },
            ],
            Image: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Creation/better_day.jpg',
        },
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Stack className="SectionCreation" spacing={2} sx={{ m: 5 }}>
            <ContentTitle text={Text.MusicalWork.Creation[context.language]} />
            <Grid container spacing={2}>
                {
                    items.map((item, i) => (
                        <Grid xs={12} md={6}>
                            <Card
                                key={i}
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: 'tertiary.main',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    justifyContent: 'start',
                                    p: 2
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 1 }}>
                                    {
                                        item.Works.map((work, i) => (
                                            <CardContent key={i} sx={{ p: 1 }}>
                                                <Typography variant="h5">{work.Name}</Typography>
                                                <ListText
                                                    lines={work.Description}
                                                    sxIcon={{ color: 'tertiary.main' }}
                                                    sxText={{ color: 'text.secondary' }}
                                                />
                                                {
                                                    work.isVideo ?
                                                        <video width="100%" controls>
                                                            <source src={work.URL} />
                                                        </video> :
                                                        <ReactAudioPlayer controls src={work.URL} style={{ width: '100%' }} />
                                                }
                                            </CardContent>
                                        ))
                                    }
                                </Box>
                                {
                                    item.Image && <BoxImage src={item.Image} sx={{ mt: 2 }} />
                                }
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Stack>
    );
};
export default Creation;