// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../../Common/Context';

export default function BoxFirst() {
    // TODO: Define useContext

    // TODO: Define variable
    const [checked, setChecked] = React.useState(false);
    const [textArray, setTextArray] = React.useState([]);
    const [textArrayIndex, setTextArrayIndex] = React.useState(-1);
    const fadeTime = 5000;

    const [searchParams] = useSearchParams();

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'GetSlogan');
        formData.append('id', searchParams.get('id'));

        fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => {
                setTextArray(result);
            })
            .catch((error) => { console.error('Error:', error); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (textArray.length > 0) {
            setTextArrayIndex(0);
        }
    }, [textArray]);

    React.useEffect(() => {
        if (textArrayIndex >= 0) {
            setChecked((prev) => !prev);
            setTimeout(() => {
                if (textArray.length >= 1) {
                    setChecked((prev) => !prev);
                }
            }, fadeTime - 1000);
            setTimeout(() => {
                const idx = textArrayIndex;
                if (idx === textArray.length - 1) {
                    setTextArrayIndex(0);
                } else {
                    setTextArrayIndex(idx + 1);
                }
            }, fadeTime);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textArrayIndex]);

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            sx={{
                backgroundImage: `url("https://www.pohtyh.edu.hk/YiYuan/images/plants/${searchParams.get('id')}/cover.jpg")`,
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: `calc(100vw * 9 / 16)`,
            }}
        >
            <Fade
                in={checked}
                timeout={{
                    enter: 1000,
                    exit: 1000
                }}
            >
                <Typography
                    component="p"
                    gutterBottom
                    sx={{
                        color: '#FFF',
                        fontWeight: 'bold',
                        fontSize: { xs: '3rem', md: '6rem' },
                        textShadow: '0px 0px 10px #000',
                    }}
                    variant="h1"
                >
                    {textArrayIndex >= 0 && textArray[textArrayIndex]}
                </Typography>
            </Fade>
        </Box>
    );
}