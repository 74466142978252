// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Dialog, IconButton, Unstable_Grid2 as Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Close, Videocam, } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';

import Slide from './Slide_LT.jpg';

import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

const DialogVideo = ({ subject, setSubject }) => {
    const handleClose = () => {
        setSubject(null);
    };

    return (
        <Dialog
            fullWidth={true}
            open={Boolean(subject)}
            onClose={handleClose}
            maxWidth="lg"
        >
            <IconButton
                edge="end"
                onClick={handleClose}
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: 16,
                    top: 8,
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.7)',
                    }
                }}
            >
                <Close />
            </IconButton>
            <iframe
                src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/ElectiveSubjectIntro/${subject}.mp4`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: 0, width: '100%', height: '80vh' }}
                title="Elective Subject Introduction Video"
            />
        </Dialog>
    );
};

const GridItems = ({ onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxItems Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const subjects = ['BIO', 'CHEM', 'PHY', 'CHIST', 'GEO', 'BAFS', 'ECON', 'ICT', 'M2', 'PE', 'TH', 'VA', 'APL',];

    // TODO: Define event
    const handleClicked = (subject) => {
        onClick(subject);
    };

    // TODO: Return component
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                subjects.map((subject, i) => (
                    <Grid key={i} xs={6}>
                        <StackImage onClick={() => { handleClicked(subject) }} hover={true}>
                            <BoxImage src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/ElectiveSubjectIntro/${subject}.jpg`} />
                            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                                <Videocam sx={{ color: 'white' }} />
                                {Text.LearningAndTeacher.ElectiveSubjectIntro[subject][context.language]}
                            </Typography>
                        </StackImage>
                    </Grid>
                ))
            }
        </Grid>
    );
};

const ElectiveSubjectIntro = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component ElectiveSubjectIntro Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [openSubject, setOpenSubject] = React.useState(null);

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            id="ElectiveSubjectIntro"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <DialogVideo subject={openSubject} setSubject={setOpenSubject} />
            <GridItems onClick={setOpenSubject} />
        </BoxSubPage>
    );
}
export default ElectiveSubjectIntro;