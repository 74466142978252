// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Fab, Stack, Tooltip, } from '@mui/material';
import { Approval, AutoStories, Lyrics, MenuBook, School, VideoCameraFront } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const SpeedDialNavigation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SpeedDialNavigation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleClicked = (el) => {
        const element = document.getElementsByClassName(el);
        const y = element[0].getBoundingClientRect().top + window.scrollY - 156;
        window.scroll({ top: y, behavior: 'smooth' });
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{ bottom: 16, position: 'fixed', right: 16, }}>
            <Stack spacing={2}>
                <Tooltip title={Text.MusicalWork.CreativeCollection[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionEBook') }} size="small">
                        <MenuBook />
                    </Fab>
                </Tooltip>
                <Tooltip title={Text.MusicalWork.Performance[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionPerformance') }} size="small">
                        <VideoCameraFront />
                    </Fab>
                </Tooltip>
                <Tooltip title={Text.MusicalWork.Creation[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionCreation') }} size="small">
                        <Lyrics />
                    </Fab>
                </Tooltip>
                <Tooltip title={Text.MusicalWork.Interdisciplinary[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionInterdisciplinary') }} size="small">
                        <AutoStories />
                    </Fab>
                </Tooltip>
                <Tooltip title={Text.MusicalWork.Graduation[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionGraduation') }} size="small">
                        <School />
                    </Fab>
                </Tooltip>
                <Tooltip title={Text.MusicalWork.Musical[context.language]}>
                    <Fab color="secondary" onClick={() => { handleClicked('SectionMusical') }} size="small">
                        <Approval />
                    </Fab>
                </Tooltip>
            </Stack>
        </Box>
    );
};
export default SpeedDialNavigation;