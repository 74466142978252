// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import { Typography } from '@mui/material';

export const MultipleRows = ({ rows, variant, sx }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component TypographyMultipleRows Rendered');

    // TODO: Return component
    return (
        rows.map((row, i) => (
            <Typography
                key={i}
                sx={{ mb: 2, ...sx }}
                variant={variant ? variant : 'body1'}
            >
                {row}
            </Typography>
        ))
    );
};

export const ContentTitle = ({ text, sx }) => {
    // TODO: Return component
    return (
        <Typography sx={{ color: 'primary.main', ...sx }} variant="h6">{text}</Typography>
    );
};

export const SessionTitle = ({ text }) => {
    // TODO: Return component
    return (
        <Typography sx={{ color: 'primary.main', textDecoration: 'underline' }} variant="subtitle1">{text}</Typography>
    );
};