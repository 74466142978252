// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LabelIcon from '@mui/icons-material/Label';
import MenuIcon from '@mui/icons-material/Menu';

// Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

export default function BoxDrawer() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [openedMenu, setOpenedMenu] = React.useState(-1);

    const [openDrawer, setOpenDrawer] = React.useState(false);

    // TODO: Define event
    const navigate = useNavigate();

    const handleMenuOpen = (e, idx) => {
        if (idx === openedMenu) {
            handleMenuClose();
        } else {
            setOpenedMenu(idx);
        }
    };

    const handleMenuClose = () => {
        setOpenedMenu(-1);
    };

    const handleMenuClick = (page) => {
        navigate(page);
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            id="Top_BoxDrawer"
            sx={{
                display: { xs: 'block', md: 'none' },
                zIndex: context.Top_BoxDrawer_ZIndex,
            }}
        >
            <Button
                startIcon={<MenuIcon />}
                onClick={() => { setOpenDrawer(true) }}
                sx={{ height: 1, '&.MuiButton-root': { borderRadius: 0 } }}
                variant="contained"
            />
            <Drawer
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                    handleMenuClose();
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'primary.main',
                        minWidth: 350,
                        px: 2,
                    }
                }}
            >
                <List>
                    {
                        // TODO: Generate Title
                        Object.keys(Text.Top.Menu).map((key, i) => (
                            <Box key={i}>
                                <ListItemButton
                                    key={i}
                                    onClick={(e) => {
                                        if (Text.Top.Menu[key].Page) {
                                            handleMenuClick(Text.Top.Menu[key].Page);
                                        } else {
                                            handleMenuOpen(e, i)
                                        }
                                    }}
                                    sx={{
                                        color: 'white',
                                        px: { xs: 0.5, lg: 2 },
                                        '&:hover': { color: 'secondary.main' },
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    component="p"
                                                    sx={{ fontSize: '1rem', lineHeight: '1rem', textAlign: 'left' }}
                                                    variant="body"
                                                >
                                                    {Text.Top.Menu[key].Title[context.language]}
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    sx={{ textAlign: 'left' }}
                                                    variant="button"
                                                >
                                                    {Text.Top.Menu[key].Title.ENG}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    {openedMenu === i ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                {
                                    // TODO: Generate Sub-Title
                                    <Collapse in={openedMenu === i} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
                                                Text.Top.Menu[key].Subtitles.map((subItem, j) => (
                                                    <Box key={j}>
                                                        <ListItemButton
                                                            key={j}
                                                            onClick={(e) => {
                                                                if (subItem.Page) {
                                                                    handleMenuClick(subItem.Page);
                                                                }
                                                            }}
                                                            sx={{
                                                                color: 'white',
                                                                py: 0,
                                                                '&:hover': { color: 'secondary.main' },
                                                            }}
                                                        >
                                                            <ListItemIcon sx={{ color: 'tertiary.main', minWidth: 40 }}>
                                                                <LabelIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary={
                                                                <>
                                                                    <Typography
                                                                        component="p"
                                                                        sx={{ fontSize: '1rem', lineHeight: '1rem', textAlign: 'left' }}
                                                                        variant="body"
                                                                    >
                                                                        {subItem.Title[context.language]}
                                                                    </Typography>
                                                                    <Typography
                                                                        component="p"
                                                                        sx={{ textAlign: 'left' }}
                                                                        variant="button"
                                                                    >
                                                                        {subItem.Title.ENG}
                                                                    </Typography>
                                                                </>
                                                            } />
                                                            {subItem.Subtitles && <ExpandLess />}
                                                        </ListItemButton>
                                                        {
                                                            // TODO: Generate Sub-Title
                                                            subItem.Subtitles &&
                                                            <Collapse in={true}>
                                                                <List component="div" disablePadding>
                                                                    {
                                                                        subItem.Subtitles.map((CCItem, k) => (
                                                                            <ListItemButton
                                                                                key={k}
                                                                                onClick={(e) => {
                                                                                    if (CCItem.Page) {
                                                                                        handleMenuClick(CCItem.Page);
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    color: 'white',
                                                                                    py: 0,
                                                                                    '&:hover': { color: 'secondary.main' },
                                                                                }}
                                                                            >
                                                                                <ListItemIcon sx={{ color: 'tertiary.main', minWidth: 40, ml: 3 }}>
                                                                                    <LabelIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={
                                                                                    <>
                                                                                        <Typography
                                                                                            component="p"
                                                                                            sx={{ fontSize: '1rem', lineHeight: '1rem', textAlign: 'left' }}
                                                                                            variant="body"
                                                                                        >
                                                                                            {CCItem.Title[context.language]}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            component="p"
                                                                                            sx={{ textAlign: 'left' }}
                                                                                            variant="button"
                                                                                        >
                                                                                            {CCItem.Title.ENG}
                                                                                        </Typography>
                                                                                    </>
                                                                                } />
                                                                            </ListItemButton>
                                                                        ))
                                                                    }
                                                                </List>
                                                            </Collapse>
                                                        }
                                                    </Box>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                }
                            </Box>
                        ))
                    }
                </List>
            </Drawer>
        </Box>
    );
}