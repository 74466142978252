// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Box, Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';

// TODO: Import MUI icon

// Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import UsefulLinks_Alumni from './UsefulLinks_Alumni.png';
import UsefulLinks_Art from './UsefulLinks_Art.png';
import UsefulLinks_Calendar from './UsefulLinks_Calendar.png';
import UsefulLinks_eClass from './UsefulLinks_eClass.png';
//import UsefulLinks_JUPAS from './UsefulLinks_JUPAS.png';
import UsefulLinks_Library from './UsefulLinks_Library.png';
import UsefulLinks_Music from './UsefulLinks_Music.png';
import UsefulLinks_Publication from './UsefulLinks_Publication.png';
import UsefulLinks_PTA from './UsefulLinks_PTA.png';
import UsefulLinks_ReplacementParentAccount from './UsefulLinks_ReplacementParentAccount.png';
import UsefulLinks_ResetPassword from './UsefulLinks_ResetPassword.png';
import UsefulLinks_SchoolDevelopmentPlatform from './UsefulLinks_SchoolDevelopmentPlatform.png';
import UsefulLinks_WebSAMS from './UsefulLinks_WebSAMS.png';

import ButtonRecruit from './ButtonRecruit';

import BoxImage from '../Common/BoxImage';

const BoxUsefulLinks = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxUsefulLinks Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [buttons] = React.useState([
        {
            Title: { CHI: '學校周年刊物', ENG: 'Annual Publication' },
            Image: UsefulLinks_Publication,
            Page: 'https://www.pohtyh.edu.hk/Files/AnnualPublication2024.pdf',
            Target: '_blank',
        },
        {
            Title: { CHI: '校曆表', ENG: 'Calendar' },
            Image: UsefulLinks_Calendar,
            Page: 'https://www.pohtyh.edu.hk/SchoolProfile_SchoolCalendar',
            Target: '_blank',
        },
        {
            Title: { CHI: 'eClass', ENG: 'eClass' },
            Image: UsefulLinks_eClass,
            Page: 'https://eclass.pohtyh.edu.hk/templates/',
            Target: '_blank',
        },
        {
            Title: { CHI: '校務發展綜合平台', ENG: 'School Development Platform' },
            Image: UsefulLinks_SchoolDevelopmentPlatform,
            Page: 'https://www.pohtyh.edu.hk/SADCPlatform2/Login',
            Target: '_blank',
        },
        {
            Title: { CHI: '網上校管系統', ENG: 'WebSAMS' },
            Image: UsefulLinks_WebSAMS,
            Page: 'https://clo.edb.gov.hk/home/survey',
            Target: '_blank',
        },
        {
            Title: { CHI: '圖書館', ENG: 'Library' },
            Image: UsefulLinks_Library,
            Page: '/LT_PromotionReading',
        },
        {
            Title: { CHI: '英喜音樂創作匯萃', ENG: 'Music Creation' },
            Image: UsefulLinks_Music,
            Page: '/MusicalWork',
        },
        {
            Title: { CHI: '藝術創作園地', ENG: 'Art Creation' },
            Image: UsefulLinks_Art,
            Page: 'https://www.pohtyh.edu.hk/subjectWeb/va/index.html#Gallery',
            Target: '_blank',
        },
        {
            Title: { CHI: '補領家長帳戶', ENG: 'Replacement of Parent Account' },
            Image: UsefulLinks_ReplacementParentAccount,
            Page: '/UniPassParent',
        },
        {
            Title: { CHI: '帳戶密碼更改', ENG: 'Reset Password' },
            Image: UsefulLinks_ResetPassword,
            Page: '/UniPass',
        },
        {
            Title: { CHI: '家教會', ENG: 'Parent-Teacher Association' },
            Image: UsefulLinks_PTA,
            Page: '/PTA',
        },
        /*{
            Title: { CHI: '大學聯招網站', ENG: 'JUPAS' },
            Image: UsefulLinks_JUPAS,
            Page: 'https://www.jupas.edu.hk/tc/',
            Target: '_blank',
        },*/
        {
            Title: { CHI: '校友會', ENG: 'Alumni Association' },
            Image: UsefulLinks_Alumni,
            Page: '/Alumni',
        },
    ]);

    // TODO: Define event
    const navigate = useNavigate();

    const handleClick = (page, target) => {
        if (target === '_blank') {
            window.open(page, '_blank');
        } else {
            navigate(page);
        }
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Box id="Home_BoxUsefulLinks" sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }}>
            <Typography
                component="p"
                sx={{
                    color: 'black',
                    fontSize: { xs: '1.5rem', sm: '2.125rem' },
                    fontWeight: 'bold',
                    mb: 2,
                    textAlign: 'center',
                }}
            >
                {Text.Home.UsefulLinks.Title[context.language]}
            </Typography>
            <Grid container spacing={2} sx={{ maxWidth: { xs: '560px', md: '800px', lg: '1200px' } }}>
                {
                    buttons.map((button, i) => (
                        <Grid key={i} xs={6} md={4} lg={3}>
                            <Button
                                onClick={() => { handleClick(button.Page, button.Target) }}
                                sx={{
                                    backgroundColor: 'tertiary.main',
                                    flexDirection: 'column',
                                    height: 1,
                                    px: 0,
                                    py: 1,
                                    width: 1,
                                    '&:hover': { backgroundColor: 'tertiary.dark' },
                                }}
                                variant="contained"
                            >
                                <BoxImage
                                    src={button.Image}
                                    sx={{ aspectRatio: 'auto', height: 1, objectFit: 'scale-down', width: 0.5 }}
                                    sxSkeleton={{ height: 1, width: 0.5 }}
                                />
                                <Typography sx={{ color: 'black', textAlign: 'center', }} variant="body1">
                                    {button.Title[context.language]}
                                </Typography>
                            </Button>
                        </Grid>
                    ))
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, px: 2, width: 1, maxWidth: '1200px' }}>
                <ButtonRecruit />
            </Box>
        </Box>
    );
};
export default BoxUsefulLinks;