// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// TODO: Import MUI icon

// Import context
import { } from '../Common/Context';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';

import FabArrowButton from './FabArrowButton';

const BoxSlide = () => {
    // TODO: Define useContext

    // TODO: Define variable
    const refBoxSlide = React.useRef(null);

    const [marginTop, setMarginTop] = React.useState(0);

    const [showFab, setShowFab] = React.useState(true);
    const [BoxNewsY, setBoxNewsY] = React.useState(0);

    const images = React.useMemo(() => [
        'https://www.pohtyh.edu.hk/Files/Home/Slide20240308.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20240319.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20240305.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20231213.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230310102024.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230423.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230519150953.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230519151421.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230601180435.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230702103905.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230913131250.jpg',
        /*'https://www.pohtyh.edu.hk/Files/Home/Slide20230913164833.jpg',*/
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230915.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230918173916.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20230922152554.jpg',
        'https://www.pohtyh.edu.hk/Files/Home/Slide20231017153848.jpg',
    ], []);
    const fadeInterval = 2500;
    const [showedIndex, setShowedIndex] = React.useState(0);

    // TODO: Define event
    React.useEffect(() => {
        setTimeout(() => {
            setShowedIndex((showedIndex + 1) % images.length);
        }, fadeInterval)
    }, [images, showedIndex]);

    const handleResizeAndScroll = () => {
        const componentTop_BoxName = document.getElementById('Top_BoxName');
        const componentTop_BoxMenu = document.getElementById('Top_BoxMenu');
        if (componentTop_BoxName && componentTop_BoxMenu) {
            const componentHeight = componentTop_BoxName.offsetHeight + componentTop_BoxMenu.offsetHeight;
            setMarginTop(componentHeight);
        }

        if (refBoxSlide.current) {
            const boxRect = refBoxSlide.current.getBoundingClientRect();

            setBoxNewsY(
                boxRect.bottom
                + window.scrollY
                - document.getElementById('Top_BoxName').offsetHeight
                - document.getElementById('Top_BoxMenu').offsetHeight
            );

            if (boxRect.bottom <= window.innerHeight) {
                setShowFab(false);
            } else {
                setShowFab(true);
            }
        }
    };

    React.useEffect(handleResizeAndScroll, []);

    React.useEffect(() => {
        window.addEventListener('resize', handleResizeAndScroll);
        return () => { window.removeEventListener('resize', handleResizeAndScroll); };
    }, []);

    React.useEffect(() => {
        window.addEventListener('scroll', handleResizeAndScroll);
        return () => { window.removeEventListener('scroll', handleResizeAndScroll); };
    }, []);

    // TODO: Return component
    return (
        <Box
            id="Home_BoxSlide"
            sx={{
                height: `calc(100vw * 4 / 6)`,
                mt: `${marginTop}px`,
                position: 'relative',
                width: 1,
            }}
            ref={refBoxSlide}
        >
            {showFab && <FabArrowButton scrollToY={BoxNewsY} />}
            {
                images.map((image, i) => (
                    <Fade in={i === showedIndex} key={i} timeout={{ enter: 1000, exit: 1000 }}>
                        <Box>
                            <BoxImage
                                src={image}
                                sx={{ aspectRatio: 'auto', height: 1, objectFit: 'cover', position: 'absolute', width: 1, }}
                                sxSkeleton={{ aspectRatio: 'auto', height: 1, position: 'absolute', width: 1, }}
                            />
                        </Box>
                    </Fade>
                ))
            }
        </Box>
    );
};
export default BoxSlide;