// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import { ContentTitle, MultipleRows } from '../../Common/TypographyTYH';

const BoxPromotion = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxPromotion Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Box>
            <ContentTitle text={Text.LearningAndTeacher.PromotionReading.Promotion.Title[context.language]}/>
            <MultipleRows rows={Text.LearningAndTeacher.PromotionReading.Promotion.Content[context.language]}/>
        </Box>
    );
};
export default BoxPromotion;