// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows, } from '../Common/TypographyTYH';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import Slide from './Slide_StudentDevelopment.jpg';

const ClassBuilding = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component ClassBuilding Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20190918161647.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20190924155548.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20190930165058.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20211109130638.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20220905.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20220905Banner.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20230316165318.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20230615204512.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20231005081453.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20231005081805.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20231005082015.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/ClassBuilding/Img20231005082350.jpg',
    ];

    // TODO: Define event

    // TODO: Return component
    const RenderAwardTable = () => (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                Object.keys(Text.StudentDevelopment.ClassBuilding.Award.Table.Title).map((title) => (
                                    <TableCell
                                        align="center"
                                        key={title}
                                        sx={{ backgroundColor: 'primary.main', color: 'white' }}
                                    >
                                        {Text.StudentDevelopment.ClassBuilding.Award.Table.Title[title][context.language]}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Text.StudentDevelopment.ClassBuilding.Award.Table.Content[context.language].map((item, i) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                    <TableCell align="center">
                                        <Typography variant="body1">{item.Category}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{item.Item}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

    return (
        <BoxSubPage
            id="ClassBuilding"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.ClassBuilding.Target.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.ClassBuilding.Target.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.ClassBuilding.ClassTeacher.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.ClassBuilding.ClassTeacher.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.ClassBuilding.Award.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.ClassBuilding.Award.Content[context.language]} />
                    <RenderAwardTable />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (
                        <BoxImage key={i}src={image}/>
                    ))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}

export default ClassBuilding;