// TODO: Import React module
import * as React from 'react';

// TODO: Return today date
const date = new Date();
export const Today = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}-${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}`;

// TODO: Fetch data
export const fetchData = (formData, callBack) => {
    (async () => {
        fetch(Webservices, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => { callBack(result) })
            .catch((error) => { console.error('Error:', error); });
    })();
}

// TODO: Define reducer
const globalReducer = (data, action) => {
    switch (action.type) {
        case 'dispatchValue': return action.value;
        default: throw new Error();
    }
}

// TODO: Define webservices
export const Webservices = 'https://www.pohtyh.edu.hk/webservices.php';
export const WebservicesYiYuan = 'https://www.pohtyh.edu.hk/YiYuan/webservices.php';

// TODO: Define context
export const Context = React.createContext();

export const ContextProvider = ({ children }) => {
    // TODO: Create reducer
    const [language, dispatchLanguage] = React.useReducer(globalReducer, 'CHI');
    const [showPopup, dispatchShowPopup] = React.useReducer(globalReducer, true);

    // TODO: Define value
    const value = {
        language: language,
        dispatchLanguage: dispatchLanguage,

        //Top_Height_xs: 50,
        //Top_Height_md: 100,

        Top_ZIndex: 600,
        Top_BoxName_ZIndex: 610,
        Top_BoxDrawer_ZIndex: 590,

        Common_Image_Width: { xs: '160px', sm: '200px', md: '224px', lg: '360px' },
        //Common_Image_Width_With_Margin: { xs: '176px', sm: '256px', md: '296px', lg: '376px' },

        showPopup: showPopup,
        dispatchShowPopup: dispatchShowPopup,
    }

    return (
        <Context.Provider value={value} >
            {children}
        </Context.Provider >
    );
};