// TODO: Import React module
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_SchoolProfile.jpg';

import BoxSubPage from '../Common/BoxSubPage';

const SchoolCalendar = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SchoolCalendar Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const location = useLocation();

    // TODO: Define event

    // TODO: Define function
    function GetTitle() {
        const idx = Text.Top.Menu.SchoolProfile.Subtitles.findIndex(item => item.Page === location.pathname);
        return idx >= 0 ? Text.Top.Menu['SchoolProfile'].Subtitles[idx].Title[context.language] : '--';
    }

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <iframe
                src={Text.SchoolProfile.SchoolCalendar.URL}
                style={{ height: '100%', minHeight: '800px', width: '100%', }}
                title={`${GetTitle()}`}
            />
        </BoxSubPage>
    );
};
export default SchoolCalendar;