// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Stack } from '@mui/material';

// TODO: Import custom component

const StackImage = ({ children, hover, onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component StackImage Rendered');

    // TODO: Define useContext

    // TODO: Define Imperative Handle

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Stack
            onClick={onClick}
            sx={{
                alignItems: 'center',
                backgroundColor: 'primary.main',
                borderRadius: '20px',
                display: 'flex',
                height: 1,
                py: 2.5,
                px: 1.5,
                '&:hover': {
                    backgroundColor: hover ? 'primary.light' : 'primary.main',
                    cursor: hover ? 'pointer' : 'auto',
                },
                '&:hover img': { filter: hover ? 'saturate(150%)' : 'saturate(100%)' },
            }}
        >
            {children}
        </Stack>
    );
};

export default StackImage;