// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context, } from '../Common/Context';
import { Text, } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

const CafeGeographie = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component CafeGeographie Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            id="CafeGeographie"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_Cafe.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <MultipleRows rows={Text.CampusDevelopment.CafeGeographie.Intro[context.language]} />
                    <ContentTitle text={Text.CampusDevelopment.CafeGeographie.Cafe.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.CafeGeographie.Cafe.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/CafeGeographie/Cafe.jpg"
                        sx={{ width: 1, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <ContentTitle text={Text.CampusDevelopment.CafeGeographie.SelfDirectedLearning.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.CafeGeographie.SelfDirectedLearning.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/CafeGeographie/SelfDirectedLearning.jpg"
                        sx={{ width: 1, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <ContentTitle text={Text.CampusDevelopment.CafeGeographie.Geography.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.CafeGeographie.Geography.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/CafeGeographie/Geography.jpg"
                        sx={{ width: 1, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <ContentTitle text={Text.CampusDevelopment.CafeGeographie.Geography.Title[context.language]} />
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};

export default CafeGeographie;