// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';

// TODO: Import custom component
import BoxName from './BoxName';
import BoxMenu from './BoxMenu';

export default function Top() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            id="Top"
            sx={{
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                zIndex: context.Top_ZIndex,
            }}
        >
            <BoxName />
            <BoxMenu />
        </Box>
    );
}