// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {  } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';

const MusicRoom = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MusicRoom Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/CampusDevelopment/MusicRoom/Img20230111150948.jpg',
        'https://www.pohtyh.edu.hk/Files/CampusDevelopment/MusicRoom/Img20230111151018.jpg',
        'https://www.pohtyh.edu.hk/Files/CampusDevelopment/MusicRoom/Img20230111151039.jpg',
        'https://www.pohtyh.edu.hk/Files/CampusDevelopment/MusicRoom/Img20230111151133.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="MusicRoom"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.CampusDevelopment.MusicRoom.Intro.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.MusicRoom.Intro.Content[context.language]} />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default MusicRoom;