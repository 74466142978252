// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_StudentDevelopment.jpg';
import BoxAirScout from './UniformedGroups/BoxAirScout';
import BoxGirlGuides from './UniformedGroups/BoxGirlGuides';
import BoxFlagGuards from './UniformedGroups/BoxFlagGuards';

import BoxSubPage from '../Common/BoxSubPage';

const UniformedGroups = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component UniformedGroups Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <Box id="UniformedGroups">
                <BoxAirScout />
                <BoxGirlGuides />
                <BoxFlagGuards />
            </Box>
        </BoxSubPage>
    );
}

export default UniformedGroups;