// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

// TODO: Export component
const SelectClasses = React.forwardRef((props, ref) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SelectClasses Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [value, setValue] = React.useState('%');
    const [options, setOptions] = React.useState([]);

    // TODO: Define event
    React.useImperativeHandle(ref, () => ({
        GetValue() {return value;}
    }));

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const action = 'UniPassParent_GetClasses';
            const formData = new FormData();
            formData.append('action', action);

            fetch(Webservices, { method: 'POST', body: formData })
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log(`${action}:`, 'Data received');
                        setOptions(result);
                    } else {
                        console.log(`${action}:`, 'Can not get info');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        fetchData();
    }, []);

    // TODO: Define function
    const renderMenuItem = (options) => {
        const menuItems = [];

        menuItems.push(<MenuItem key="AllClasses" value="%">{`${Text.Common.Classes[context.language]}`}</MenuItem>);

        Object.keys(options).forEach((form) => {
            menuItems.push(
                <ListSubheader key={form}>{Text.Common.ClassLevel[form][context.language]}</ListSubheader>
            );

            options[form].forEach((option) => {
                menuItems.push(<MenuItem key={option.Class} value={option.Class}>{`${option.Class}`}</MenuItem>);
            });
        });

        return menuItems;
    }

    // TODO: Return component
    return (
        <FormControl sx={{ minWidth: '100px' } }>
            <InputLabel>{Text.Common.Classes[context.language]}</InputLabel>
            <Select label={Text.Common.Classes[context.language]} value={value} onChange={handleSelectChange}>
                {renderMenuItem(options)}
            </Select>
        </FormControl>
    );
});

export default SelectClasses;