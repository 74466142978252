// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';

import BoxItems from './MediaPressCoverage/BoxItems';
import DialogCoverage from './MediaPressCoverage/DialogCoverage';

import Slide from './Slide_Achievements.jpg';


const MediaPressCoverage = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MediaPressCoverage Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [currentItem, setCurrentItem] = React.useState(null);

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            id="MediaPressCoverage"
            image={Slide}
            title={Text.Top.Menu['ACH'].Title[context.language]}
            subtitles={Text.Top.Menu['ACH'].Subtitles}
        >
            <BoxItems onClick={setCurrentItem} />
            <DialogCoverage currentItem={currentItem} onClose={setCurrentItem} />
        </BoxSubPage>
    );
}
export default MediaPressCoverage;