// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';

// TODO: Import MUI icon
import { ExpandMore, Link, Subject, } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component

const BoxELearning = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxELearning Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleLinkClicked = (url) => { window.open(url, '_blank') };

    // TODO: Return component
    const RenderAccordion = ({ title, links }) => {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ display: 'flex', gap: 1, }} variant="body1">
                        <Subject color="primary" />
                        {title[context.language]}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        links.map((link, i) => (
                            <Typography
                                key={i}
                                onClick={() => { handleLinkClicked(link.url) }}
                                sx={{ cursor: 'pointer', display: 'flex', gap: 1, '&:hover': { color: 'grey' } }}
                            >
                                <Link color="primary" />
                                {link.text}
                            </Typography>
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        )
    };

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.ELearning.Title[context.language]}
            </Typography>
            {
                Text.LearningAndTeacher.PromotionReading.ELearning.Links.Items.map((subject, i) => (
                    <RenderAccordion key={i} title={subject.Subject} links={subject.Links} />)
                )
            }
        </Box>
    );
};
export default BoxELearning;