// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';

import Slide from './Slide_Achievements.jpg';

import TabsYears from './AwardsExternal/TabsYears';
import TableData from './AwardsExternal/TableData';

const AwardsExternal = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component AwardsExternal Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define reference

    // TODO: Define variable
    const [selectedYear, setSelectedYear] = React.useState();

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component

    return (
        <BoxSubPage
            id="AwardsExternal"
            image={Slide}
            title={Text.Top.Menu['ACH'].Title[context.language]}
            subtitles={Text.Top.Menu['ACH'].Subtitles}
        >
            <TabsYears onChange={setSelectedYear} />
            <TableData selectedYear={selectedYear} />
        </BoxSubPage>
    );
}

export default AwardsExternal;