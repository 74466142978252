// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Avatar, Box, SpeedDial, SpeedDialAction, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// TODO: Import MUI icon
import { /*Draw, Face,   OutdoorGrill,*/ EmojiEvents, HowToVote, MusicNote, School, Whatshot, } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const SpeedDialFocus = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SpeedDialFocus Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const [opened, setOpened] = React.useState(true);

    const actions = [
        {
            Title: { CHI: '中一學位申請', ENG: '中一學位申請' },
            Icon: <HowToVote />,
            URL: 'https://www.pohtyh.edu.hk/ADM_S1Application',
            EndDate: '2025-01-16',
        },
        {
            Title: { CHI: '第二十三屆家長教師會執行委員會委員選舉結果公布', ENG: '第二十三屆家長教師會執行委員會委員選舉結果公布' },
            Icon: <HowToVote />,
            URL: 'https://www.pohtyh.edu.hk/Album?ID=20241104',
            EndDate: '2024-12-01',
        },
        {
            Title: { CHI: '2024 中學文憑試成績', ENG: '2024 中學文憑試成績', },
            Icon: <EmojiEvents />,
            URL: 'https://www.pohtyh.edu.hk/ACH_DSEResult2024',
        },
        {
            Avatar: 'https://www.pohtyh.edu.hk/Files/Album/20240903/thumbs/IMG_9619.jpg',
            Title: { CHI: '謝雪心女士真情分享講座', ENG: '謝雪心女士真情分享講座', },
            URL: 'https://www.pohtyh.edu.hk/Album?ID=20240903',
        },
        {
            Title: { CHI: '2024 DSE優秀學生訪問', ENG: '2024 DSE優秀學生訪問', },
            Icon: <School />,
            URL: 'https://www.pohtyh.edu.hk/DSEOutstandingStudentVisit',
        },
        {
            Title: { CHI: '中一新生註冊須知', ENG: '中一新生註冊須知', },
            Icon: <School />,
            URL: 'https://www.pohtyh.edu.hk/ADM_S1Registration',
            EndDate: '2024-07-12',
        },
        {
            Title: { CHI: '中一叩門學位申請須知', ENG: '中一叩門學位申請須知', },
            Icon: <School />,
            URL: 'https://www.pohtyh.edu.hk/ADM_S1Registration',
            EndDate: '2024-07-12',
        },
        {
            Avatar: 'https://www.pohtyh.edu.hk/Files/Album/20240524/Slide.jpg',
            Title: { CHI: '天主教聖安德肋小學<br/>Live In The Moment音樂會', ENG: '天主教聖安德肋小學<br/>Live In The Moment音樂會', },
            URL: 'https://www.pohtyh.edu.hk/Album?ID=20240524',
            EndDate: '2024-07-12',
        },
        {
            Avatar: 'https://www.pohtyh.edu.hk/Files/Album/2024LWLF4/Slide.jpg',
            Title: { CHI: '中四全方位喜義日', ENG: '中四全方位喜義日', },
            URL: 'https://www.pohtyh.edu.hk/Album?ID=2024LWLF4',
            EndDate: '2024-07-12',
        },
        {
            Title: { CHI: '中六級歲月如歌歌唱比賽', ENG: '中六級歲月如歌歌唱比賽' },
            Icon: <MusicNote />,
            URL: 'https://www.pohtyh.edu.hk/MusicContest',
            EndDate: '2024-11-16',
        },
        /*{
            Title: { CHI: '音樂比賽2024-2025', ENG: 'Music Contest 2024-2025' },
            Icon: <MusicNote />,
            URL: 'https://www.pohtyh.edu.hk/MusicContest',
            EndDate: '2025-01-26',
        },
        {
            Title: { CHI: '全港小學生兒童繪本主題曲創作大賽2024', ENG: '全港小學生兒童繪本主題曲創作大賽2024' },
            Icon: <Draw />,
            URL: 'https://www.pohtyh.edu.hk/Files/Focus/20240117_全港小學生兒童繪本主題曲創作大賽2024.pdf',
            EndDate: '2024-03-02',
        },
        {
            Title: { CHI: '校友會燒烤', ENG: '校友會燒烤' },
            Icon: <OutdoorGrill />,
            URL: 'https://www.facebook.com/events/1155110535857958?mibextid=9l3rBW',
            EndDate: '2024-03-16',
        },
        {
            Title: { CHI: '2024年度校友校董選舉', ENG: '2024年度校友校董選舉' },
            Icon: <OutdoorGrill />,
            URL: 'https://www.pohtyh.edu.hk/Files/Alumni/2024年度校友校董選舉通知.pdf',
            EndDate: '2024-03-08',
        },
        {
            Title: { CHI: '第十六屆「法團校董會家長校董選舉」結果', ENG: '第十六屆「法團校董會家長校董選舉」結果' },
            Icon: <HowToVote />,
            URL: 'https://www.pohtyh.edu.hk/Album?ID=20240321',
            EndDate: '2024-04-01',
        },*/
    ];

    // TODO: Define event
    const theme = useTheme();

    // TODO: Return component
    return (
        <>
            <style>
                {
                    `@keyframes gradient {
                        0 % {
                            background-position: 0% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                        100% {
                            background-position: 0% 50%;
                        }
                    }`
                }
            </style>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                direction="down"
                FabProps={{
                    color: 'tertiary',
                    variant: 'extended',
                    sx: {
                        animation: 'gradient 2s ease infinite',
                        backgroundImage: `linear-gradient(90deg, ${theme.palette.tertiary.dark}, ${theme.palette.tertiary.light})`,
                        backgroundSize: '200% 200%',
                        height: '64px',
                    },
                }}
                sx={{ alignItems: 'end', position: 'fixed', top: { xs: 64, md: 24 }, right: 16 }}
                icon={
                    <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                        {<Whatshot fontSize="large" />}
                        <Typography variant="h6">{Text.Home.Focus.Title[context.language]}</Typography>
                    </Box>
                }
                open={opened}
                //onClick={() => { navigate(`/News`); } }
                onMouseEnter={() => { setOpened(true) }}
                onMouseLeave={() => { setOpened(false) }}
            >
                {
                    actions.map((action, i) => {
                        if (
                            !action.EndDate ||
                            new Date() <= new Date(action.EndDate) ||
                            (new Date()).toISOString().split('T')[0] === (new Date(action.EndDate))?.toISOString().split('T')[0]
                        ) {
                            return (
                                <SpeedDialAction
                                    key={i}
                                    FabProps={{
                                        size: 'large',
                                        sx: {
                                            animation: 'gradient 2s ease infinite',
                                            backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,
                                            backgroundSize: '200% 200%',
                                            justifyContent: 'start',
                                            '&:hover': { backgroundColor: 'secondary.light', },
                                        },
                                        variant: 'extended'
                                    }}
                                    icon={
                                        <a
                                            style={{
                                                alignItems: 'center',
                                                color: 'black',
                                                display: 'flex',
                                                gap: 8,
                                                textDecoration: 'none',
                                            }}
                                            href={action.URL}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {action.Avatar && <Avatar src={action.Avatar} />}
                                            {action.Icon && <>{action.Icon}</>}
                                            <span dangerouslySetInnerHTML={{ __html: action.Title[context.language] }}></span>
                                        </a>
                                    }
                                />
                            )
                        }
                        return false;
                    })
                }
            </SpeedDial>
        </>
    );
};

export default React.memo(SpeedDialFocus);