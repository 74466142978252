// TODO: Import React module
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// TODO: Import MUI module
import { } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../../Common/Context';

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';

const BoxImages = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxImages Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20150919112108.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20161210105820.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20180825125233.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20181006103406.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20190117.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20201110124135.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20210821154145.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20210825114854.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20210908135631.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20211217134045.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20211230121021.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20220826134409.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230420172823.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230827193331.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230828094350.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230828105534.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230828110926.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230907074032.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20230911162418.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20231013152720.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20231013162602.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/StudentLeadership/Img20231021142444.jpg',
    ];

    // TODO: Define event
    const theme = useTheme();

    // TODO: Define function

    // TODO: Return component
    return (
        <Swiper
            autoplay={{ delay: 2500, disableOnInteraction: false, }}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{ clickable: true, }}
            breakpoints={{ 0: { slidesPerView: 2, spaceBetween: 16, }, }}
            modules={[Autoplay, Navigation, Pagination]}
            navigation={false}
            style={{ '--swiper-pagination-color': theme.palette.primary.main, paddingBottom: '36px', }}
        >
            {
                images.map((item, i) => (
                    <SwiperSlide
                        key={i}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            width: context.Common_Image_Width,
                        }}>
                        <BoxImage src={item} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    );
};
export default BoxImages;