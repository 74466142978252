import { createTheme } from '@mui/material/styles';

export const ThemeTYH = createTheme({
    /*components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color:'white',
                }
            },
        },
    },*/
    palette: {
        primary: {
            main: '#00AB94',
            light: '#80CBC4',
            dark: '#004d40',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FFFABE',
            light: '#FFFDE7',
            dark: '#FFF176',
        },
        tertiary: {
            main: '#FBDDE0',
            light: '#F7E6EC',
            dark: '#F2A0BC',
            contrastText: '#000000',
        },
    },
    typography: {
        fontFamily: ['Fjalla One', 'Noto Sans HK'].join(','),
    },
});