// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

// TODO: Import MUI icon

// TODO: Import custom component

export default function BoxFloorplan() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            id="YiYuan_BoxFloorplan"
            sx={{
                backgroundColor: grey['200'],
                py: 2,
            }}
        >
            <Grid container>
                <Grid
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Box sx={{ m: 2 }}>
                        <Typography
                            sx={{
                                color: 'success.dark',
                                fontSize: { xs: '1.1rem', sm: '1.5rem', md: '2.5rem' },
                                fontWeight: 'bold',
                            }}
                            variant="h4"
                        >
                            實景導覽
                        </Typography>
                        <Typography variant="body1">
                            本組會定期更新貽園720°全景相片，讓大家可以實景觀看貽園裡的植物，及週邊環境動態資訊。
                        </Typography>
                        <Typography variant="body1">　</Typography>
                        <Typography variant="body1">
                            按下全景圖中的圖示，便可查看植物的詳細資訊，甚至可以觀看在它們身上過往發生過的片段。
                        </Typography>
                        <Typography
                            component="p"
                            sx={{
                                mt: 1,
                                textAlign: 'center',
                            }}
                        >
                            <Button
                                color="success"
                                onClick={() => { window.open('https://www.pohtyh.edu.hk/gardening/vr/index.htm','_blank') }}
                                variant="contained"
                            >
                                立即體驗
                            </Button>
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Box
                        sx={{
                            backgroundImage: 'url("https://www.pohtyh.edu.hk/YiYuan/images/floorplan.jpg")',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 1,
                            width: 1,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}