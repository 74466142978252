// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';

const SubjectsDSE = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SubjectsDSE Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <Box id="SubjectsDSE" sx={{ mb: 2 }}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.CoreCurriculumTitle[context.language]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.CoreCurriculumContent[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.NonCoreCurriculumTitle[context.language]}
                                        </Typography>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject1Title[context.language]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject1Content[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.NonCoreCurriculumTitle[context.language]}
                                        </Typography>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject2Title[context.language]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject2Content[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.NonCoreCurriculumTitle[context.language]}
                                        </Typography>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject3Title[context.language]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.ElectiveSubject3Content[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell align="center" rowSpan={3} sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.OtherCoursesTitle[context.language]}
                                        </Typography>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.CompulsorySubjectsTitle[context.language]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.MusicArt[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.PE[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {Text.LearningAndTeacher.SubjectsDSE.OLE[context.language]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </BoxSubPage>
    );
};
export default SubjectsDSE;