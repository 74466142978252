// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import Creation from './Creation.js';
import EBook from './eBook.js';
import Graduation from './Graduation.js';
import Interdisciplinary from './Interdisciplinary.js';
import Musical from './Musical.js';
import Performance from './Performance'
import SpeedDialNavigation from './SpeedDialNavigation';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

export default function MusicalWork() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="MusicalWork"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            disableMenu={true}
            disableSubtitle={true}
            title={Text.MusicalWork.Title[context.language]}
        >
            <SpeedDialNavigation />
            <EBook />
            <Performance/>
            <Creation />
            <Interdisciplinary />
            <Graduation />
            <Musical />
        </BoxSubPage>
    );
}