// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Typography, Unstable_Grid2 as Grid, } from '@mui/material';

// TODO: Import MUI icon
import { Tour, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';
import StackImage from '../../Common/StackImage';

const BoxItems = ({ onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxItems Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const tours = [
        { ID: '20240629', Title: '英國劍橋遊學團' },
        { ID: '20240626', Title: '四日三夜韓國首爾考察團' },
        { ID: '20230705', Title: '體育科台灣交流團' },
        { ID: '20230630', Title: '「公民知識之旅：文化與環境考察團」' },
        { ID: '20230628', Title: '視藝科臺北藝術文化五天遊學團' },
    ];

    // TODO: Define event
    const navigate = useNavigate();

    const handleClicked = (tour) => {
        navigate(`/Album?ID=${tour.ID}`);
    };

    // TODO: Return component
    const RenderStackImage = ({ tour }) => (
        <StackImage onClick={() => { handleClicked(tour) }} hover={true}>
            <BoxImage src={`https://www.pohtyh.edu.hk/Files/Album/${tour.ID}/Slide.jpg`} />
            <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <Tour sx={{ color: 'white' }} />
                {tour.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                tours.map((tour, i) => (
                    <Grid xs={6}>
                        <RenderStackImage key={i} tour={tour} />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default React.memo(BoxItems);