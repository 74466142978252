// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Fab, Unstable_Grid2 as Grid, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { MusicNote, YouTube } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import { ContentTitle, } from '../Common/TypographyTYH';
import StackImage from '../Common/StackImage';

const Performance = ({ onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Performance Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const coverages = [
        {
            ID: '9TrrNtaXlts',
            Title: '天主教聖安德肋小學 Live In The Moment音樂會花絮',
        },
        {
            ID: 'VsEq26D8eJs',
            Title: '佛教志蓮小學音樂表演',
        },
        {
            ID: 'vVRXP-caw1I',
            Title: '港澳信義會明道小學表演',
        },
        {
            ID: 'b9pzkoUKyxw',
            Title: '博愛醫院陳國威小學表演',
        },
        {
            ID: 'ccbx_3rQOwQ',
            Title: '2324音樂比賽花絮',
        },
        {
            ID: '1ULKMMKT4tE',
            Title: '隱形的翅膀 萬幸音樂會',
        },
    ];

    // TODO: Define event
    const handleClicked = (coverage) => {
        onClick(coverage);
    };

    // TODO: Return component
    const RenderStackImage = ({ coverage }) => (
        <StackImage onClick={() => { handleClicked(coverage) }} hover={true}>
            <iframe
                src={`https://www.youtube.com/embed/${coverage.ID}`}
                style={{ aspectRatio: '16/9', border: 0, width: '100%', height: '100%' }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={coverage.Title}
            ></iframe>
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <MusicNote sx={{ color: 'white' }} />
                {coverage.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Stack className="SectionPerformance" spacing={2} sx={{ m: 5 }}>
            <ContentTitle text={Text.MusicalWork.Performance[context.language]} />
            <Grid container spacing={2} sx={{ mb: 2 }}>
                {
                    coverages.map((coverage, i) => (
                        <Grid key={i} xs={12} md={6}>
                            <RenderStackImage coverage={coverage} />
                        </Grid>
                    ))
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <Fab
                    color="primary"
                    onClick={() => { window.open('https://www.youtube.com/@pohtyhmusic', '_blank') }}
                    sx={{ zIndex: 0 }}
                    variant="extended"
                >
                    <YouTube sx={{ mr: 1 }} />
                    {Text.MusicalWork.Youtube[context.language]}
                </Fab>
            </Box>
        </Stack>
    );
};

export default React.memo(Performance);