// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// Import context
import { WebservicesYiYuan } from '../../Common/Context';

// TODO: Import custom component

export default function BoxSlide() {
    // TODO: Define useContext

    // TODO: Define variable
    const [images, setImages] = React.useState([]);
    const fadeInterval = 2500;
    const [showedIndex, setShowedIndex] = React.useState(0);

    // TODO: Define event
    React.useEffect(GetBanner, []);

    React.useEffect(() => {
        setTimeout(() => {
            setShowedIndex((showedIndex + 1) % images.length);
        }, fadeInterval)
    }, [images, showedIndex]);

    // TODO: Define function
    function GetBanner() {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetBanner');

            fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        setImages(result);
                    }
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    }

    // TODO: Return component
    return (
        <Box
            id="YiYuan_BoxSlide"
            sx={{
                height: `calc(100vw * 9 / 16 * 0.5)`,
                position: 'relative',
                width: 1,
            }}
        >
            {
                images.map((image, i) => (
                    <Fade
                        in={i === showedIndex}
                        key={i}
                        timeout={{
                            enter: 1000,
                            exit: 1000
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundImage: `url("https://www.pohtyh.edu.hk/YiYuan/images/banner/${image.Image}")`,
                                backgroundSize: 'cover',
                                display: 'flex',
                                height: 1,
                                justifyContent: 'center',
                                left: 0,
                                position: 'absolute',
                                top: 0,
                                width: '100%',
                            }}
                        >
                            <Typography
                                component="p"
                                gutterBottom
                                sx={{
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    fontSize: { xs: '2.5rem', md: '4rem' },
                                    textShadow: '0px 0px 10px #000',
                                }}
                                variant="h1"
                            >
                                {image.Slogan}
                            </Typography>
                        </Box>
                    </Fade>
                ))
            }
        </Box>
    );
}