// TODO: Import React module
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// TODO: Import MUI icon

// Import context
import { Context } from '../Common/Context';

// TODO: Import custom component

export default function BoxSubPageSelectMenu(props) {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const location = useLocation();
    const [items, setItems] = React.useState([]);

    // TODO: Define event
    const navigate = useNavigate();

    const handleChange = (e) => {
        navigate(e.target.value);
    };

    React.useEffect(() => {
        let arr = [];

        props.subtitles.forEach((subItem) => {
            if (subItem.Page) {
                arr.push(RenderMenuItem(arr.length, subItem.Page, subItem.Title[context.language]));
            } else if (!subItem.Page && subItem.Subtitles) {
                arr.push(RenderMenuItem(arr.length, subItem.Subtitles[0].Page, subItem.Title[context.language]));
            }

            if (subItem.Subtitles) {
                subItem.Subtitles.forEach((CCItem) => {
                    arr.push(RenderMenuItem(arr.length, CCItem.Page, `- ${CCItem.Title[context.language]}`));
                })
            }
        })

        setItems(arr);
    }, [context.language, props.subtitles]);

    // TODO: Define function
    function RenderMenuItem(key, value, text) {
        return (<MenuItem key={key} value={value}>{text}</MenuItem>)
    }

    // TODO: Return component
    return (
        <FormControl variant="standard">
            <Select
                autoWidth
                onChange={handleChange}
                sx={{ color: 'primary.main' }}
                value={items.length > 0 ? location.pathname : ''}
            >
                {items}
            </Select>
        </FormControl>
    );
}