// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Link, Typography } from '@mui/material';

// TODO: Import MUI icon
import { Link as LinkIcon, SportsBasketball } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_Activities.jpg';

const PE = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component PE Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20211105123850.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20221009110350.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20230913164326.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20230927170628.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20230927170738.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20231017153048.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20231102160922.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/PE/Img20231102172150.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="PE"
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.Activities.PE.Purpose.Title[context.language]} />
                    <MultipleRows rows={Text.Activities.PE.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.Activities.PE.Team.Title[context.language]} />
                    <MultipleRows rows={Text.Activities.PE.Team.Content[context.language]} />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                        {
                            Text.Activities.PE.Team.Teams.map((item, i) => (
                                <Typography key={i} sx={{ display: 'flex', gap: 1, minWidth: { xs: 200, md: 220, lg: 230 } }}>
                                    <SportsBasketball color="primary" />
                                    {item[context.language]}
                                </Typography>
                            ))
                        }
                    </Box>
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.Activities.PE.Links.Title[context.language]}/>
                    {
                        Text.Activities.PE.Links.Content.map((item, i) => (
                            <Box key={i} sx={{ display: 'flex', gap: 1 }}>
                                <LinkIcon />
                                <Link href={item} target="_blank">{item}</Link>
                            </Box>
                        ))
                    }
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default PE;