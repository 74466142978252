// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Link, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Business, Call, Email, Fax, Instagram } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const Footer = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Footer Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const currentYear = new Date().getFullYear();

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    const RenderBoxCopyright = () => (
        <Box sx={{ flex: 1 }}>
            <Typography sx={{ color: 'white' }} variant="body2">{Text.Common.SchoolName.ENG}</Typography>
            <Typography sx={{ color: 'white' }} variant="body2">
                {`Copyright ©${currentYear} All rights reserved`}
            </Typography>
        </Box>
    );

    const RenderBoxInfo = () => (
        <Box sx={{ mt: { xs: 2, md: 0 } }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 0, md: 2 } }}>
                <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', }} variant="body2">
                    <Business fontSize="small" />
                    {`
                        ${Text.SchoolProfile.SchoolProfile.AddressTitle[context.language]}:
                        ${Text.SchoolProfile.SchoolProfile.Address[context.language]}
                    `}
                </Typography>
                <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', }} variant="body2">
                    <Email fontSize="small" />
                    {`
                        ${Text.SchoolProfile.SchoolProfile.EmailTitle[context.language]}: 
                        ${Text.SchoolProfile.SchoolProfile.Email}
                    `}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 0, md: 2 }, 'a:visited': { color: 'white' } }}>
                <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', }} variant="body2">
                    <Call fontSize="small" />
                    {`
                        ${Text.SchoolProfile.SchoolProfile.ContactNoTitle[context.language]}: 
                        ${Text.SchoolProfile.SchoolProfile.ContactNo}
                    `}
                </Typography>
                <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', }} variant="body2">
                    <Fax fontSize="small" />
                    {`
                        ${Text.SchoolProfile.SchoolProfile.FaxNoTitle[context.language]}: 
                        ${Text.SchoolProfile.SchoolProfile.FaxNo}
                    `}
                </Typography>
                <Link
                    href="https://www.instagram.com/pohtyh_official/"
                    rel="noopener"
                    sx={{ alignItems: 'center', color: 'white', display: 'flex' }}
                    target="_blank"
                    underline="none"
                    variant="body2"
                >
                    <Instagram fontSize="small" />
                    {`IG: pohtyh_official`}
                </Link>
            </Box>
        </Box>
    );

    return (
        <Box
            id="Footer"
            sx={{
                backgroundColor: 'primary.main',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                p: 2,
                px: 5,
            }}
        >
            <RenderBoxCopyright />
            <RenderBoxInfo />
        </Box>
    );
};
export default React.memo(Footer);