// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, } from '@mui/material';

// TODO: Import MUI icon

// Import context
import { } from '../Common/Context';

// TODO: Import custom component
import BoxImage from './BoxImage';

export default function BoxSubPageSlide(props) {
    // TODO: Define useContext

    // TODO: Define variable
    const [marginTop, setMarginTop] = React.useState(0);

    // TODO: Define event
    React.useEffect(() => {
        handleResizeAndScroll();
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', handleResizeAndScroll);
        return () => { window.removeEventListener('resize', handleResizeAndScroll); };
    }, []);

    React.useEffect(() => {
        window.addEventListener('scroll', handleResizeAndScroll);
        return () => { window.removeEventListener('scroll', handleResizeAndScroll); };
    }, []);

    const handleResizeAndScroll = () => {
        const componentTop_BoxName = document.getElementById('Top_BoxName');
        const componentTop_BoxMenu = document.getElementById('Top_BoxMenu');
        if (componentTop_BoxName && componentTop_BoxMenu) {
            const componentHeight = componentTop_BoxName.offsetHeight + componentTop_BoxMenu.offsetHeight;
            setMarginTop(componentHeight);
        }

    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            id="BoxSubPageSlide"
            sx={{
                minHeight: props.image ? `calc(100vw * 9 / 16 * 0.5)` : { xs: 0, md: 50 },
                mt: `${marginTop}px`,
                position: 'relative',
                width: 1,
            }}
        >
            {
                props.image &&
                <BoxImage
                    src={props.image}
                    sx={{ aspectRatio: 'auto', height: 1, objectFit: 'cover', width: 1, }}
                    sxSkeleton={{ height: 1, width: 1 }}
                >
                </BoxImage>
            }
        </Box>
    );
}