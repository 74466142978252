// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_StudentDevelopment.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth, } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';

const Houses = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Houses Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20151030121729.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20231127141224.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20180202153206.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20190125081207.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20190322150037.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img20230707135008.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/Houses/Img_02.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="Houses"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    {
                        Text.StudentDevelopment.Houses.Intro[context.language].map((item, i) => (
                            <Typography key={i} sx={{ color: 'black', mb: 2 }} variant="body1">
                                {item}
                            </Typography>
                        ))
                    }
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}

export default Houses;