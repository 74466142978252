// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Alert, Box, Button, Paper, Snackbar, Stack, TextField, Typography } from '@mui/material';

// TODO: Import MUI icon
import { ArrowRight, Report } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

import BoxSubPage from '../Common/BoxSubPage';

const UniPass = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component UniPass Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [resetInfo, setResetInfo] = React.useState({ UID: '', OldPW: '', NewPW: '', ConfirmPW: '' });
    const [forgotInfo, setForgotInfo] = React.useState({ STID: '', HKID: '' });
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('error');

    const [showForgotForm, setShowForgotForm] = React.useState(false);
    const [autoResetPassword, setAutoResetPassword] = React.useState(null);

    // TODO: Define event
    const handleResetButtonClick = () => {
        if (resetInfo.UID.length === 0) {
            setErrorMessage(Text.UniPass.Error.UID[context.language]);
            return;
        }
        if (resetInfo.OldPW.length === 0) {
            setErrorMessage(Text.UniPass.Error.OldPassword[context.language]);
            return;
        }
        if (resetInfo.NewPW.length === 0) {
            setErrorMessage(Text.UniPass.Error.NewPassword[context.language]);
            return;
        }
        if (resetInfo.NewPW !== resetInfo.ConfirmPW) {
            setErrorMessage(Text.UniPass.Error.ConfirmPassword[context.language]);
            return;
        }

        const fetchData = async () => {
            const action = 'UniPass_Reset';
            const formData = new FormData();
            formData.append('action', action);
            formData.append('uID', resetInfo.UID);
            formData.append('oldPW', resetInfo.OldPW);
            formData.append('newPW', resetInfo.NewPW);

            fetch(Webservices, { method: 'POST', body: formData })
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log(`${action}:`, 'Data received');
                        if (result !== '舊密碼不正確!') {
                            setSnackbarSeverity('success');
                        }
                        setErrorMessage(result);
                    } else {
                        console.log(`${action}:`, 'Can not get info');
                    }
                })
                .catch((error) => { console.error('Error:', error) });
        }
        fetchData();
    }

    const handleForgotButtonClick = () => {
        if (
            forgotInfo.STID.length !== 9 ||
            !(/^[a-zA-Z]$/.test(forgotInfo.STID.charAt(0))) ||
            forgotInfo.STID.toUpperCase().charAt(0) !== 'S' ||
            !(/^\d{8}$/.test(forgotInfo.STID.slice(-8)))
        ) {
            setErrorMessage(Text.UniPass.Error.STID[context.language]);
            return;
        }
        if (
            forgotInfo.HKID.length !== 7 ||
            !(/^[a-zA-Z]$/.test(forgotInfo.HKID.charAt(0))) ||
            !(/^\d{6}$/.test(forgotInfo.HKID.slice(-6)))
        ) {
            setErrorMessage(Text.UniPass.Error.HKID[context.language]);
            return;
        }

        const fetchData = async () => {
            const action = 'UniPass_Forgot';
            const formData = new FormData();
            formData.append('action', action);
            formData.append('uID', forgotInfo.STID);
            formData.append('HKID', forgotInfo.HKID);

            fetch(Webservices, { method: 'POST', body: formData })
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log(`${action}:`, 'Data received');
                        setAutoResetPassword(`${Text.UniPass.ForgotAutoReset[context.language]}${result}`);
                    } else {
                        console.log(`${action}:`, 'Can not get info');
                        setErrorMessage(Text.UniPass.ForgotResetFail[context.language]);
                    }
                })
                .catch((error) => { console.error('Error:', error) });
        }
        fetchData();
    }

    const handleClose = (event) => {
        setErrorMessage(null);
        setSnackbarSeverity('error');
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            title={Text.UniPass.Title[context.language]}
        >
            <Box id="UniPass">
                <Typography variant="h6">{Text.UniPass.Intro[context.language]}</Typography>
                {
                    Text.UniPass.Description[context.language].map((item, i) => (
                        <Box key={i} sx={{ display: 'flex', gap: 1 }}>
                            <ArrowRight color="primary" fontSize="small" />
                            <Typography key={i}>{item}</Typography>
                        </Box>
                    ))
                }
                <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
                    <Report color="primary" />
                    <Typography color="primary" variant="h6">{Text.UniPass.Notice[context.language]}</Typography>
                </Box>
                <Paper elevation={3} sx={{ my: 2 }}>
                    {
                        !showForgotForm &&
                        <Stack spacing={2} sx={{ p: 2 }}>
                            <TextField
                                label={Text.UniPass.Account[context.language]}
                                onChange={(e) => { setResetInfo((prevState) => ({ ...prevState, UID: e.target.value })) }}
                                value={resetInfo.UID}
                            />
                            <TextField
                                label={Text.UniPass.OldPassword[context.language]}
                                onChange={(e) => { setResetInfo((prevState) => ({ ...prevState, OldPW: e.target.value })) }}
                                type="password"
                                value={resetInfo.OldPW}
                            />
                            <TextField
                                label={Text.UniPass.NewPassword[context.language]}
                                onChange={(e) => { setResetInfo((prevState) => ({ ...prevState, NewPW: e.target.value })) }}
                                type="password"
                                value={resetInfo.NewPW}
                            />
                            <TextField
                                label={Text.UniPass.ConfirmPassword[context.language]}
                                onChange={(e) => { setResetInfo((prevState) => ({ ...prevState, ConfirmPW: e.target.value })) }}
                                type="password"
                                value={resetInfo.ConfirmPW}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleResetButtonClick}>
                                    {Text.UniPass.Modify[context.language]}
                                </Button>
                                <Button variant="outlined" color="error" onClick={() => { setShowForgotForm(true) }}>
                                    {Text.UniPass.Forgot[context.language]}
                                </Button>
                            </Box>
                        </Stack>
                    }
                    {
                        showForgotForm &&
                        <Stack spacing={2} sx={{ p: 2 }}>
                            {
                                Boolean(autoResetPassword) ?
                                    <Typography sx={{ p: 2, textAlign: 'center' }} variant="h6">
                                        {autoResetPassword}
                                    </Typography> :
                                    <>
                                        <Typography variant="body1">{Text.UniPass.ForgotDescription[context.language]}</Typography>
                                        <TextField
                                            focused
                                            label={Text.UniPass.StudentID[context.language]}
                                            onChange={(e) => { setForgotInfo((prevState) => ({ ...prevState, STID: e.target.value })) }}
                                            placeholder={Text.UniPass.StudentIDPlaceholder}
                                            value={forgotInfo.STID}
                                        />
                                        <TextField
                                            focused
                                            label={Text.UniPass.HKID[context.language]}
                                            onChange={(e) => { setForgotInfo((prevState) => ({ ...prevState, HKID: e.target.value })) }}
                                            placeholder={Text.UniPass.HKIDPlaceholder}
                                            value={forgotInfo.HKID}
                                        />
                                    </>
                            }
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                {
                                    !Boolean(autoResetPassword) &&
                                    <Button variant="contained" color="primary" onClick={handleForgotButtonClick}>
                                        {Text.Common.Submit[context.language]}
                                    </Button>
                                }
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setShowForgotForm(false);
                                        setAutoResetPassword(null);
                                    }}>
                                    {Text.Common.Back[context.language]}
                                </Button>
                            </Box>
                        </Stack>
                    }
                </Paper>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={handleClose}
                open={Boolean(errorMessage)}
            >
                <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: 1 }}>{errorMessage}</Alert>
            </Snackbar>
        </BoxSubPage>
    );
}
export default UniPass;