// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

// TODO: Import MUI icon
import { CancelRounded } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { Context, Webservices } from '../Common/Context';
/*import { Text } from '../Common/Text';*/

const DialogPopup = () => {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [content, setContent] = React.useState([]);

    // TODO: Define event
    React.useEffect(() => {
        const GetPopup = () => {
            (async () => {
                const formData = new FormData();
                formData.append('action', 'Home_GetPopup');
                fetch(Webservices, { method: 'POST', body: formData, })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.length > 0) {
                            setContent(result);
                            context.dispatchShowPopup({type: 'dispatchValue', value: false});
                        }
                    })
                    .catch((error) => { console.error('Error:', error); });
            })();
        }
        if (context.showPopup) {
            GetPopup();
        }
    }, [context]);

    const handleClose = () => {
        setContent([]);
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={content.length > 0}
            onClose={handleClose}
        >
            {
                content.length > 0 &&
                <>
                    <DialogActions sx={{ pb: 0 }}>
                        <IconButton onClick={handleClose} sx={{ p: 0 }}>
                            <CancelRounded />
                        </IconButton>
                    </DialogActions>
                    <DialogTitle sx={{color:'primary.main'} }>{content[0].title}</DialogTitle>
                    <DialogContent dangerouslySetInnerHTML={{ __html: content[0].content }}>
                    </DialogContent>
                </>
            }
        </Dialog>
    );
}
export default DialogPopup;