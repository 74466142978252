// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component
import BoxSubPageLeftMenu from './BoxSubPageLeftMenu';
import BoxSubPageSlide from './BoxSubPageSlide';
import BoxSubPageSubTitle from './BoxSubPageSubTitle';
import BoxSubPageTitle from './BoxSubPageTitle';
import Top from '../Top/Top';
import Footer from '../Footer/Footer';

export default function BoxSubPage(props) {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Define function

    // TODO: Return component
    return (
        <Stack sx={{ height: '100%', }}>
            <Top />
            <BoxSubPageSlide image={props.image} />
            <BoxSubPageTitle disableMenu={props.disableMenu} title={props.title} subtitles={props.subtitles} />
            <Divider />
            <Container id={props.id} sx={{ flex: 1, my: 2 }}>
                <Stack direction="row" spacing={{ xs: 0, md: 2 }}>
                    {
                        props.disableMenu !== true && props.subtitles &&
                        <BoxSubPageLeftMenu subtitles={props.subtitles} />
                    }
                    <Stack sx={{ flexGrow: 1, }}>
                        {
                            props.disableSubtitle !== true && props.subtitles &&
                            <BoxSubPageSubTitle subtitles={props.subtitles} />
                        }
                        {props.children}
                    </Stack>
                </Stack>
            </Container>
            <Footer />
        </Stack>
    );
}