// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Import material icon
import Brightness5Icon from '@mui/icons-material/Brightness5';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import WaterIcon from '@mui/icons-material/Water';

// TODO: Import custom component

// Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

export default function LiveDataTable() {
    // TODO: Define useContext
    const context = React.useContext(Context);
    
    // TODO: Define variable
    const [data, setData] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState(false);

    // TODO: Define event
    React.useEffect(() => {
        GetData();
    },[]);

    // TODO: Define function
    function GetData() {
        setLoadingData(true);

        (async () => {
            if (true) {
                const formData = new FormData();
                formData.append('action', 'GetData');

                fetch(
                    'https://www.pohtyh.edu.hk/MyTYH5/webservices/SolarHarvest.php',
                    {
                        method: 'POST',
                        body: formData,
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result) {
                            console.log('GetData:', 'Data received');
                            setData(result);
                        } else {
                            console.log('GetData:', 'Can not get data');
                        }

                        setLoadingData(false);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        })();
    }

    // TODO: Return component
    return (
        <>
            {
                loadingData ?
                    <Skeleton sx={{ height: 75, ml: 1, mr: 1 }} /> :
                    <>
                        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            <Chip color="warning" icon={<Brightness5Icon />} label={Text.CampusDevelopment.SolarPhotovoltaicSystems.irradiance[context.language] + ': ' + (data.irradiance ? data.irradiance : '--')} />
                            <Chip color="error" icon={<DeviceThermostatIcon />} label={Text.CampusDevelopment.SolarPhotovoltaicSystems.temperature[context.language] + ': ' + (data.tempAVG ? data.tempAVG + '°C' : '--')} />
                            <Chip color="info" icon={<WaterIcon />} label={Text.CampusDevelopment.SolarPhotovoltaicSystems.humidity[context.language] + ': ' + (data.humidityAVG ? data.humidityAVG + '%' : '--')} />
                        </Stack>
                        <Typography sx={{ mt: 1 }} variant="body1">{Text.CampusDevelopment.SolarPhotovoltaicSystems.generation[context.language]}</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                            {Text.CampusDevelopment.SolarPhotovoltaicSystems.today[context.language]}
                                        </TableCell>
                                        <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                            {Text.CampusDevelopment.SolarPhotovoltaicSystems.thisMonth[context.language]}
                                        </TableCell>
                                        <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                            {Text.CampusDevelopment.SolarPhotovoltaicSystems.grandTotal[context.language]}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.generation && data.generation.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell align="right" sx={{ color: 'primary.main' }}>{Text.CampusDevelopment.SolarPhotovoltaicSystems[row.title][context.language]}</TableCell>
                                                <TableCell>{row.daily}</TableCell>
                                                <TableCell>{row.monthly}</TableCell>
                                                <TableCell>{row.total}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
            }
        </>
    );
}