// TODO: Import React module
import * as React from 'react';
import {
    Chart as ChartJS,
    PointElement,
    LineElement,
    Filler,
    RadialLinearScale,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component

// TODO: Register ChartJS
ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
);

const BoxChart = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxChart Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [chartData, setChartData] = React.useState([]);

    const data = {
        labels: [
            Text.Admission.Characteristics.DomainTitle.A[context.language],
            Text.Admission.Characteristics.DomainTitle.B[context.language],
            Text.Admission.Characteristics.DomainTitle.C[context.language],
            Text.Admission.Characteristics.DomainTitle.D[context.language],
            Text.Admission.Characteristics.DomainTitle.E[context.language],
            Text.Admission.Characteristics.DomainTitle.F[context.language],
            Text.Admission.Characteristics.DomainTitle.G[context.language],
            Text.Admission.Characteristics.DomainTitle.H[context.language],
            Text.Admission.Characteristics.DomainTitle.I[context.language],
        ],
        datasets: [
            {
                data: chartData,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        plugins: { legend: { display: false } },
        scales: {
            r: {
                pointLabels: { font: { size: 16 } },
                suggestedMin: 0,
                suggestedMax: 5,
                ticks: {
                    display: false,
                    stepSize: 1,
                },
            },
        }
    };

    // TODO: Define event
    const generateRandomData = () => {
        const newData = Array(9)
            .fill()
            .map(() => Math.floor(Math.random() * 3) + 3);
        setChartData(newData);
    };

    React.useEffect(() => {
        const interval = setInterval(() => {generateRandomData();}, 5000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        generateRandomData();
    }, []);

    // TODO: Return component
    return (
        <Box sx={{ width: 0.5 }}>
            <Radar data={data} options={options} />
        </Box>
    );
}

export default BoxChart;