// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Skeleton } from '@mui/material';

// TODO: Import context
import { Context } from './Context';

// TODO: Import custom component
const BoxImage = ({ debug, disableSkeleton, hover, src, sx, sxSkeleton }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxImage Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const [loaded, setLoaded] = React.useState(false);

    // TODO: Define event

    // TODO: Return component
    return (
        <>
            {
                ((!loaded && !disableSkeleton) || debug) &&
                <Skeleton sx={{
                    aspectRatio: '16/9',
                    transform: 'scale(1)',
                    width: context.Common_Image_Width,
                    ...sxSkeleton,
                }} />
            }
            <Box
                component="img"
                onLoad={() => setLoaded(true)}
                src={src}
                sx={{
                    aspectRatio: '16/9',
                    display: (loaded && !debug ? 'block' : 'none'),
                    objectFit: 'cover',
                    width: context.Common_Image_Width,
                    '&:hover': { filter: hover ? 'saturate(150%)' : 'saturate(100%)' },
                    ...sx,
                }}
            />
        </>
    );
};

export default React.memo(BoxImage);