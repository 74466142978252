// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Button, Box, Pagination, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { ArrowCircleLeft, ArrowCircleRight, CancelRounded, } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';

// TODO: Import custom component

const DialogCoverage = ({ currentItem, onClose }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DialogActivity Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    // TODO: Define event
    const handleDialogClosed = () => {
        onClose(null);
    }

    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? currentItem.files.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === currentItem.files.length - 1 ? 0 : prevIndex + 1));
    };

    React.useEffect(() => { setCurrentImageIndex(0) }, [currentItem]);

    // TODO: Return component
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(currentItem)}
            onClose={handleDialogClosed}
        >
            {
                currentItem &&
                <>
                    <DialogActions sx={{ pb: 0 }}>
                        <IconButton onClick={handleDialogClosed} sx={{ p: 0 }}>
                            <CancelRounded />
                        </IconButton>
                    </DialogActions>
                    <DialogTitle>{currentItem.Title}</DialogTitle>
                    <DialogContent sx={{ overflowX:'hidden'}}>
                        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: 1 }}>
                            <Box>
                                <IconButton onClick={handlePrevClick}>
                                    <ArrowCircleLeft />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                                <Box
                                    component="img"
                                    src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Highlights/${currentItem.path}/${currentItem.files[currentImageIndex]}`}
                                    sx={{ height: '50vh', objectFit:'contain'}}
                                />
                            </Box>
                            <Box>
                                <IconButton onClick={handleNextClick}>
                                    <ArrowCircleRight />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, width: 1 }}>
                            {currentItem.files.map((thumbnail, i) => (
                                <Box
                                    key={i}
                                    component="img"
                                    src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Highlights/${currentItem.path}/${thumbnail}`}
                                    sx={{
                                        border: `${currentImageIndex === i ? '2px dotted green' : 'none'}`,
                                        objectFit: 'cover',
                                        p: 0.5,
                                        width: '64px',
                                        height: '64px',
                                    }}
                                    onClick={() => setCurrentImageIndex(i)}
                                />
                            ))}
                        </Box>
                        {/*<Box sx={{ display: 'flex', justifyContent: 'center', width: 1, }}>
                            <Pagination
                                count={currentItem.files.length}
                                page={currentImageIndex + 1}
                                onChange={(event, value) => setCurrentImageIndex(value - 1)}
                            />
                        </Box>*/}
                        {/*<Stack>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: currentItem.Intro }}
                                variant="body1"
                            />
                            <iframe
                                src={currentItem.Filename}
                                style={{ border: 0, height: '480px', width: '100%', }}
                                title={currentItem.Title}
                            />
                        </Stack>*/}
                        <Box sx={{ p: { xs: 0, md: 2, lg: 4 } }}>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: currentItem.content[context.language] }}
                                variant="body1"
                            />
                        </Box>
                    </DialogContent>
                </>
            }
        </Dialog >
    );
}

export default DialogCoverage;