// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_Activities.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

export default function HappyFriday() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = {
        society_ams: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_ams.jpg',
        society_badminton: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_badminton.jpg',
        society_baseballSoftball: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_baseballSoftball.jpg',
        society_basketball: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_basketball.jpg',
        society_boardgame: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_boardgame.jpg',
        society_chem: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_chem.jpg',
        society_choir: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_choir.jpg',
        society_coffee: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_coffee.jpg',
        society_communityAmbassador: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_communityAmbassador.jpg',
        society_creation: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_creation.jpg',
        society_cyc: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_cyc.jpg',
        society_dancing: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_dancing.jpg',
        society_darts: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_darts.jpg',
        society_desserts: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_desserts.jpg',
        society_drama: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_drama.jpg',
        society_dubbing: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_dubbing.jpg',
        society_eventManagement: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_eventManagement.jpg',
        society_field: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_field.jpg',
        society_FilmAppreciation: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_FilmAppreciation.jpg',
        society_flag: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_flag.jpg',
        society_floral: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_floral.jpg',
        society_football: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_football.jpg',
        society_game: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_game.jpg',
        society_geo: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_geo.jpg',
        society_girlGuides: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_girlGuides.jpg',
        society_hourse: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_hourse.jpg',
        society_is: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_is.jpg',
        society_japanese: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_japanese.jpg',
        society_jpc: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_jpc.jpg',
        society_korean: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_korean.jpg',
        society_media: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_media.jpg',
        society_mobile_app: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_mobile_app.jpg',
        society_papercraft: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_papercraft.jpg',
        society_psychology: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_psychology.jpg',
        society_rowing: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_rowing.jpg',
        society_symphonicBand: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_symphonicBand.jpg',
        society_tableTennis: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_tableTennis.jpg',
        society_volleyball: 'https://www.pohtyh.edu.hk/Files/Activities/HappyFriday/society_volleyball.jpg',
    }

    // TODO: Define event

    // TODO: Define function
    function RenderGridSociety(props) {
        return (
            <Grid container spacing={2}>
                {
                    props.list.map((item, i) => (
                        item.Description &&
                        <Grid key={i} xs={6}>
                            <StackImage>
                                <BoxImage src={images[item.ID]} />
                                <Typography sx={{ color: 'white', fontWeight: 'bold', mt: 1, width: 0.95 }} variant="body1">
                                    {item[context.language]}
                                </Typography>
                                <Typography
                                    dangerouslySetInnerHTML={{ __html: item.Description[context.language] }}
                                    sx={{ color: 'white', mt: 1, width: 0.95 }}
                                    variant="body2"
                                />
                            </StackImage>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <Box id="HappyFriday">
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.Activities.HappyFriday.Description[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.Activities.HappyFriday.Description[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
                <RenderGridSociety list={Text.Activities.HappyFriday.Society} />
                <ContentTitle text={Text.Activities.HappyFriday.NonHappyFriday[context.language]} />
                <RenderGridSociety list={Text.Activities.HappyFriday.NSociety} />
            </Box>
        </BoxSubPage>
    );
}