// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_StudentDevelopment.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows, } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';
import BoxImage from '../Common/BoxImage';

const DisciplineCounselling = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DisciplineCounselling Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img01.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img02.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img03.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img04.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img05.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/DisciplineCounselling/Img06.jpg',
    ];

    // TODO: Define event

    // TODO: Return component
    const RenderDiscipline = () => (
        <>
            <Typography color="primary" sx={{ fontWeight: 'bold', textAlign: 'center', }} variant="h6">
                {Text.StudentDevelopment.DisciplineCounselling.Slogan[context.language]}
            </Typography>
            <ContentTitle
                sx={{ fontWeight: 'bold' }}
                text={Text.StudentDevelopment.DisciplineCounselling.Discipline.Title[context.language]}
            />
            <ContentTitle text={Text.StudentDevelopment.DisciplineCounselling.Discipline.Purpose.Title[context.language]} />
            <MultipleRows rows={Text.StudentDevelopment.DisciplineCounselling.Discipline.Purpose.Content[context.language]} />
            <ContentTitle text={Text.StudentDevelopment.DisciplineCounselling.Discipline.Aim.Title[context.language]} />
            <ListText lines={Text.StudentDevelopment.DisciplineCounselling.Discipline.Aim.Content[context.language]} />
            <ContentTitle text={Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.Title[context.language]} />
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.DirectorOfStudentsAffairs.Title[context.language]}:
                </Typography>
                <Typography variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.DirectorOfStudentsAffairs.Member[context.language]}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.DeputyDirectorOfStudentsAffairs.Title[context.language]}:
                </Typography>
                <Typography variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.DeputyDirectorOfStudentsAffairs.Member[context.language]}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.TeacherOfStudentsAffairs.Title[context.language]}:
                </Typography>
                <Typography variant="body1">
                    {Text.StudentDevelopment.DisciplineCounselling.Discipline.Members.TeacherOfStudentsAffairs.Member[context.language]}
                </Typography>
            </Box>
        </>
    );

    const RenderCounselling = () => (
        <>
            <ContentTitle
                sx={{ fontWeight: 'bold' }}
                text={Text.StudentDevelopment.DisciplineCounselling.Counselling.Title[context.language]}
            />
            <ContentTitle text={Text.StudentDevelopment.DisciplineCounselling.Counselling.Purpose.Title[context.language]} />
            <MultipleRows rows={Text.StudentDevelopment.DisciplineCounselling.Counselling.Purpose.Content[context.language]} />
            <ContentTitle text={Text.StudentDevelopment.DisciplineCounselling.Counselling.Vision.Title[context.language]} />
            <MultipleRows rows={Text.StudentDevelopment.DisciplineCounselling.Counselling.Vision.Content[context.language]} />
        </>
    );

    return (
        <BoxSubPage
            id="DisciplineCounselling"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <RenderDiscipline />
                    <RenderCounselling />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (
                        <BoxImage key={i} src={image} />
                    ))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}

export default DisciplineCounselling;