// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// TODO: Import MUI icon
import { Groups } from '@mui/icons-material';

// TODO: Import context
import { Context, } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component

const BoxLibrarian = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxLibrarian Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const theme = useTheme();

    // TODO: Define event

    // TODO: Return component
    const RenderTableXS = () => (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Chief.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.Chief.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.DeputyChief.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.DeputyChief.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography key={i} sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Ambassador.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.Ambassador.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography key={i} sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography color="primary" sx={{ textAlign: 'center' }} variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Title[context.language]}
                        </Typography>
                    </TableCell>
                </TableRow>
                {
                    Array.from(Array(5), (_, i) => (
                        <React.Fragment key={i}>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography color="primary" variant="body1">
                                        {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Leaders.Title[i + 1][context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography color="primary" variant="body1">
                                        {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Members.Title[context.language]}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {
                                        Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Leaders.Members[i + 1].map((item, i) => (
                                            <Typography key={i} variant="body2">{item}</Typography>
                                        ))
                                    }
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {
                                        Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Members.Members[i + 1].map((item, i) => (
                                            <Typography key={i} variant="body2">{item}</Typography>
                                        ))
                                    }
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))
                }
            </TableBody>
        </Table>
    );

    const RenderTableMD = () => (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Chief.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.Chief.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.DeputyChief.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.DeputyChief.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography key={i} sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography color="primary" variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Ambassador.Title[context.language]}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                        {
                            Text.LearningAndTeacher.PromotionReading.Librarian.Members.Ambassador.Members.map((item, i) => (
                                <React.Fragment key={i}>
                                    {
                                        i > 0 &&
                                        <Typography sx={{ color: 'black' }} component="span" variant="body2"> / </Typography>
                                    }
                                    <Typography key={i} sx={{ color: 'black' }} component="span" variant="body2">{item}</Typography>
                                </React.Fragment>
                            ))
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={5}>
                        <Typography color="primary" sx={{ textAlign: 'center' }} variant="body1">
                            {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Title[context.language]}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    {
                        Array.from(Array(5), (_, i) => (
                            <TableCell key={i}>
                                <Typography color="primary" sx={{ fontSize: { xs: '0.75rem', lg: '1rem' } }} variant="body1">
                                    {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Leaders.Title[i + 1][context.language]}
                                </Typography>
                            </TableCell>
                        ))
                    }
                </TableRow>
                <TableRow>
                    {
                        Array.from(Array(5), (_, i) => (
                            <TableCell key={i} sx={{ verticalAlign: 'top' }}>
                                {
                                    Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Leaders.Members[i + 1].map((item, i) => (
                                        <Typography key={i} variant="body2">{item}</Typography>
                                    ))
                                }
                            </TableCell>
                        ))
                    }
                </TableRow>
                <TableRow>
                    {
                        Array.from(Array(5), (_, i) => (
                            <TableCell key={i}>
                                <Typography color="primary" variant="body1">
                                    {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Members.Title[context.language]}
                                </Typography>
                            </TableCell>
                        ))
                    }
                </TableRow>
                <TableRow>
                    {
                        Array.from(Array(5), (_, i) => (
                            <TableCell key={i} sx={{ verticalAlign: 'top' }}>
                                {
                                    Text.LearningAndTeacher.PromotionReading.Librarian.Members.Librarians.Members.Members[i + 1].map((item, i) => (
                                        <Typography key={i} variant="body2">{item}</Typography>
                                    ))
                                }
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableBody>
        </Table>
    );

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ display: 'flex', color: 'black', gap: 1 }} variant="body1">
                <Groups />
                {Text.LearningAndTeacher.PromotionReading.Librarian.Members.Title[context.language]}
            </Typography>
            <TableContainer component={Paper}>
                {
                    useMediaQuery(theme.breakpoints.down('md')) ?
                        <RenderTableXS /> :
                        <RenderTableMD />
                }
            </TableContainer>
        </Box>
    );
};

export default BoxLibrarian;