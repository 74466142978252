// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Button, } from '@mui/material';

// TODO: Import MUI icon
import { Work } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const ButtonRecruit = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component ButtonRecruit Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const navigate = useNavigate();

    const handleButtonClick = () => navigate('/Recruit');

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            {
                ((new Date()) <= new Date('2024-09-22')) &&
                <Button onClick={handleButtonClick} variant="contained" startIcon={<Work />}>
                    {Text.Recruit.Title[context.language]}
                </Button >
            }
        </>
    );
}
export default ButtonRecruit;