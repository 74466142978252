// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxSlide from './BlessedBotanicals/BoxSlide';
import BoxIntro from './BlessedBotanicals/BoxIntro';
import BoxEBook from './BlessedBotanicals/BoxEBook';
import BoxKnowledge from './BlessedBotanicals/BoxKnowledge';
import BoxActivities from './BlessedBotanicals/BoxActivities';
import BoxFloorplan from './BlessedBotanicals/BoxFloorplan';
import BoxPlant from './BlessedBotanicals/BoxPlant';
import BoxEGarden from './BlessedBotanicals/BoxEGarden';
import BoxTech from './BlessedBotanicals/BoxTech';

export default function BlessedBotanicals() {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BlessedBotanicals Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box id="BlessedBotanicals">
            <BoxSubPage
                image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_BB.jpg'}
                title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
                subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
            >
                <BoxSlide />
                <BoxIntro />
                {/*<BoxEBook />*/}
                <BoxKnowledge />
                <BoxActivities />
                <BoxFloorplan />
                <BoxPlant />
                <BoxEGarden />
                <BoxTech />
            </BoxSubPage>
        </Box>
    );
}