import * as React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    Filler,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";

// Import material icon
import Brightness5Icon from '@mui/icons-material/Brightness5';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import WaterIcon from '@mui/icons-material/Water';

// Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Register ChartJS
ChartJS.register(
    CategoryScale,
    Filler,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BoxChartMonthly() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [labels, setLabels] = React.useState([]);
    const [dailyYield, setDailyYield] = React.useState([]);
    const [irradiance, setIrradiance] = React.useState([]);
    const [temperature, setTemperature] = React.useState([]);
    const [humidity, setHumidity] = React.useState([]);

    const [extraData, setExtraData] = React.useState('irradiance');

    const options = {
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true,
        stacked: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Day'
                },
            },
            y: {
                beginAtZero: true,
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'kWh'
                },
                type: 'linear',
            },
            y1: {
                display: extraData === 'irradiance',
                grid: {
                    drawOnChartArea: false,
                },
                position: 'right',
                title: {
                    display: true,
                    text: 'W/m²'
                },
                type: 'linear',
            },
            y2: {
                display: extraData === 'temperature',
                grid: {
                    drawOnChartArea: false,
                },
                position: 'right',
                title: {
                    display: true,
                    text: '°C'
                },
                type: 'linear',
            },
            y3: {
                display: extraData === 'humidity',
                grid: {
                    drawOnChartArea: false,
                },
                position: 'right',
                title: {
                    display: true,
                    text: '%'
                },
                type: 'linear',
            },
        }
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: Text.CampusDevelopment.SolarPhotovoltaicSystems.chartDailyYield[context.language],
                data: dailyYield,
                borderColor: 'rgb(0, 102, 0)',
                backgroundColor: 'rgba(0, 102, 0, 0.5)',
                yAxisID: 'y',
            },
            {
                label: Text.CampusDevelopment.SolarPhotovoltaicSystems.chartIrradiance[context.language],
                data: irradiance,
                borderColor: 'rgb(237, 108, 2)',
                backgroundColor: 'rgba(237, 108, 2, 0.5)',
                hidden: extraData !== 'irradiance',
                yAxisID: 'y1',
            },
            {
                label: Text.CampusDevelopment.SolarPhotovoltaicSystems.chartTemperature[context.language],
                data: temperature,
                borderColor: 'rgb(255, 0, 0)',
                backgroundColor: 'rgba(237, 108, 2, 0.5)',
                hidden: extraData !== 'temperature',
                yAxisID: 'y2',
            },
            {
                label: Text.CampusDevelopment.SolarPhotovoltaicSystems.chartHumidity[context.language],
                data: humidity,
                borderColor: 'rgb(2, 136, 209)',
                backgroundColor: 'rgba(2, 136, 209, 0.5)',
                hidden: extraData !== 'humidity',
                yAxisID: 'y3',
            },
        ],
    };

    const handleExtraData = (event, newData) => {
        if (newData !== null) {
            setExtraData(newData);
        }
    };

    const StyledToggleButton = styled(ToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: '#0288D1'
        }
    });

    // TODO: Define event
    React.useEffect(() => {
        GetChartMonthly();
    }, []);

    // TODO Define function
    function GetChartMonthly() {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetChartMonthly');

            fetch(
                'https://www.pohtyh.edu.hk/MyTYH5/webservices/SolarHarvest.php',
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log('GetChartMonthly:', 'Data received');

                        setLabels(result.labels);
                        setDailyYield(result.data.dailyYield);
                        setIrradiance(result.data.irradiance);
                        setTemperature(result.data.temp);
                        setHumidity(result.data.humidity);
                    } else {
                        console.log('GetChartMonthly:', 'Can not get data');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })();
    }

    return (
        <>
            {
                labels.length > 1 &&
                <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                    <Typography sx={{ textAlign: 'center' }} variant="h6">{Text.CampusDevelopment.SolarPhotovoltaicSystems.chartMonthly[context.language]}</Typography>
                    <Stack>
                        <Typography variant="caption">{Text.CampusDevelopment.SolarPhotovoltaicSystems.choice[context.language]}</Typography>
                        <ToggleButtonGroup
                            exclusive
                            onChange={handleExtraData}
                            size="small"
                            value={extraData}
                        >
                            <StyledToggleButton sx={{ color: '#ED6C02' }} value="irradiance">
                                <Brightness5Icon />
                                {Text.CampusDevelopment.SolarPhotovoltaicSystems.irradiance[context.language]}
                            </StyledToggleButton>
                            <StyledToggleButton sx={{ color: '#FF0000' }} value="temperature">
                                <DeviceThermostatIcon />
                                {Text.CampusDevelopment.SolarPhotovoltaicSystems.temperature[context.language]}
                            </StyledToggleButton>
                            <StyledToggleButton sx={{ color: '#0288D1' }} value="humidity">
                                <WaterIcon />
                                {Text.CampusDevelopment.SolarPhotovoltaicSystems.humidity[context.language]}
                            </StyledToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Box sx={{ height: '50vh', }}>
                        <Line options={options} data={chartData} />
                    </Box>
                </Paper>
            }
        </>
    );
}