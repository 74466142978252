// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import ListText from '../Common/ListText';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth, } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_LT.jpg';

const LearningSupportProgramme = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component LearningSupportProgramme Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="LearningSupportProgramme"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.LearningAndTeacher.LearningSupportProgramme.Caring.Title[context.language]} />
                    <ListText lines={Text.LearningAndTeacher.LearningSupportProgramme.Caring.Content[context.language]} />
                    <ContentTitle text={Text.LearningAndTeacher.LearningSupportProgramme.Measures.Title[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.LearningSupportProgramme.Measures.Content[context.language]} />
                    <ContentTitle text={Text.LearningAndTeacher.LearningSupportProgramme.IntegratedEducation.Title[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.LearningSupportProgramme.IntegratedEducation.Content[context.language]} />
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage >
    );
}
export default LearningSupportProgramme;