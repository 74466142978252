// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import ListText from '../Common/ListText';
import { ContentTitle, } from '../Common/TypographyTYH';

const BoxForm = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxForm Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [group, setGroup] = React.useState(0);
    const [noOfParticipants, setNoOfParticipants] = React.useState(1);
    const [participants, setParticipants] = React.useState([{ Name: '', ClassName: '', ClassNo: 1 }]);
    const participantsRef = React.useRef(participants);
    const [song, setSong] = React.useState('');
    const [instruments, setInstruments] = React.useState('');
    const [modus, setModus] = React.useState(0);
    const [url, setURL] = React.useState('');
    const [attachment, setAttachment] = React.useState(null);
    const attachmentInputRef = React.useRef(null);
    const [studentID, setStudentID] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('error');

    const [openWrongPassword, setOpenWrongPassword] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    // TODO: Define event
    const navigate = useNavigate();

    React.useEffect(() => {
        participantsRef.current = participants;
    }, [participants]);

    React.useEffect(() => {
        setParticipants(previousParticipants => {
            if (noOfParticipants > previousParticipants.length) {
                const additionalElements = Array(noOfParticipants - previousParticipants.length).fill({ Name: '', ClassName: '', ClassNo: 1 });
                return [...previousParticipants, ...additionalElements];
            } else {
                return previousParticipants.slice(0, noOfParticipants);
            }
        });
    }, [noOfParticipants]);

    const handleSubmitClicked = () => {
        if (
            participants.some(item => item.Name.length === 0 || item.ClassName.length === 0) ||
            song.length === 0 ||
            (modus === 0 && instruments.length === 0) ||
            (modus === 1 && (url.length === 0 && !attachment))
        ) {
            setSeverity('error');
            setSnackbarOpen(true);
        } else {
            setLoading(true);
            submitForm();
        }
    };

    const submitForm = () => {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'MusicContest_Submit');
            formData.append('group', Text.MusicContest.GroupList.Options[context.language][group]);
            formData.append('participants', JSON.stringify(participants));
            formData.append('song', song);
            formData.append('instruments', instruments);
            formData.append('url', url);
            formData.append('file', attachment);
            formData.append('studentID', studentID);
            formData.append('password', password);
            fetch(Webservices, { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result) {
                        setSeverity('success');
                        setSnackbarOpen(true);
                        setTimeout(() => navigate('/'), 5000);
                    } else {
                        setLoading(false);
                        setStudentID('');
                        setPassword('');
                        setOpenWrongPassword(true)
                    }
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    };

    // TODO: Return component
    return (
        <Box>
            <Stack spacing={2} sx={{ alignItems: 'center', mt: 2 }}>
                <img
                    alt='leaflet'
                    src="https://www.pohtyh.edu.hk/Files/MusicContest/Leaflets2024F6.jpg"
                    style={{ width: '100%', maxWidth: '600px' }}
                />
                <ListText lines={Text.MusicContest.ListText[context.language]} />
                <Box sx={{ width: 1 }}>
                    <ContentTitle text={`${Text.MusicContest.Form[context.language]}:`} />
                </Box>
                <Box sx={{ display: 'flex', width: 1, gap: 2, }}>
                    <FormControl sx={{ minWidth: '200px' }}>
                        <InputLabel>{Text.MusicContest.GroupList.Title[context.language]}</InputLabel>
                        <Select
                            value={group}
                            label={Text.MusicContest.GroupList.Title[context.language]}
                            onChange={(e) => {
                                if (e.target.value === 0) {
                                    setNoOfParticipants(1);
                                }
                                setGroup(e.target.value);
                            }}
                        >
                            {
                                Text.MusicContest.GroupList.Options[context.language].map((item, i) =>
                                    <MenuItem key={i} value={i}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        disabled={group === 0}
                        inputProps={{ min: 1 }}
                        label={Text.MusicContest.NoOfParticipants[context.language]}
                        onChange={(e) => setNoOfParticipants(e.target.value)}
                        sx={{ flex: 1 }}
                        type="number"
                        value={noOfParticipants}
                    />
                </Box>
                <Box sx={{ width: 1 }}>
                    <Typography variant="body1">{Text.MusicContest.ParticipantsDetail[context.language]}:</Typography>
                </Box>
                {
                    participants.map((participant, i) => (
                        <Box key={i} sx={{ alignItems: 'center', display: 'flex', gap: 2, width: 1 }}>
                            <Typography variant="body1">{i + 1}.</Typography>
                            <TextField
                                error={participant.Name.length === 0}
                                label={Text.Common.StudentName[context.language]}
                                onChange={(e) =>
                                    setParticipants(
                                        participants.map((participant, index) =>
                                            index === i ? { ...participant, Name: e.target.value } : participant
                                        )
                                    )
                                }
                                size="small"
                                value={participant.Name}
                            />
                            <TextField
                                error={participant.ClassName.length === 0}
                                label={Text.Common.Classes[context.language]}
                                onChange={(e) =>
                                    setParticipants(
                                        participants.map((participant, index) =>
                                            index === i ? { ...participant, ClassName: e.target.value } : participant
                                        )
                                    )
                                }
                                size="small"
                                value={participant.ClassName}
                            />
                            <TextField
                                inputProps={{ min: 1, max: 50 }}
                                label={Text.Common.ClassNo[context.language]}
                                onChange={(e) =>
                                    setParticipants(
                                        participants.map((participant, index) =>
                                            index === i ? { ...participant, ClassNo: e.target.value } : participant
                                        )
                                    )
                                }
                                size="small"
                                type="number"
                                value={participant.ClassNo}
                            />
                        </Box>
                    ))
                }
                <Box sx={{ width: 1 }}>
                    <TextField
                        error={song.length === 0}
                        fullWidth
                        label={Text.MusicContest.Song[context.language]}
                        onChange={(e) => setSong(e.target.value)}
                        value={song}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                    <FormControl sx={{ minWidth: '150px' }}>
                        <InputLabel>{Text.MusicContest.Modus.Title[context.language]}</InputLabel>
                        <Select
                            value={modus}
                            label={Text.MusicContest.Modus.Title[context.language]}
                            onChange={(e) => { setModus(e.target.value) }}
                        >
                            {
                                Text.MusicContest.Modus.Options[context.language].map((item, i) =>
                                    <MenuItem key={i} value={i}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        error={modus === 0 && instruments.length === 0}
                        fullWidth
                        label={Text.MusicContest.Instruments[context.language]}
                        onChange={(e) => setInstruments(e.target.value)}
                        sx={{ display: (modus === 0 ? 'block' : 'none') }}
                        value={instruments}
                    />
                    <TextField
                        fullWidth
                        label={Text.MusicContest.URL[context.language]}
                        onChange={(e) => setURL(e.target.value)}
                        placeholder="https://"
                        sx={{ display: (modus === 1 ? 'block' : 'none') }}
                        value={url}
                    />
                    <input
                        ref={attachmentInputRef}
                        onChange={(e) => { setAttachment(e.target.files[0]) }}
                        style={{ display: 'none' }}
                        type="file"
                    />
                    <Button
                        variant="contained"
                        onClick={() => attachmentInputRef.current.click()}
                        sx={{ display: (modus === 1 ? 'block' : 'none'), minWidth: '100px', }}
                    >
                        {Text.Common.UploadFiles[context.language]}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                    <TextField
                        error={studentID.length === 0}
                        fullWidth
                        label={Text.MusicContest.StudentID[context.language]}
                        onChange={(e) => setStudentID(e.target.value)}
                        placeholder="sXXXXXXXX"
                        value={studentID}
                    />
                    <TextField
                        error={password.length === 0}
                        fullWidth
                        label={Text.MusicContest.Password[context.language]}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        value={password}
                    />
                </Box>
                <Button disabled={loading} variant="contained" onClick={handleSubmitClicked}>
                    {Text.Common.Submit[context.language]}
                </Button>
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
                open={snackbarOpen}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={severity}>
                    {
                        severity === 'error' ?
                            Text.MusicContest.ErrorNotice[context.language] :
                            Text.MusicContest.SuccessNotice[context.language]
                    }
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => { setOpenWrongPassword(false) }}
                open={openWrongPassword}
            >
                <Alert severity="error">{`學生帳戶資料不正確`}</Alert>
            </Snackbar>
        </Box>
    );
};
export default BoxForm;