// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_LT.jpg';
import BoxPromotion from './PromotionReading/BoxPromotion';
import BoxLibrarian from './PromotionReading/BoxLibrarian';
import BoxEBook from './PromotionReading/BoxEBook';
import BoxELearning from './PromotionReading/BoxELearning';
import BoxRecommended from './PromotionReading/BoxRecommended';
import BoxGoodBooks from './PromotionReading/BoxGoodBooks';
//import BoxNewBooks from './PromotionReading/BoxNewBooks';
import BoxRanking from './PromotionReading/BoxRanking';
import BoxVideo from './PromotionReading/BoxVideo';
import SpeedDialAnchor from './PromotionReading/SpeedDialAnchor';

const PromotionReading = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component PromotionReading Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const refIntro = React.useRef(null);
    const refLibrarian = React.useRef(null);
    const refEBook = React.useRef(null);
    const refRecommended = React.useRef(null);
    const refRanking = React.useRef(null);

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="PromotionReading"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <Box ref={refIntro}>
                <ContentTitle text={Text.LearningAndTeacher.PromotionReading.Intro.Title[context.language]} />
                <MultipleRows rows={Text.LearningAndTeacher.PromotionReading.Intro.Content[context.language]} />
                <BoxPromotion />
                <ContentTitle text={Text.LearningAndTeacher.PromotionReading.Coordinate.Title[context.language]} />
                <MultipleRows rows={Text.LearningAndTeacher.PromotionReading.Coordinate.Content[context.language]} />
            </Box>
            <Box ref={refEBook}>
                <BoxEBook />
                <BoxELearning />
            </Box>
            <Box ref={refRecommended}>
                <BoxVideo />
                <BoxRecommended />
            </Box>
            <Box ref={refLibrarian}>
                <ContentTitle text={Text.LearningAndTeacher.PromotionReading.Librarian.Title[context.language]} />
                <MultipleRows rows={Text.LearningAndTeacher.PromotionReading.Librarian.Content[context.language]} />
                <BoxLibrarian />
            </Box>
            <Box ref={refRanking}>
                <BoxGoodBooks />
                <BoxRanking />
            </Box>
            <SpeedDialAnchor
                refIntro={refIntro}
                refLibrarian={refLibrarian}
                refEBook={refEBook}
                refRecommended={refRecommended}
                refRanking={refRanking}
            />
        </BoxSubPage>
    );
};
export default PromotionReading;