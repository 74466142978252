// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { AppBar, Box, Paper, Tab, Tabs } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxHighlightedProgrammes from './BoxHighlightedProgrammes';
import BoxUsefulInfo from './BoxUsefulInfo';

const TabInfo = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component TabInfo Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [value, setValue] = React.useState(0);

    // TODO: Define event
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <Paper elevation={3} >
            <AppBar position="static">
                <Tabs
                    centered
                    indicatorColor="secondary"
                    onChange={handleChange}
                    textColor="inherit"
                    value={value}
                >
                    <Tab label={Text.PTA.Tabs.Labels[0][context.language]} />
                    <Tab label={Text.PTA.Tabs.Labels[1][context.language]} />
                </Tabs>
            </AppBar>
            {
                value === 0 &&
                <Box sx={{ p: 2 }}>
                    <BoxHighlightedProgrammes />
                </Box>
            }
            {
                value === 1 &&
                <Box sx={{ p: 2 }}>
                    <BoxUsefulInfo />
                </Box>
            }
        </Paper>
    );
};
export default TabInfo;