// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Box, Typography, Unstable_Grid2 as Grid, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';
import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

const News = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component News Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [news, setNews] = React.useState([]);

    // TODO: Define event
    const navigate = useNavigate();

    React.useEffect(() => {
        const GetNews = () => {
            (async () => {
                const formData = new FormData();
                formData.append('action', 'News_GetNews');

                fetch(Webservices, { method: 'POST', body: formData, })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.length > 0) {
                            setNews(result);
                        }
                    }).catch((error) => { console.error('Error:', error); });
            })();
        }
        GetNews();
    }, []);

    // TODO: Return component
    const RenderStackImage = ({ item }) => (
        <StackImage onClick={() => { navigate(`/Album?ID=${item.ID}`) }} hover={true}>
            <BoxImage
                hover
                src={`https://www.pohtyh.edu.hk/Files/Album/${item.ID}/Slide.jpg`}
            />
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                {item.Title}
            </Typography>
        </StackImage>
    );

    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            title={Text.News.Title[context.language]}
        >
            <Box id="News">
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    {/*<Grid xs={6} md={4}>
                        <StackImage onClick={() => { navigate("/和諧校園政策(諮詢稿)") }} hover={true}>
                            <BoxImage
                                hover
                                src={`https://www.pohtyh.edu.hk/Files/Home/和諧校園政策(諮詢稿).jpg`}
                            />
                            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                                和諧校園政策(諮詢稿)
                            </Typography>
                        </StackImage>
                    </Grid>*/}
                    {
                        news.map((item, i) => (
                            <Grid key={i} xs={6} md={4}>
                                <RenderStackImage item={item} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </BoxSubPage>
    );
}
export default News;