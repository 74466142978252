// TODO: Import React module
import * as React from 'react';
import ReactPlayer from 'react-player/youtube';

// TODO: Import MUI module
import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';

// TODO: Import MUI icon
import { CancelRounded, PlayArrow } from '@mui/icons-material';

// TODO: Import custom component

const DialogVideo = (props) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DialogVideo Rendered');

    // TODO: Define variable
    const [open, setOpen] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);

    // TODO: Define event
    const handleClickOpen = () => {
        setOpen(true);
        setPlaying(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPlaying(false);
    };

    // TODO: Return component
    return (
        <>
            <IconButton
                color="primary"
                onClick={handleClickOpen}
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '56px',
                    height: '56px',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)', },
                }}
            >
                <PlayArrow />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none', }, }}
            >
                <DialogActions sx={{ pb: 0 }}>
                    <IconButton onClick={handleClose} sx={{ p: 0 }}>
                        <CancelRounded />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    overflowY: 'hidden',
                    p: 0,
                    pb: 0,
                }}>
                    <ReactPlayer controls={true} playing={playing} url={props.url} />
                </DialogContent>
            </Dialog>
        </>
    );
}
export default React.memo(DialogVideo);