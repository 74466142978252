// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

import Slide from './Slide_StudentDevelopment.jpg';
import BoxImages from './StudentLeadership/BoxImages';

const StudentLeadership = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component StudentLeadership Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="StudentLeadership"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.StudentLeadership.Purpose.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.StudentLeadership.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.StudentLeadership.Aim.Title[context.language]} />
                    <ListText lines={Text.StudentDevelopment.StudentLeadership.Aim.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.StudentLeadership.Strategy.Title[context.language]} />
                    <ListText lines={Text.StudentDevelopment.StudentLeadership.Strategy.Content[context.language]} />
                    <BoxImages />
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default StudentLeadership;