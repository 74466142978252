// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, } from '@mui/material';

// TODO: Import MUI icon
import { Lightbulb } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';

const AppliedLearning = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component AppliedLearning Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleButtonClick = () => {
        window.open('https://sites.google.com/pohtyh.edu.hk/apl/?authuser=1', '_blank');
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="AppliedLearning"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.LearningAndTeacher.AppliedLearning.IntroTitle[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.AppliedLearning.IntroContent[context.language]} />
                    <ContentTitle text={Text.LearningAndTeacher.AppliedLearning.GainedTitle[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.AppliedLearning.GainedContent[context.language]} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button onClick={handleButtonClick} variant="contained" startIcon={<Lightbulb />}>
                            {Text.LearningAndTeacher.AppliedLearning.KnowMore[context.language]}
                        </Button>
                    </Box>
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default AppliedLearning;