// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography} from '@mui/material';

// TODO: Import MUI icon
import CheckIcon from '@mui/icons-material/Check';

// TODO: Import context
import { Context, fetchData, } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';

const Curriculum = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Curriculum Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [subjects, setSubjects] = React.useState([]);
    const checkedTable = {
        CHI: [1, 2, 3, 4, 5, 6],
        ENG: [1, 2, 3, 4, 5, 6],
        MATH: [1, 2, 3, 4, 5, 6],
        LS: [1, 2, 3],
        CS: [4, 5, 6],
        PTH: [1, 2, 3],
        HE: [1, 2, 3],
        SCJ: [1, 2],
        STEM: [2, 3],
        ICT: [1, 2, 3, 4, 5, 6],
        BIO: [3, 4, 5, 6],
        CHEM: [3, 4, 5, 6],
        PHY: [3, 4, 5, 6],
        CHIST: [1, 2, 3, 4, 5, 6],
        HIST: [1, 2],
        GEO: [1, 2, 3, 4, 5, 6],
        BAFS: [4, 5, 6],
        ECON: [4, 5, 6],
        TH: [4, 5, 6],
        MUS: [1, 2, 3, 4, 5, 6],
        VA: [1, 2, 3, 4, 5, 6],
        PE: [1, 2, 3, 4, 5, 6],
        LIFE: [1, 2, 3],
        RD: [1, 2, 3],
        APL: [5, 6],
        ASSEMBLY: [1, 2, 3, 4, 5, 6],
        CLASS: [1, 2, 3, 4, 5, 6],
        CCAC: [1, 2, 3, 4, 5, 6],
        LP: [6],
    };

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'LT_Curriculum_GetSubjects');
        fetchData(formData, (result) => { setSubjects(result) });
    }, []);

    // TODO: Return component
    return (
        <BoxSubPage
            id="Curriculum"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <Paper sx={{mb:2}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                    {Text.LearningAndTeacher.Curriculum.Subject[context.language]}
                                </TableCell>
                                {
                                    Object.keys(Text.Common.ClassLevel).map((form) => (
                                        <TableCell
                                            align="center"
                                            key={form}
                                            sx={{ backgroundColor: 'primary.main', color: 'white' }}
                                        >
                                            {Text.Common.ClassLevel[form][context.language]}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                subjects.map((subject) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={subject.ID}>
                                        <TableCell>
                                            <Typography variant="body1">
                                                {context.language === 'CHI' ? subject.CFullName : subject.EFullName}
                                            </Typography>
                                        </TableCell>
                                        {
                                            Object.keys(Text.Common.ClassLevel).map((form) => (
                                                <TableCell key={form}>
                                                    {
                                                        checkedTable[subject.ID] && checkedTable[subject.ID].includes(parseInt(form)) &&
                                                        <CheckIcon color="primary" />
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </BoxSubPage>
    );
}
export default Curriculum;