// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import ListText from '../Common/ListText';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_Achievements.jpg';


const DSEResult2024 = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DSEResult2024 Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            disableMenu={true}
            id="DSEResult2024"
            image={Slide}
            title={Text.Achievements.DSEResult2024.Title[context.language]}
        >
            <ContentTitle text="最佳五科成績（首三名）" />
            <MultipleRows
                rows={[
                    '羅子陽：化學5** 物理5*  數學5*  數延5  英文4',
                    '吳錦樞：化學5*  物理5*  中文5*  數學5  英文4',
                    '王皓文：旅款5** 地理5*  經濟5   數學4  中文4'
                ]}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{mt:2}} text="考獲5**、5*或5優異成績的科目包括" />
            <MultipleRows
                rows={['中、英、數、數延、物理、化學、地理、經濟、旅款']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="考獲5**成績高於全港水平的科目包括" />
            <MultipleRows
                rows={['化學、旅款']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="考獲5*成績高於全港水平的科目包括" />
            <MultipleRows
                rows={['物理、化學、地理、旅款']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="考獲4級至5**成績的科目包括" />
            <MultipleRows
                rows={['中、英、數、數延、物理、化學、生物、經濟、企概、資通、中史、地理、旅款、視藝']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="考獲4級或以上成績高於全港水平的科目包括" />
            <MultipleRows
                rows={['經濟、企概、地理、旅款、視藝']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="考獲3級或以上成績高於全港水平的科目包括" />
            <MultipleRows
                rows={['化學、生物、企概、經濟、地理、視藝、體育']}
                sx={{ mb: 0 }}
            />
            <ContentTitle sx={{ mt: 2 }} text="合格率與全港比較" />
            <ListText lines={[
                '13科考獲達標/2級或以上成績高於全港水平，其中11科達90%或以上',
                '5科合格率達100％，包括：化學、經濟、企概、數延、體育',
                '乙類科目（應用學習，共18科）100%達標，表現優異（I）及表現優異（II）均高於全港水平',
            ]} />
        </BoxSubPage>
    );
}
export default DSEResult2024;