// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_Activities.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';

const LifeWideLearning = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component LifeWideLearning Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/Activities/LifeWideLearning/F1.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/LifeWideLearning/F2.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/LifeWideLearning/F3.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/LifeWideLearning/F4.jpg',
        'https://www.pohtyh.edu.hk/Files/Activities/LifeWideLearning/F5.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="LifeWideLearning"
            image={Slide}
            title={Text.Top.Menu['ACT'].Title[context.language]}
            subtitles={Text.Top.Menu['ACT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <Typography
                        dangerouslySetInnerHTML={{ __html: Text.Activities.LifeWideLearning.Intro[context.language] }}
                        sx={{ color: 'black' }}
                        variant="body1"
                    />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
}
export default LifeWideLearning;