// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

import Slide from './Slide_SchoolProfile.jpg';

const MissionVision = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MissionVision Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <Box id="MissionVision" sx={{ mb: 2 }}>
                <ContentTitle text={Text.SchoolProfile.MissionVision.MissionTitle[context.language]} />
                <MultipleRows rows={Text.SchoolProfile.MissionVision.MissionContent[context.language]} />
                <ContentTitle text={Text.SchoolProfile.MissionVision.VisionTitle[context.language]} />
                <MultipleRows rows={Text.SchoolProfile.MissionVision.VisionContent[context.language]} />
            </Box>
        </BoxSubPage>
    );
};
export default MissionVision;