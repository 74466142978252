// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    AppBar, Dialog, Unstable_Grid2 as Grid, IconButton,
    ListItemAvatar, ListItemButton, ListItemText, Toolbar, Typography,
} from '@mui/material';

// TODO: Import MUI icon
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_SchoolProfile.jpg';

import BoxSubPage from '../Common/BoxSubPage';

const SchoolPlansReports = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SchoolPlansReports Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [url, setURL] = React.useState('');

    // TODO: Define event
    const handleClick = (title, url) => {
        setTitle(title);
        setURL(url);
        setOpen(true);
    };

    const handleClose = () => { setOpen(false); };

    React.useEffect(() => { console.log(url) }, [url]);

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <Grid container spacing={2}>
                {
                    Text.SchoolProfile.SchoolPlanReports.map((item, i) => (
                        <Grid key={i} xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItemButton onClick={() => { handleClick(item[context.language], item.URL) }}>
                                <ListItemAvatar>
                                    <ArticleIcon sx={{ color: 'primary.main' }} />
                                </ListItemAvatar>
                                <ListItemText primary={item[context.language]} />
                            </ListItemButton>
                        </Grid>
                    ))
                }
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { height: '100%' } }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography color="white" sx={{ ml: 2, flex: 1 }} variant="h6">{title}</Typography>
                        <IconButton edge="end" onClick={handleClose} sx={{ color: 'white' }}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <iframe src={url} style={{ height: '100%', width: '100%', }} title={title} />
            </Dialog>
        </BoxSubPage>
    );
};
export default SchoolPlansReports;