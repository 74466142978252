// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

// TODO: Import MUI icon
import { Panorama } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';
import DialogActivity from './STEAMEducation/DialogActivity';

const STEAMEducation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component STEAMEducation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img01.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img02.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img03.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img04.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img05.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img06.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img07.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Img08.jpg',
    ];

    const activities = [
        {
            path: '5G校園應用創作比賽',
            files: ['01.jpg', '02.jpg', '03.jpg'],
            title: { CHI: '5G校園應用創作比賽' },
            content: { CHI: '本校5位高中修讀ICT的同學(陳進宇、甘合宗、曾冠瀤、江卓恆、李晃希)於2024年參與由香港特區政府通訊辦主辦的5G校園應用創作比賽，以人工智能、網站伺服器端技術及Raspberry Pi微電腦統統製作「聲識鳥語AI學習系統」，榮獲比賽的季軍，詳情可見: <a href="https://www.ofca.gov.hk/5gcompetition/tc/competition_result/index.html" target="_blank">https://www.ofca.gov.hk/5gcompetition/tc/competition_result/index.html</a>' },
        },
        {
            path: 'STEMInPEAndPlay設計比賽',
            files: ['01.jpg', '02.jpg', '03.jpg', '04.jpg'],
            title: { CHI: 'STEM in PE and Play設計比賽' },
            content: { CHI: '<p>本校的STEAM隊伍於2023及2024連續兩屆均有參與由鵬程慈善基金及IVE舉之STEM in PE and Play設計比賽，利用STEM的元素設計體感遊戲，一方面提升運動的訓練成效，另一方面也鼓勵更多同學積極參與體藝活動，建立健康的生活方式。</p><p>在2023第一屆的比賽中，中五溫智衡、王泰中、周浩軒、中二翁太懋憑作品「極限球手」獲得決賽季軍及我最喜愛大獎季軍；在2024第二屆比賽中，中三翁太懋、列俊希、張森蕙、溫梓健及中五楊家榆憑作品「舞動新世紀」獲得決賽的亞軍及我最喜愛大獎亞軍。</p>' },
        },
        {
            path: '社區STEAM活動',
            files: ['01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg'],
            title: { CHI: '社區STEAM活動' },
            content: { CHI: '本校同學以其STEAM體感遊戲作品，到區內小學及商場設置攤位，與眾同樂。' },
        },
        {
            path: '教育大學SteamLympics',
            files: ['01.jpg', '02.jpg', '03.jpg'],
            title: { CHI: 'STEAMLympics' },
            content: { CHI: '本校的STEAM隊伍(中四翁太懋、列俊希、張森蕙、溫梓健及施君臨)於2024年以MQTT的IoT技術及ESP8288微電腦改良「極限球手」作品，並參與由香港教育大學舉辦的第一屆STEAMLympics，榮獲決賽的優異獎。' },
        },
        {
            path: '創新科技嘉年華',
            files: ['01.jpg'],
            title: { CHI: '創新科技嘉年華' },
            content: { CHI: '每年中三級同學均會到科學園舉行的創新科技嘉年華參觀，學習資訊科技的新發明及社會上的應用。' },
        },
    ];

    const [dialogItem, setDialogItem] = React.useState(null);

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="SocialServicesCommittee"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.LearningAndTeacher.STEAMEducation.Purpose.Title[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.STEAMEducation.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.LearningAndTeacher.STEAMEducation.Vision.Title[context.language]} />
                    <MultipleRows rows={Text.LearningAndTeacher.STEAMEducation.Vision.Content[context.language]} />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.LearningAndTeacher.STEAMEducation.Highlights.Title[context.language]} />
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                {
                    activities.map(activity => (
                        <Grid xs={6} key={activity.path}>
                            <StackImage onClick={() => { setDialogItem(activity) }} hover={true}>
                                <BoxImage src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/STEAMEducation/Highlights/${activity.path}/01.jpg`} />
                                <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                                    <Panorama sx={{ color: 'white' }} />
                                    {activity.title[context.language]}
                                </Typography>
                            </StackImage>
                        </Grid>
                    ))
                }
            </Grid>
            <DialogActivity currentItem={dialogItem} onClose={setDialogItem} />
        </BoxSubPage>
    );
}
export default STEAMEducation;