// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';

import Slide from './Slide_SchoolProfile.jpg';
import SchoolSong from './SchoolSong.png';

import BoxSubPage from '../Common/BoxSubPage';

const MottoSchoolSong = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MottoSchoolSong Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <Box id="MottoSchoolSong">
                {
                    Text.SchoolProfile.MottoSchoolSong.Motto.map((item, i) => (
                        <Box key={i}>
                            <Typography
                                component="span"
                                sx={{ color: 'primary.main', fontSize: { xs: '1.5rem', md: '3rem' } }}
                            >
                                {item.Char}
                            </Typography>
                            <Typography
                                component="span"
                                sx={{ color: 'black', fontSize: { xs: '1rem', md: '1.5rem' }, ml: 2 }}
                            >
                                {item.Description}
                            </Typography>
                        </Box>
                    ))
                }
                <Divider />
                <BoxImage
                    src={SchoolSong}
                    sx={{ aspectRatio: 'auto', mt: 2, width: 1 }}
                    sxSkeleton={{ aspectRatio: 'auto', mt: 2, height:'890px',width: 1 }}
                />
            </Box>
        </BoxSubPage>
    );
};
export default MottoSchoolSong;