// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { ExpandMore, PictureAsPdf } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { Context, } from '../Common/Context';
import { Text } from '../Common/Text';

import BoxSubPage from '../Common/BoxSubPage';
import { MultipleRows } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

const Recruit = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Recruit Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            id="Recruit"
            title={Text.Recruit.Title[context.language]}
        >
            <Typography sx={{ mb: 2 }} variant="body1">{Text.Recruit.Content[context.language]}</Typography>
            {
                Text.Recruit.Items[context.language].map((item, i) => (
                    <Accordion expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>{item.Title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListText lines={item.Content} />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
            <Box sx={{ display: 'flex' }}>
                <PictureAsPdf />
                <Link href="https://www.pohtyh.edu.hk/Files/Recruit/ApplicationForm.pdf" rel="noopener" target="_blank">
                    {Text.Recruit.PDF[context.language]}
                </Link>
            </Box>
            <MultipleRows rows={Text.Recruit.Footer[context.language]} sx={{ mb: 0, mt: 2, }} />

        </BoxSubPage>
    );
}
export default Recruit;