// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context, } from '../Common/Context';
import { Text, } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';

const LanTingJu = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component LanTingJu Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            id="CafeGeographie"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_LanTingJu.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.Intro[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/IMG_5934.jpg"
                        sx={{ width: 1, mb: 2, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.Section[context.language]} />
                    <ContentTitle text={Text.CampusDevelopment.LanTingJu.Rest.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.Rest.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/R6M27286.jpg"
                        sx={{ width: 1, mb: 2, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <ContentTitle text={Text.CampusDevelopment.LanTingJu.SelfLearning.Title[context.language]} />
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.SelfLearning.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/IMG_5902.jpg"
                        sx={{ width: 1, mb: 2, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.Other[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/20241005_150241.jpg"
                        sx={{ width: 1, mb: 2, }}
                        sxSkeleton={{ width: 1 }}
                    />
                    <MultipleRows rows={Text.CampusDevelopment.LanTingJu.Naming[context.language]} />
                </BoxGridColumnsFullWidth>
                <BoxImage src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/IMG_5906.jpg" />
                <BoxImage src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/R6M27215.jpg" />
                <BoxImage src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/R6M27273.jpg" />
                <BoxImage src="https://www.pohtyh.edu.hk/Files/CampusDevelopment/LanTingJu/IMG_5910.jpg" />
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};

export default LanTingJu;