// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
//import BoxImage from '../Common/BoxImage';
import BoxSubPage from '../Common/BoxSubPage';
import { /*ContentTitle,*/ MultipleRows } from '../Common/TypographyTYH';
import TabInfo from './TabInfo';

import Slide from './Slide.jpg';

//import Image from './Image.jpg';

const PTA = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component PTA Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="PTA"
            image={Slide}
            disableMenu={true}
            disableSubtitle={true}
            title={Text.PTA.Title[context.language]}
        >
            <MultipleRows rows={Text.PTA.Intro[context.language]} />
            <Typography sx={{ color: 'black', mt: 2 }} variant="body1">
                {Text.PTA.CommitteeTitle[context.language]}
            </Typography>
            <Typography variant="body1">
                第二十三屆家長教師會執行委員會委員選舉已於2024年11月4日順利完成，衷心感謝參選家長的熱心參與及各家長的踴躍投票。在校長、李副校長以及一位參選家長的見證下，順利選出執行委員會委員，八名參選家長順利當選為第二十三屆執行委員會委員，名單如下：（排名不分先後）
            </Typography>
            <ul>
                <li>楊崎珍</li>
                <li>盧穎詩</li>
                <li>阮嘉欣</li>
                <li>李繼兒</li>
                <li>李穎佳</li>
                <li>盧子健</li>
                <li>陳國帆</li>
                <li>林佩瑩</li>
            </ul>
            <Typography variant="body1">
                如各家長對是次執行委員會委員選舉結果有任何查詢或異議，可在選舉結果公佈後的一星期內(即11月12日下午5時正前)向學校提出。
            </Typography>
            {/*<Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Chairman[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">李頴佳先生</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.ViceChairman[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">吳玉蘭女士、蔡睿溱老師</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Secretary[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">李惠蓮副校長、羅凱玲老師</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Treasurer[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">阮嘉欣女士、黃雅淳老師</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Contact[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" sx={{ display: { xs: 'none', md: 'block' } }}>
                                        盧頴詩女士、盧子健先生、楊崎珍女士、潘錦偉副校長、陳冠全副校長
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: { xs: 'block', md: 'none' } }}>
                                        盧頴詩女士、盧子健先生、楊崎珍女士、{<br />}潘錦偉副校長、陳冠全副校長
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Recreation[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">李繼兒女士、李國威助理校長</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                    <Typography variant="body1">
                                        {Text.PTA.Role.Publishing[context.language]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">李鳳嬋女士、 張俊晗老師</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>*/}
            <Divider sx={{ my: 2 }} />
            <TabInfo />
            {/*<Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                <BoxImage src={Image} sx={{ width: 1, maxWidth: '640px' }} sxSkeleton={{ maxWidth: '640px', width: 1 }} />
            </Box>
            <ContentTitle text={Text.PTA.ChairmanSpeechTitle[context.language]} />
            <Typography
                dangerouslySetInnerHTML={{ __html: Text.PTA.ChairmanSpeechContent }}
                sx={{ color: 'black' }}
                variant="body1"
            />
            <Divider sx={{ my: 2 }} />
            <Typography sx={{ color: 'primary.main' }} variant="body1">{Text.PTA.Footer[context.language]}</Typography>
            <Typography sx={{ color: 'black' }} variant="body1">{Text.PTA.ContactPhone[context.language]}</Typography>
            <Typography sx={{ color: 'black' }} variant="body1">{Text.PTA.ContactEmail[context.language]}</Typography>*/}
        </BoxSubPage>
    );
};
export default PTA;