// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

// TODO: Import MUI icon

// TODO: Import custom component

export default function BoxTech() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            id="YiYuan_BoxTech"
            sx={{
                backgroundColor: grey['200'],
                py: 2,
            }}
        >
            <Grid
                columns={{ xs: 6, md: 12 }}
                container
                direction={{ xs: 'column-reverse', md: 'row' }}
            >
                <Grid
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Box sx={{ m: 2 }}>
                        <Typography
                            sx={{
                                color: 'success.dark',
                                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2.5rem' },
                                fontWeight: 'bold',
                            }}
                            variant="h4"
                        >
                            科技應用
                        </Typography>
                        <Typography variant="body1">
                            隨著科技發展及流動應用普及，園藝種植也邁向智能化，利用資訊科技元素了解植物的背景，以及成長條件等資訊，同時還有些工具輔助培育植物生長。
                        </Typography>
                        <Typography variant="body2">　</Typography>
                        <Typography
                            sx={{
                                color: 'success.dark',
                                fontSize: { xs: '1rem', sm: '1.2rem', md: '2.0rem' },
                                fontWeight: 'bold',
                            }}
                            variant="h4"
                        >
                            流動應用程式介紹
                        </Typography>
                        <Typography variant="body1">
                            形色 - 這個流動應用程式透過拍攝植物，幫助用家分辨植物種類，而且提供這些植物的種植資訊。
                            <Link
                                href="http://www.xingse.net/"
                                target="_blank"
                                variant="body2"
                            >
                                前往下載
                            </Link>
                        </Typography>
                        <Typography variant="body2">　</Typography>
                        <Typography variant="body1">
                            Lux Light Meter - 植物的生長極需要光，如果光照不足，很可能導致植物停止生長，或者其他不良影響。這個流動應用程式可以讓用家量度日光度，輔助植物生長。
                            <Link
                                href="http://doggoapps.com/"
                                target="_blank"
                                variant="body2"
                            >
                                前往下載
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Box
                        component="img"
                        sx={{
                            backgroundImage: 'url("https://www.pohtyh.edu.hk/YiYuan/images/tech.jpg")',
                            backgroundPosition: { xs: 'bottom', md: 'center' },
                            backgroundSize: 'cover',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: { xs: 200, md: 350 },
                            width: '100%',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}