// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import BoxImage from '../Common/BoxImage';
import { ContentTitle, } from '../Common/TypographyTYH';

import DialogCoverage from './DialogCoverage';
import ListText from '../Common/ListText';
import BoxItems from './BoxItems';
import TableNameList from './TableNameList';

const Alumni = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Alumni Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [currentItem, setCurrentItem] = React.useState(null);

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <BoxSubPage
            id="Alumni"
            image={Slide}
            disableMenu={true}
            disableSubtitle={true}
            title={Text.Alumni.Title[context.language]}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                <BoxImage
                    src="https://www.pohtyh.edu.hk/Files/Alumni/Logo.jpg"
                    sx={{ aspectRatio: 'auto', width: 'auto' }}
                    sxSkeleton={{width:'389px',height:'97px'}}
                />
            </Box>
            <BoxItems onClick={setCurrentItem} />
            <DialogCoverage currentItem={currentItem} onClose={setCurrentItem} />
            <ContentTitle text={Text.Alumni.AboutUs.Title[context.language]} />
            <ListText lines={Text.Alumni.AboutUs.Content[context.language]} />
            <ContentTitle text={Text.Alumni.RoleTitle[context.language]} />
            <TableNameList />
            <ContentTitle text={Text.Alumni.ContactTitle[context.language]} />
            <Typography sx={{ color: 'black' }} variant="body1">
                {Text.Alumni.ContactContent[context.language]}
            </Typography>
            <a href="mailto:alumni@pohtyh.edu.hk">alumni@pohtyh.edu.hk</a>
        </BoxSubPage>
    );
};
export default Alumni;