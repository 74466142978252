// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { lightGreen } from '@mui/material/colors';

// TODO: Import MUI icon;

// TODO: Import custom component
import DialogMore from './DialogMore.js';

export default function BoxIntro() {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box id="YiYuan_BoxIntro">
            <Grid container>
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Box
                        component="img"
                        src="https://www.pohtyh.edu.hk/YiYuan/images/board.jpg"
                        sx={{
                            height: 1,
                            objectFit: 'cover',
                            width: 1,
                        }}
                    />
                </Grid>
                <Grid
                    sx={{
                        alignItems: 'center',
                        backgroundColor: lightGreen['50'],
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    xs={6}
                >
                    <Container>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1rem', lg: '2rem' },
                                textAlign: 'center',
                            }}
                            variant="h3"
                        >
                            一草一木，盤中粒粒，
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1rem', lg: '2rem' },
                                textAlign: 'center',
                            }}
                            variant="h3"
                        >
                            皆上天之貽贈，
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1rem', lg: '2rem' },
                                textAlign: 'center',
                            }}
                            variant="h3"
                        >
                            願汝惜之愛之，
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1rem',  lg: '2rem' },
                                textAlign: 'center',
                            }}
                            variant="h3"
                        >
                            貽厥後來。
                        </Typography>
                        <Typography
                            component="p"
                            sx={{
                                mt: 1,
                                textAlign: 'center',
                            }}
                        >
                            <DialogMore />
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
}