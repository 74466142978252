// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';

import Slide from './Slide_StudentDevelopment.jpg';

const IE = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component IE Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/IE/Img03.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/IE/Img04.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/IE/Img05.jpg',
        'https://www.pohtyh.edu.hk/Files/StudentDevelopment/IE/Img06.jpg',
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="IE"
            image={Slide}
            title={Text.Top.Menu['SD'].Title[context.language]}
            subtitles={Text.Top.Menu['SD'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.StudentDevelopment.IE.Purpose.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.IE.Purpose.Content[context.language]} />
                    <ContentTitle text={Text.StudentDevelopment.IE.Philosophy.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.IE.Philosophy.Content[context.language]} />
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/StudentDevelopment/IE/Img01.jpg"
                        sx={{ objectFit: 'cover', width: 1, }}
                        sxSkeleton={{ width: 1, }}
                    />
                    <ContentTitle text={Text.StudentDevelopment.IE.Process.Title[context.language]} />
                    <Stepper orientation="vertical">
                        {
                            Text.StudentDevelopment.IE.Process.Content.map((step,i) => (
                                <Step key={i} active={true}>
                                    <StepLabel sx={{
                                        textDecoration: 'underline',
                                        '& .MuiStepIcon-text': { fill: 'white' },
                                    }}>
                                        <Typography variant="body1">{step.Year}</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.Content[context.language]}</Typography>
                                    </StepContent>
                                </Step>
                            ))
                        }
                    </Stepper>
                    <ContentTitle text={Text.StudentDevelopment.IE.LetMeShine.Title[context.language]} />
                    <MultipleRows rows={Text.StudentDevelopment.IE.LetMeShine.Content[context.language]} />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (
                        <BoxImage key={i} src={image} />
                    ))
                }
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default IE;