// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { lightGreen } from '@mui/material/colors';

// TODO: Import MUI icon

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../Common/Context';

export default function BoxActivities() {
    // TODO: Define useContext

    // TODO: Define variable
    const [images, setImages] = React.useState([]);

    // TODO: Define event
    React.useEffect(GetActivitiies, []);

    const handleClickOpen = (url) => {
        window.open(url, '_blank');
    };

    // TODO: Define function
    function GetActivitiies() {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetActivitiies');

            fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        setImages(result);
                    }
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    }

    // TODO: Return component
    return (
        <Box
            id="YiYuan_BoxActivities"
            sx={{
                backgroundColor: lightGreen['50'],
                py: 2,
            }}
        >
            <Typography
                sx={{
                    color: 'success.dark',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
                variant="h4"
            >
                活動剪影
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{ m: 2 }}
            >
                {images.map((image, i) => (
                    <Grid xs={6} lg={4} key={i}>
                        <Box
                            onClick={() => handleClickOpen(image.URL)}
                            sx={{
                                backgroundImage: (`url(https://www.pohtyh.edu.hk/YiYuan/images/activities/${image.Image})`),
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'flex',
                                alignItems: 'end',
                                justifyContent: 'center',
                                height: ({ xs: 150, md: 200 }),
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    p: 1,
                                    width: '100%',
                                }}
                            >
                                <Typography color="white">
                                    {image.Title}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
