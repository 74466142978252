// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, } from '@mui/material';

// TODO: Import MUI icon
import { Lightbulb } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_LT.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';

const EnglishEducation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component EnglishEducation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const images = [
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20221125131841.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20230217145910.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20230302082115.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20230316082032.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20231101132319.jpg',
        'https://www.pohtyh.edu.hk/Files/LearningAndTeacher/EnglishEducation/Img20231101132331.jpg',
    ];

    // TODO: Define event
    const handleButtonClick = () => {
        window.open('https://www.pohtyh.edu.hk/subjectWeb/eng/', '_blank');
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="EnglishEducation"
            image={Slide}
            title={Text.Top.Menu['LT'].Title[context.language]}
            subtitles={Text.Top.Menu['LT'].Subtitles}
        >
            <BoxGridColumnsContainer>
                <BoxGridColumnsFullWidth>
                    <ContentTitle text={Text.LearningAndTeacher.EnglishEducation.Title} />
                    <MultipleRows rows={Text.LearningAndTeacher.EnglishEducation.Content} />
                </BoxGridColumnsFullWidth>
                {
                    images.map((image, i) => (<BoxImage key={i} src={image} />))
                }
                <BoxGridColumnsFullWidth>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button onClick={handleButtonClick} variant="contained" startIcon={<Lightbulb />}>
                            {Text.LearningAndTeacher.EnglishEducation.KnowMore}
                        </Button>
                    </Box>
                </BoxGridColumnsFullWidth>
            </BoxGridColumnsContainer>
        </BoxSubPage>
    );
};
export default EnglishEducation;