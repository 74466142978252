// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component
import { Context, fetchData } from '../../Common/Context';
import { Text } from '../../Common/Text';

const TableData = ({ selectedYear }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component TableData Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);

    // TODO: Define event

    React.useEffect(() => {
        if (selectedYear) {
            setLoading(true);
            const formData = new FormData();
            formData.append('action', 'ACH_AwardsExternal_GetData');
            formData.append('year', selectedYear);
            fetchData(
                formData,
                (result) => {
                    setData(result);
                    setLoading(false);
                }
            )
        }
    }, [selectedYear]);

    // TODO: Return component
    const RenderDomain = ({ domain }) => (
        <Box sx={{ my: 2 }}>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={3} sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">{data[domain][0].CName}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    {Text.Achievements.AwardsExternal.Organizer[context.language]}
                                </TableCell>
                                <TableCell align="center">
                                    {Text.Achievements.AwardsExternal.Items[context.language]}
                                </TableCell>
                                {/*<TableCell align="center" sx={{ minWidth: '120px' }}>
                                    {Text.Achievements.AwardsExternal.AwardDate[context.language]}
                                </TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data[domain].map((row, i) => (<RenderTableRow key={i} row={row} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );

    const RenderTableRow = ({ row }) => (
        <TableRow hover>
            <TableCell>
                <Typography dangerouslySetInnerHTML={{ __html: row.Organizer }} variant="body1" />
            </TableCell>
            <TableCell>
                <Typography dangerouslySetInnerHTML={{ __html: row.Item }} variant="body1" />
            </TableCell>
            {/*<TableCell>
                <Typography dangerouslySetInnerHTML={{ __html: row.DAward }} variant="body1" />
            </TableCell>*/}
        </TableRow>
    )

    return (
        <>
            {loading && <Skeleton variant="rectangular" sx={{ width: 1, height: '200px', }} />}
            {Object.keys(data).map((domain) => (<RenderDomain key={domain} domain={domain} />))}
        </>
    );
};

export default TableData;