// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const TableNameList = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component TableNameList Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white', minWidth: '115px' }}>
                                <Typography variant="body1">
                                    {Text.Alumni.Role.Chairman[context.language]}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">庾鎧榮</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                <Typography variant="body1">
                                    {Text.Alumni.Role.ViceChairman[context.language]}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">鄧兆康</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                <Typography variant="body1">
                                    {Text.Alumni.Role.Secretary[context.language]}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">鍾善妮、施麗莎、庾灝倫、朱俊軒、賴曉藍、王淇</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
export default React.memo(TableNameList);