// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Unstable_Grid2 as Grid, Skeleton, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context, fetchData } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';
import StackImage from '../../Common/StackImage';

const BoxGoodBooks = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxGoodBooks Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const books = [
        'GB_大使講中國故事.jpg', 'GB_如果歷史是一群貓(3)：秦楚兩漢篇.jpg', 'GB_我們三代人.jpg', 'GB_看漫畫，學物理.jpg',
        'GB_發現三星堆.jpg',
    ];

    //const [loading, setLoading] = React.useState(false);
    //const [books, setBooks] = React.useState([]);

    // TODO: Define event
    /*React.useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('action', 'LT_PromotionReading_GetGoodBooks');
        fetchData(
            formData,
            (result) => {
                setBooks(result);
                setLoading(false);
            }
        );
    }, []);*/

    // TODO: Return component
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.GoodBooks.Title[context.language]}
            </Typography>
            {/*
                loading &&
                <Grid container spacing={2}>
                    <Grid xs={6} spacing={2}>
                        <Skeleton variant="rounded" height={100} />
                    </Grid>
                    <Grid xs={6} spacing={2}>
                        <Skeleton variant="rounded" height={100} />
                    </Grid>
                </Grid>
            */}
            {
                <Grid container spacing={2}>
                    {
                        books.map((item) => (
                            <Grid key={item} xs={6} md={4}>
                                <StackImage>
                                    <BoxImage
                                        //src={`https://www.pohtyh.edu.hk/library/images/goodbook/${item.rID}.jpg`}
                                        src={`https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/${item}`}
                                        sx={{ aspectRatio: 'auto', objectFit: 'contain', width: 1 }}
                                        sxSkeleton={{ width: 1 }}
                                    />
                                    <Typography sx={{ color: 'white', mt: 1, }} variant="body1">
                                        {item.replace('GB_', '').replace('.jpg', '')}
                                    </Typography>
                                </StackImage>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Box>
    );
};
export default BoxGoodBooks;