// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;

// TODO: Import custom component

export default function DialogMore() {
    // TODO: Define useContext

    // TODO: Define variable
    const [open, setOpen] = React.useState(false);

    // TODO: Define event
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            <Button
                color="success"
                onClick={handleClickOpen}
                variant="contained"
            >
                了解更多
            </Button>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        color: 'success.dark',
                        fontWeight: 'bold',
                    }}
                >介紹</DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 2 }}>同學們，有留意到剛入學校大門旁邊的一個花圃，已悄悄地換上新裝嗎？它就是我們的貽園，它不是一個普通的花圃，而是有著各式各樣的植物，每一個品種皆有它自身的價值，例如瓜果可供給我們食用，香菜可供給我們作調味料，有些植物更有藥用價值。</Typography>
                    <Typography sx={{ mb: 2 }}>起初，這裡只是學校裡平平無奇的角落，栽種了幾棵大樹而已。大約2019年上旬，幾位不同部門的教職員與校長一起閒談時，討論到這一塊草地，希望重新規劃成一個小花圃。6月時，這幾位教職員便制定了一些方案，在得到校長的同意及資源上的支持下，終於落實整理這一塊田地，成為今日的貽園。</Typography>
                    <Typography sx={{ mb: 2 }}>現在這個花圃不但是學校的一個校園美景，對社區都是一個聯繫的地方。學校附近的街坊都非常關注貽園的近況，有時會與我們閒談，交流種植心得，當到了收成的時候，更會與他們分享成果。</Typography>
                    <Typography sx={{ mb: 2 }}>近年學校為學生舉辦不同多元智能課程，鼓勵學生積極參與。我們期待貽園可以持續發展，作為校園地標之餘，都讓學生有多一個課外學習的機會，成為特色校園的其中一個項目。</Typography>
                    <Typography sx={{ mb: 2 }}>打理貽園並不是只有體力勞動的工作，當中有很多知識是需要學習，每一種植物皆有不同的照顧方法，例如澆水量、日照的時間，或生長季節亦各有不同。照料植物的人都需靠著自身的經驗，以及閱讀繼續學習種植知識，才有今天的美景。在此我們感謝幾位工友、校務處同工和教學助理，無論天氣好與壞，他們都親力親為，悉心打理貽園這個地方，務求可以提供一個校園美景讓校內及校外的人觀賞，又讓同學多一個興趣學習的地方。</Typography>
                    <Typography sx={{ mb: 2 }}>我們也誠邀一些對園藝有興趣的同學，攜手打理貽園，一起發展這個園圃，共享種植的成果。聯絡負責職員，可電郵至leyland@pohtyh.edu.hk或到校務處查詢。</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="success" onClick={handleClose} variant="contained">關閉</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}