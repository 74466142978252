// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Stack, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../../Common/Context';
import { Text } from '../../Common/Text';

// TODO: Import custom component
import BoxImage from '../../Common/BoxImage';

const BoxEBook = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxEBook Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleHyreadClicked = () => { window.open('https://pohtyh.hyread.hk/', '_blank') };
    const handleHKEDCityClicked = () => { window.open('https://www.hkreadingcity.net/zh-hant', '_blank') };
    const handleHKExamClicked = () => { window.open('https://mmis.hkpl.gov.hk/zh/hkcee-and-hkale-papers-collection', '_blank') };
    const handleLibraryCEOClicked = () => { window.open('http://lib.pohtyh.edu.hk/pohtyh/wlib.php?u=LIB_opac2.php', '_blank') };

    // TODO: Return component
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', mb: 1, }} variant="h6">
                {Text.LearningAndTeacher.PromotionReading.EBook.Title[context.language]}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, mb: 2, }}>
                <Stack onClick={handleLibraryCEOClicked} sx={{ alignItems: 'center', cursor: 'pointer', }}>
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/LibraryCEO.png"
                        sx={{ aspectRatio: 'auto', height: '80px', objectFit: 'contain', width: 'auto' }}
                        sxSkeleton={{ height: '80px', width: '80px', }}
                    />
                    <Typography sx={{ textAlign: 'center' }} variant="body1">學校圖書館目錄</Typography>
                </Stack>
                <Stack onClick={handleHyreadClicked} sx={{ alignItems: 'center', cursor: 'pointer', }}>
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/Hyread.png"
                        sx={{ aspectRatio: 'auto', height: '80px', objectFit: 'contain', width: 'auto' }}
                        sxSkeleton={{ height: '80px', width: '80px', }}
                    />
                    <Typography sx={{ textAlign: 'center' }} variant="body1">Hyread</Typography>
                </Stack>
                <Stack onClick={handleHKEDCityClicked} sx={{ alignItems: 'center', cursor: 'pointer', }}>
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/HKEDCity.png"
                        sx={{ aspectRatio: 'auto', height: '80px', objectFit: 'contain', width: 'auto' }}
                        sxSkeleton={{ height: '80px', width: '80px', }}
                    />
                    <Typography sx={{ textAlign: 'center' }} variant="body1">HKedCity</Typography>
                </Stack>
                <Stack onClick={handleHKExamClicked} sx={{ alignItems: 'center', cursor: 'pointer', }}>
                    <BoxImage
                        src="https://www.pohtyh.edu.hk/Files/LearningAndTeacher/PromotionReading/HKExam.jpg"
                        sx={{ aspectRatio: 'auto', height: '80px', objectFit: 'contain', width: 'auto' }}
                        sxSkeleton={{ height: '80px', width: '80px', }}
                    />
                    <Typography sx={{ textAlign: 'center' }} variant="body1">試題特藏</Typography>
                </Stack>
            </Box>
        </Box>
    );
};
export default BoxEBook;