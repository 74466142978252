// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component
import BoxNews from './BoxNews';
import BoxSlide from './BoxSlide';
import BoxUsefulLinks from './BoxUsefulLinks';
import DialogPopup from './DialogPopup';
import SpeedDialFocus from './SpeedDialFocus';
import Top from '../Top/Top';
import Footer from '../Footer/Footer';

const Home = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Home Rendered');

    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <>
            <Top />
            <BoxSlide />
            <BoxNews />
            <BoxUsefulLinks />
            <Footer />
            <DialogPopup />
            <SpeedDialFocus />
        </>
    );
};
export default Home;