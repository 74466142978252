// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Box, Button, MenuItem, Popper, Typography, useMediaQuery } from '@mui/material';

// TODO: Import MUI icon
import { ArrowRight } from '@mui/icons-material';

// Import context
import { Context } from '../Common/Context';

// TODO: Import custom component
import BoxDropDown from './BoxDropDown';

export default function BoxMenuItems({ items, depthLevel }) {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const [dropdown, setDropdown] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let ref = React.useRef();

    // TODO: Define event
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 1200px)');

    const onMouseEnter = (event) => {
        setDropdown(true);
        setAnchorEl(event.currentTarget);
    };

    const onMouseLeave = () => {
        setDropdown(false);
        setAnchorEl(null);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <Box
            ref={ref}
            onClick={(event) => {
                if (items.Page) {
                    navigate(items.Page);
                }
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{ position: 'relative' }}
        >
            <MenuItem sx={{ display: 'block', px: 1, py: 0 }}>
                <Button
                    sx={{
                        display: 'flex',
                        border: 'none',
                        backgroundColor: 'transparent',
                        color:'white',
                        cursor: 'pointer',
                        justifyContent: 'start',
                        width: 1,
                        '&:hover': { color: 'secondary.main' },
                    }}
                >
                    <Box
                        sx={{ flex: 1 }}
                    >
                        <Typography
                            component="p"
                            sx={{
                                fontSize: depthLevel > 0 || isSmallScreen ? '1rem' : '1.25rem',
                                lineHeight: '1.5rem',
                                textAlign: depthLevel > 0 ? 'left' : 'center'
                            }}
                            variant="button"
                        >
                            {items.Title['CHI']}
                        </Typography>
                        {
                            items.Title['ENG'] !== items.Title['CHI'] &&
                            <Typography
                                component="p"
                                sx={{
                                    fontSize: { xs: '0.875rem', lg: '1rem' },
                                    lineHeight: '1.25rem',
                                    textAlign: depthLevel > 0 ? 'left' : 'center'
                                }}
                                variant="button"
                            >
                                {items.Title['ENG']}
                            </Typography>
                        }
                    </Box>
                    {depthLevel > 0 && items.Subtitles && <ArrowRight sx={{ ml: 2 }} />}
                </Button>
                {
                    items.Subtitles &&
                    <Popper
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        onClose={onMouseLeave}
                        open={dropdown}
                        sx={{ zIndex: context.Top_ZIndex }}
                    >
                        <BoxDropDown depthLevel={depthLevel} submenus={items.Subtitles} />
                    </Popper>
                }
            </MenuItem>
        </Box>
    );
}