// TODO: Import React module
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// TODO: Import MUI module;
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OpacityIcon from '@mui/icons-material/Opacity';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterIcon from '@mui/icons-material/Water';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

// TODO: Import custom component
import {  WebservicesYiYuan } from '../../../Common/Context';

export default function PlantInfo() {
    // TODO: Define useContext

    // TODO: Define variable
    const [searchParams] = useSearchParams();

    const [sunshine, setSunshine] = React.useState('');
    const [temp, setTemp] = React.useState('');
    const [watering, setWatering] = React.useState('');
    const [humidity, setHumidity] = React.useState('');
    const [manure, setManure] = React.useState('');
    const [seasonal, setSeasonal] = React.useState('');

    // TODO: Define event
    React.useEffect(() => {
        const formData = new FormData();
        formData.append('action', 'GetInfo');
        formData.append('id', searchParams.get('id'));

        fetch(WebservicesYiYuan, { method: 'POST', body: formData, })
            .then((response) => response.json())
            .then((result) => {
                setSunshine(result['Sunshine'] ? result['Sunshine'] : '');
                setTemp(result['Temp'] ? result['Temp'] : '');
                setWatering(result['Watering'] ? result['Watering'] : '');
                setHumidity(result['Humidity'] ? result['Humidity'] : '');
                setManure(result['Manure'] ? result['Manure'] : '');
                setSeasonal(result['Seasonal'] ? result['Seasonal'] : '');
            })
            .catch((error) => { console.error('Error:', error); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: Define function

    // TODO: Return component
    const GridItem = (icon, color, title, content) => {
        return (
            <Grid xs={6} md={4}>
                <Stack direction="column">
                    <Stack
                        direction="row"
                        sx={{ alignItems: 'center' }}
                    >
                        {icon}
                        <Typography
                            color={color}
                            sx={{ fontWeight: 'bold' }}
                            variant="h6"
                        >
                            {title}
                        </Typography>
                    </Stack>
                    <Typography variant="body1">{content}</Typography>
                </Stack>
            </Grid>
        );
    }

    return (
        <Grid
            container
            spacing={5}
            sx={{ m: 2 }}
        >
            {sunshine && GridItem(<WbSunnyIcon color="warning" />, 'warning.dark', '日照', sunshine)}
            {temp && GridItem(<ThermostatIcon color="error" />, 'error.main', '生長適溫', temp)}
            {watering && GridItem(<OpacityIcon color="primary" />, 'primary.dark', '澆水', watering)}
            {humidity && GridItem(<WaterIcon color="info" />, 'info.main', '濕度', humidity)}
            {manure && GridItem(<ScatterPlotIcon color="warning" />, 'warning.dark', '施肥', manure)}
            {seasonal && GridItem(<CalendarMonthIcon color="success" />, 'success.dark', '作物時間', seasonal)}
        </Grid>
    );
}