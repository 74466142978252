// TODO: Import React module
import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import custom component
import { ContentTitle, } from '../Common/TypographyTYH';
import BoxImage from '../Common/BoxImage';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

const Graduation = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component Graduation Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const data = [{
        year: 2024,
        name: '離別的回眸',
        description: '<p>創作：黃柏銘 / 梅文熙</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/2324.mp3',

    }, {
        year: 2023,
        name: '唱一首歌給我',
        description: '<p>曲詞：6A 李昊樺 / 編：6A 李昊樺，朱慧敏老師 /唱：6E葉學賢，蔡濬溱老師，黃偉豪老師</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/2223.wav',

    }, {
        year: 2022,
        name: 'Everything will be OK',
        description: '<p>創作:呂依銘師姐/校友陳凱迪</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/2122.mp3',

    }, {
        year: 2021,
        name: 'It\'s time to say goodbye',
        description: '<p>創作:黃淑嫻/胡胤廷/林朗傑<br>主唱:趙詠琪/胡胤廷<br>樂器演奏:林朗傑/黃淑嫻<br>編曲:胡胤廷</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/2021.mp3',

    }, {
        year: 2020,
        name: '結伴',
        description: '<p>創作:劉慧珍/禤漢彬/余鎧軒/高梓桓/葉雅姿/吳婉婷<br>主唱:李藹盈/禤漢彬/余鎧軒/吳婉婷/謝詩呈/葉雅姿<br>樂器演奏:Drum:謝梓聰/Saxophone:葉青霖<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Flute:劉慧珍/Trombone:柳鈺晶<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eurphonium:高梓桓<br>編曲:朱慧敏老師</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/1920.mp3',
    }, {
        year: 2019,
        name: '我倆',
        description: '<p>創作:翁羨桐/賴曉藍<br>主唱:何曉晴/謝天恩<br>樂器演奏:Violin:駱昕/Flute:吳嘉蔚/翁羨桐<br>編曲:朱慧敏老師</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/1819.mp3',
    }, {
        year: 2018,
        name: '相約在走廊',
        description: '<p>創作:朱慧敏老師<br>主唱:陳靖嵐/廖卓琳<br>編曲:朱慧敏老師</p>',
        filename: 'https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/1718.mp3',
    },];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Stack className="SectionGraduation" spacing={2} sx={{ alignItems: 'center', m: 5 }}>
            <ContentTitle text={Text.MusicalWork.Graduation[context.language]} />
            <Grid container spacing={2}>
                {
                    data.map((item, i) => (
                        <Grid key={i} xs={12} md={6}>
                            <Stack direction="row" spacing={2}>
                                <Avatar sx={{ fontSize: '1rem' }}>{item.year}</Avatar>
                                <Box sx={{ width: 1 }}>
                                    <Typography
                                        color="black"
                                        dangerouslySetInnerHTML={{ __html: item.name }}
                                        variant="subtitle1"
                                    />
                                    <Typography
                                        color="text.secondary"
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                        variant="subtitle2"
                                    />
                                    <ReactAudioPlayer controls src={item.filename} style={{ width: '100%' }} />
                                </Box>
                            </Stack>
                        </Grid>
                    ))
                }
            </Grid>
            <Divider variant="inset" />
            <BoxImage
                src="https://www.pohtyh.edu.hk/Files/MusicalWork/Graduation/Graduation.jpg"
                sx={{ maxWidth: '640px', width: 1 }}
                sxSkeleton={{ maxWidth: '640px', width: 1 }}
            />
        </Stack>
    );
};
export default Graduation;