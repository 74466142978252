// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';

import LiveDataTable from './SolarPhotovoltaicSystems/LiveDataTable.js';
import BoxChartToday from './SolarPhotovoltaicSystems/BoxChartToday.js';
import BoxChartMonthly from './SolarPhotovoltaicSystems/BoxChartMonthly.js';
import BoxChartYearly from './SolarPhotovoltaicSystems/BoxChartYearly.js';

export default function SolarPhotovoltaicSystems() {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function
    function RenderSelfExploration() {
        return (
            <Box sx={{mt:2}}>
                <Typography
                    sx={{ color: 'primary.main' }}
                    variant="h6"
                >
                    {Text.CampusDevelopment.SolarPhotovoltaicSystems.SelfExplorationTitle[context.language]}
                </Typography>
                <List>
                    {
                        Text.CampusDevelopment.SolarPhotovoltaicSystems.SelfExplorationQuestion.map((row, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar>
                                    <Avatar sx={{ backgroundColor: 'primary.main', width: 24, height: 24 }}>
                                        <HelpOutlineIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={row[context.language]} />
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
        );
    }

    // TODO: Return component
    return (
        <BoxSubPage
            id="SolarPhotovoltaicSystems"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <Box>
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.CampusDevelopment.SolarPhotovoltaicSystems.Intro[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
            </Box>
            <LiveDataTable />
            <Typography sx={{ mt: 2 }} variant="h6">{Text.CampusDevelopment.SolarPhotovoltaicSystems.Chart[context.language]}</Typography>
            <BoxChartToday />
            <BoxChartMonthly />
            <BoxChartYearly />
            <RenderSelfExploration />
        </BoxSubPage>
    );
}