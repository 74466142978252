// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Article, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

const BoxHighlightedProgrammes = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxHighlightedProgrammes Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const items = [
        /*{
            Title: '樂高立體情景模型：清明上河圖',
            Intro: '',
            Thumb: 'https://www.newtownplaza.com.hk/wp-content/uploads/2024/09/NTP_LEGO_edirectory-885x1328px_aw02.jpg',
            URL: 'https://www.newtownplaza.com.hk/wp-content/uploads/2024/09/NTP_LEGO_edirectory-885x1328px_aw02.jpg',
        },
        {
            Title: '中華文化節大熊貓命名比賽',
            Intro: '',
            Thumb: 'https://media.oceanpark.com.hk/files/s3fs-public/giant-panda-2024-innerpage-desktop-tc.jpg',
            URL: 'https://www.oceanpark.com.hk/tc/park-experience/giant-panda-campaign-2024/naming-competition-2024',
        },
        {
            Title: '中華文化節2024',
            Intro: '由康樂及文化事務署以及弘揚中華文化辦公室策劃的首屆「中華文化節」於6月至9月進行，節目內容豐富多彩，包含多種類型的活動，大型演出包括壯美的舞劇、「中國戲曲節」的精選劇目，以及獲國家藝術基金肯定的本地優秀作品。另外，亦有多個音樂與歌舞節目，以及一同參有跨媒體演出、電影、展覽、講座、藝人談、大師班等，不少都適合親子加。',
            Thumb: 'https://gia.info.gov.hk/general/202404/18/P2024041800246_photo_1270591.jpg',
            URL: 'https://www.ccf.gov.hk/tc/',
        },*/
        {
            Title: '康樂及文化事務署——中國歷史文化活動',
            Intro: '各位家長，如有閒暇，不妨參加康樂及文化事務署舉辦的各項中國歷史文化活動，與兒女共享天倫樂之餘，順道認識博大精深的中華文化。',
            Thumb: 'https://www.lcsd.gov.hk/edutainment/upload/page_banner_3251_desktop_1.jpg',
            URL: 'https://www.lcsd.gov.hk/en/common/pdf/Chinese_History_Culture_Programmes_TC.pdf',
        },
    ];

    // TODO: Define event
    const handleClicked = (url) => {
        window.open(url, '_blank');
    };

    // TODO: Return component
    const RenderStackImage = ({ item }) => (
        <StackImage onClick={() => { handleClicked(item.URL) }} hover={true}>
            <BoxImage src={item.Thumb} />
            <Typography sx={{ alignItems: 'start', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <Article sx={{ color: 'white' }} />
                {item.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                items.map((item, i) => (
                    <Grid key={i} xs={6} md={4}>
                        <RenderStackImage item={item} />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default React.memo(BoxHighlightedProgrammes);