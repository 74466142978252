// TODO: Import React module
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// Import context
import { Context } from './Context';

// TODO: Import custom component

export default function BoxSubPageSubTitle(props) {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const location = useLocation();

    // TODO: Define event

    // TODO: Define function
    function GetTitle() {
        let title = '--';

        props.subtitles.forEach((subItem) => {
            if (subItem.Page === location.pathname) {
                title = subItem.Title[context.language];
                return;
            }
            if (subItem.Subtitles) {
                subItem.Subtitles.forEach((CCItem) => {
                    if (CCItem.Page === location.pathname) {
                        title = CCItem.Title[context.language];
                        return;
                    }
                })
            }
        })

        return title;
    }

    // TODO: Return component
    return (
        <Box id="BoxSubPageSubTitle" sx={{ mb: 2 }}>
            <Typography sx={{ color: 'primary.main', fontWeight:'bold' }} variant="h5">
                {`${GetTitle()}`}
            </Typography>
        </Box>
    );
}