// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context, } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import BoxForm from './BoxForm';

const MusicContest = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component MusicContest Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <BoxSubPage
            id="MusicContest"
            disableMenu={true}
            disableSubtitle={true}
            title={Text.MusicContest.Title[context.language]}
        >
            <BoxForm />
        </BoxSubPage>
    );
};
export default MusicContest;