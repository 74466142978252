// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Unstable_Grid2 as Grid, Link, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import Slide from './Slide_SchoolProfile.jpg';

import BoxSubPage from '../Common/BoxSubPage';
import ListText from '../Common/ListText';

const SchoolProfile = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component SchoolProfile Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={Slide}
            title={Text.Top.Menu['SchoolProfile'].Title[context.language]}
            subtitles={Text.Top.Menu['SchoolProfile'].Subtitles}
        >
            <Box id="SchoolProfile">
                <Grid container spacing={2}>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.SupervisorTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.Supervisor[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.PrincipalTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.Principal[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.AddressTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.Address[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.ContactNoTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.ContactNo}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.FaxNoTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.FaxNo}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.EmailTitle[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Typography sx={{ color: 'black' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.Email}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            Instagram
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Link href="https://www.instagram.com/pohtyh_official/" rel="noopener" target="_blank">
                            <Box component="img" src="https://www.pohtyh.edu.hk/Files/SchoolProfile/QR.png" sx={{ width: '128px' }} />
                        </Link>
                    </Grid>
                    <Grid xs={3} md={2}>
                        <Typography sx={{ color: 'primary.main' }} variant="body1">
                            {Text.SchoolProfile.SchoolProfile.Transportation[context.language]}
                        </Typography>
                    </Grid>
                    <Grid xs={7} md={10}>
                        <Box
                            component="img"
                            src="https://www.pohtyh.edu.hk/Files/SchoolProfile/Map.jpg"
                            sx={{
                                //height: 1,
                                //objectFit: 'cover',
                                maxWidth: 1,
                            }}
                        />
                        <ListText lines={Text.SchoolProfile.SchoolProfile.TransportationDetail[context.language]} />
                    </Grid>
                </Grid>
                {/*<Box sx={{ height: '100vh', mt: 2, width: 1 }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1845.5975688152407!2d114.2580585!3d22.3084586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340403f24ad2d22b%3A0x2d17f88bdb6334b8!2z5Y2a5oSb6Yar6Zmi5YWr5Y2B6YCx5bm06YSn6Iux5Zac5Lit5a24!5e0!3m2!1szh-TW!2shk!4v1689657172717!5m2!1szh-TW!2shk"
                        width="100%"
                        height="480"
                        loading="lazy"
                        style={{ border: 0 }}
                        title={Text.Common.SchoolName.ENG}
                    >
                    </iframe>
                </Box>*/}
            </Box>
        </BoxSubPage>
    );
};
export default SchoolProfile;