// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import context
import { Context } from './Context';

// TODO: Import custom component

export const BoxGridColumnsContainer = ({ children }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxGridColumnsContainer Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define Imperative Handle

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
            <Box sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: {
                    xs: `repeat(auto-fit, minmax(${context.Common_Image_Width.xs}, ${context.Common_Image_Width.xs}))`,
                    sm: `repeat(auto-fit, minmax(${context.Common_Image_Width.sm}, ${context.Common_Image_Width.sm}))`,
                    md: `repeat(auto-fit, minmax(${context.Common_Image_Width.md}, ${context.Common_Image_Width.md}))`,
                    lg: `repeat(auto-fit, minmax(${context.Common_Image_Width.lg}, ${context.Common_Image_Width.lg}))`,
            },
                justifyContent:'center',
                mb: 2,
            }}>
                {children}
            </Box>
    );
};

export const BoxGridColumnsFullWidth = ({ children }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxGridColumnsFullWidth Rendered');

    // TODO: Return component
    return (<Box sx={{ gridColumn: '1 / -1' }}>{children}</Box>);
};