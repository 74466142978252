// TODO: Import React module
import * as React from 'react';
import HTMLFlipBook from 'react-pageflip';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogContent ,Stack, } from '@mui/material';

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import { ContentTitle, } from '../Common/TypographyTYH';

const EBook = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component EBook Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const books = {
        1: {
            Pages: [
                '01.png', '02.png', '03.png', '04.png', '05.png', '06.png', '07.png', '08.png', '09.png', '10.png',
                '11.png', '12.png', '13.png', '14.png', '15.png', '16.png', '17.png', '18.png', '19.png', '20.png',
                '21.png', '22.png', '23.png',
            ],
            Path: 'https://www.pohtyh.edu.hk/Files/MusicalWork/eBook1/',
            Width: 380,
            Height: 570,
        },
        2: {
            Pages: [
                '01.png', '02.png', '03.png', '04.png', '05.png', '06.png', '07.png', '08.png', '09.png', '10.png',
                '11.png', '12.png', '13.png', '14.png', '15.png', '16.png', '17.png', '18.png', '19.png', '20.png',
                '21.png', '22.png', '23.png', '24.png', '25.png', '26.png', '27.png',
            ],
            Path: 'https://www.pohtyh.edu.hk/Files/MusicalWork/eBook2/',
            Width: 380,
            Height: 560,
        },
        3: {
            Pages: [
                '01.png', '02.png', '03.png', '04.png', '05.png', '06.png', '07.png', '08.png', '09.png', '10.png',
                '11.png', '12.png', '13.png', '14.png', '15.png', '16.png', '17.png', '18.png', '19.png', '20.png',
                '21.png', '22.png', '23.png', '24.png', '25.png', '26.png', '27.png', '28.png',
            ],
            Path: 'https://www.pohtyh.edu.hk/Files/MusicalWork/eBook3/',
            Width: 380,
            Height: 560,
        },
    };
    const [currentBook, setCurrentBook] = React.useState(null);

    // TODO: Define event
    const handleDialogOpen = (i) => {
        setCurrentBook(i);
    };

    const handleDialogClose = () => {
        setCurrentBook(null);
    };

    // TODO: Define function

    // TODO: Return component
    const FlipBook = () => (
        <HTMLFlipBook
            showCover={true}
            width={books[currentBook].Width}
            height={books[currentBook].Height}
            minWidth={books[currentBook].Width}
            minHeight={books[currentBook].Height}
            size="stretch"
            style={{ backgroundColor: '#EEEEEE', border: '#666666 solid 5px' }}
        >
            {
                books[currentBook].Pages.map((item, i) => (
                    <div key={i}>
                        <img alt={item.Title} loading="lazy" src={books[currentBook].Path + item} style={{ width: '100%' }} />
                    </div>
                ))
            }
        </HTMLFlipBook>
    );
    const DialogBook = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={currentBook !== null}
                onClose={handleDialogClose}
            >
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <FlipBook />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Stack className="SectionEBook" spacing={2} sx={{ m: 5 }}>
            <ContentTitle text={Text.MusicalWork.CreativeCollection[context.language]} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    Object.keys(books).map((key, i) => (
                        <Button key={i} onClick={() => { handleDialogOpen(key) }}>
                            <Box
                                component="img"
                                src={`${books[key].Path}${books[key].Pages[0]}`}
                                sx={{ width: { xs: 1, sm: '200px' } }}
                            />
                        </Button>
                    ))
                }
            </Box>
            <DialogBook />
        </Stack>
    );
};
export default EBook;