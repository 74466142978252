// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Alert, Box, Button, Paper, Snackbar, Stack, TextField, Typography } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { Context, Webservices } from '../Common/Context';
import { Text } from '../Common/Text';

import BoxSubPage from '../Common/BoxSubPage';
import SelectClasses from './SelectClasses';

const UniPassParent = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component UniPassParent Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const SelectClassesRef = React.useRef();
    const [studentName, setStudentName] = React.useState('');
    const [sent, setSent] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    // TODO: Define event
    const handleButtonClick = () => {
        if (SelectClassesRef.current.GetValue() === '%' || studentName.length === 0) {
            setOpen(true);
            return;
        }

        const fetchData = async () => {
            const action = 'UniPassParent_Submit';
            const formData = new FormData();
            formData.append('action', action);
            formData.append('className', SelectClassesRef.current.GetValue());
            formData.append('studentName', studentName);

            fetch(Webservices, { method: 'POST', body: formData })
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        console.log(`${action}:`, 'Data received');
                        setSent(true);
                    } else {
                        console.log(`${action}:`, 'Can not get info');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        fetchData();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            title={Text.UniPassParent.Title[context.language]}
        >
            <Box id="UniPassParent">
                <Typography variant="body1">{Text.UniPassParent.Intro[context.language]}</Typography>
                <Paper elevation={3} sx={{ my: 2 }}>
                    {
                        sent ?
                            <Typography sx={{ p: 2, textAlign: 'center' }} variant="h6">
                                {Text.UniPassParent.Sent[context.language]}
                            </Typography> :
                            <Stack spacing={2} sx={{ p: 2 }}>
                                <Typography>{Text.UniPassParent.Description[context.language]}</Typography>
                                <Box sx={{ display: 'flex', gap: 2, }}>
                                    <SelectClasses ref={SelectClassesRef} />
                                    <TextField
                                        label={Text.Common.StudentName[context.language]}
                                        onChange={(e) => { setStudentName(e.target.value) }}
                                        sx={{ flex: 1 }}
                                        value={studentName}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="primary" onClick={handleButtonClick}>
                                        {Text.Common.Submit[context.language]}
                                    </Button>
                                </Box>
                            </Stack>
                    }
                </Paper>
            </Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose} open={open}>
                <Alert onClose={handleClose} severity="error" sx={{ width: 1 }}>
                    {Text.UniPassParent.Error[context.language]}
                </Alert>
            </Snackbar>
        </BoxSubPage>
    );
}
export default UniPassParent;