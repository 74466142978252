// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Import context

// TODO: Import custom component
import BoxSubPageSelectMenu from './BoxSubPageSelectMenu';

export default function BoxSubPageTitle(props) {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box id="BoxSubPageTitle">
            <Container sx={{ p: 2 }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, }}>
                    <Box sx={{
                        backgroundColor: 'primary.main',
                        clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)',
                        pl: 2,
                        pr: 8,
                        py: 1,
                    }}>
                        <Typography sx={{ color: 'white', fontSize: '1.5rem' }}>{props.title}</Typography>
                    </Box>
                </Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Typography sx={{ color: 'primary.main', fontSize: '1.25rem' }}>{props.title}</Typography>
                    {
                        props.disableMenu !== true &&
                        <BoxSubPageSelectMenu subtitles={props.subtitles} />
                    }
                </Breadcrumbs>
            </Container>
        </Box>
    );
}