// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxSubPage from '../Common/BoxSubPage';
import { ContentTitle, MultipleRows, SessionTitle, } from '../Common/TypographyTYH';
import ListText from '../Common/ListText';

const IT = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component IT Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            id="IT"
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <ListText lines={Text.CampusDevelopment.IT.Intro[context.language]} />
            <ContentTitle text={Text.CampusDevelopment.IT.eLearningTitle[context.language]} />
            <Box sx={{ pl: 2 }} >
                <SessionTitle text={Text.CampusDevelopment.IT.eCircularTitle[context.language]} />
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.CampusDevelopment.IT.eCircularContent[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
                <SessionTitle text={Text.CampusDevelopment.IT.ePaymentTitle[context.language]} />
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.CampusDevelopment.IT.ePaymentContent[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
                <SessionTitle text={Text.CampusDevelopment.IT.ParentCardTitle[context.language]} />
                <MultipleRows rows={Text.CampusDevelopment.IT.ParentCardContent[context.language]} />
            </Box>
            <ContentTitle text={Text.CampusDevelopment.IT.LTTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.IT.LTContent[context.language]} />
            <ContentTitle text={Text.CampusDevelopment.IT.AdminTitle[context.language]} />
            <MultipleRows rows={Text.CampusDevelopment.IT.AdminContent[context.language]} />
        </BoxSubPage>
    );
};
export default IT;