// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Unstable_Grid2 as Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { Article, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import BoxImage from '../Common/BoxImage';
import StackImage from '../Common/StackImage';

const BoxItems = ({ onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxItems Rendered');

    // TODO: Define useContext

    // TODO: Define variable
    const coverages = [
        {
            Title: '2024年度校友校董選舉結果公告',
            Intro: '',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Alumni/2024年度校友校董選舉結果公告.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Alumni/2024年度校友校董選舉結果公告.pdf',
        },
        {
            Title: '2024年度校友校董選舉通知',
            Intro: '',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Alumni/2024年度校友校董選舉通知.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Alumni/2024年度校友校董選舉通知.pdf',
        },
        {
            Title: '2023年度校友校董選舉資料',
            Intro: '',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Alumni/2023AlumniManagerResult.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Alumni/2023AlumniManagerResult.jpg',
        },
        {
            Title: '2023年度校友校董選舉通知',
            Intro: '',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Alumni/2023AlumniDirectorElectionInformation.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Alumni/2023AlumniDirectorElectionInformation.pdf',
        },
        {
            Title: '2022年度校友校董選舉資料',
            Intro: '',
            Thumb: 'https://www.pohtyh.edu.hk/Files/Alumni/2022AlumniManagerResult.jpg',
            Filename: 'https://www.pohtyh.edu.hk/Files/Alumni/2022AlumniManagerResult.jpg',
        },
    ];

    // TODO: Define event
    const handleClicked = (coverage) => { onClick(coverage) };

    // TODO: Return component
    const RenderStackImage = ({ coverage }) => (
        <StackImage onClick={() => { handleClicked(coverage) }} hover={true}>
            <BoxImage src={coverage.Thumb} />
            <Typography sx={{ alignItems: 'center', color: 'white', display: 'flex', gap: 1, mt: 1, }} variant="body1">
                <Article sx={{ color: 'white' }} />
                {coverage.Title}
            </Typography>
        </StackImage>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {
                coverages.map((coverage, i) => (
                    <Grid key={i} xs={6} md={4}>
                        <RenderStackImage coverage={coverage} />
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default React.memo(BoxItems);