// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import MUI icon

// Import context
//import { Context, Webservices } from '../Common/Context';
//import { Text } from '../Common/Text';

// TODO: Import custom component
import BoxMenuItems from './BoxMenuItems';

export default function BoxDropDown({ submenus, depthLevel }) {
    // TODO: Define useContext
    //const context = React.useContext(Context);

    // TODO: Define variable
    depthLevel = depthLevel + 1;

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{
            border:'1px solid #060',
            padding: '0.5rem 0',
            backgroundColor: 'primary.main',
            borderRadius: '0.5rem',
        }}>
            {
                submenus.map((submenu, i) => (
                    <BoxMenuItems key={i} depthLevel={depthLevel} items={submenu} />
                ))
            }
        </Box>
    );
}