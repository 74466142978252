// TODO: Import React module
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// Import context
import { Context } from '../Common/Context';

// TODO: Import custom component

export default function BoxSubPageLeftMenu(props) {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable
    const location = useLocation();

    // TODO: Define event
    const navigate = useNavigate();

    const handleMenuClick = (page) => {
        navigate(page);
    }

    // TODO: Define function
    function RenderListItemText(titleChi, titleEng) {
        return (
            <ListItemText primary={
                <>
                    <Typography
                        component="p"
                        sx={{ fontSize: '1rem', lineHeight: '1rem', textAlign: 'left' }}
                        variant="body"
                    >
                        {titleChi}
                    </Typography>
                    <Typography
                        component="p"
                        sx={{ textAlign: 'left' }}
                        variant="button"
                    >
                        {titleEng}
                    </Typography>
                </>
            } />
        )
    }

    // TODO: Return component
    return (
        <Box id="BoxSubPageLeftMenu" sx={{ display: { xs: 'none', md:'block'} } }>
            <List sx={{ maxWidth: '350px', minWidth: '350px' }}>
                {
                    props.subtitles.map((subItem, i) => (
                        <Box key={i}>
                            <Box
                                sx={{
                                    backgroundColor: location.pathname === subItem.Page ? 'primary.main' : 'secondary.main',
                                    clipPath: 'polygon(0% 0%, 80% 0%, 90% 50%, 80% 100%, 0% 100%)',
                                    mb: 1,
                                }}
                            >
                                <ListItemButton
                                    onClick={(e) => {
                                        if (subItem.Page) {
                                            handleMenuClick(subItem.Page);
                                        } else if (!subItem.Page && subItem.Subtitles[0].Page) {
                                            handleMenuClick(subItem.Subtitles[0].Page);
                                        }
                                    }}
                                    sx={{ color: location.pathname === subItem.Page ? 'white' : 'black' }}
                                >
                                    {RenderListItemText(subItem.Title[context.language], subItem.Title.ENG)}
                                </ListItemButton>
                            </Box>
                            {
                                // TODO: Generate Sub-Title
                                subItem.Subtitles &&
                                <Collapse in={true}>
                                    <List disablePadding>
                                        {
                                                subItem.Subtitles.map((CCItem, j) => (
                                                <Box
                                                    key={j}
                                                    sx={{
                                                        backgroundColor: location.pathname === CCItem.Page ? 'primary.main' : 'secondary.main',
                                                        clipPath: 'polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%)',
                                                        mb: 1,
                                                        pl: 4.5,
                                                    }}
                                                >
                                                    <ListItemButton
                                                        onClick={(e) => {
                                                            if (CCItem.Page) {
                                                                handleMenuClick(CCItem.Page);
                                                            }
                                                        }}
                                                        sx={{ color: location.pathname === CCItem.Page ? 'white' : 'black' }}
                                                    >
                                                        {RenderListItemText(CCItem.Title[context.language], CCItem.Title.ENG)}
                                                    </ListItemButton>
                                                </Box>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            }
                        </Box>
                    ))
                }
            </List>
        </Box>
    );
}