// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {  } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component
import { } from '../Common/Context';

import BoxSubPage from '../Common/BoxSubPage';
import { MultipleRows } from '../Common/TypographyTYH';

const PrivacyPolicyOfMyTYH = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component PrivacyPolicyOfMyTYH Rendered');

    // TODO: Define useContext
    //const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <BoxSubPage
            disableMenu={true}
            disableSubtitle={true}
            id="MyTYH隱私權政策"
            title="MyTYH隱私權政策"
        >
            <MultipleRows
                rows={[
                    '歡迎使用「MyTYH」（以下簡稱本程式），為了讓您能夠安心使用本程式的各項服務與資訊，特此向您說明本程式的隱私權保護政策，以保障您的權益，請您詳閱下列內容：',
                    '一、隱私權保護政策的適用範圍',
                    '隱私權保護政策內容，包括本程式如何處理在您使用服務時收集到的個人識別資料。隱私權保護政策不適用於本程式以外的相關連結網站，也不適用於非本程式所委託或參與管理的人員。',
                    '二、個人資料的蒐集、處理及利用方式',
                    '當您使用本程式所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本程式不會將個人資料用於其他用途。',
                    '本程式在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。',
                    '於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進服務的參考依據，此記錄為內部應用，決不對外公佈。',
                    '為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。',
                    '三、資料之保護',
                    '本程式主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。',
                    '如因業務需要有必要委託其他單位提供服務時，本程式亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。',
                    '四、對外的相關連結',
                    '本程式提供其他網站的網路連結，您也可經由本程式所提供的連結，點選進入其他網站。但該連結網站不適用本程式的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。',
                    '五、與第三人共用個人資料之政策',
                    '本程式絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。',
                    '六、隱私權保護政策之修正',
                    '本程式隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。'
                ]}
                sx={{ width: 1 }}
            />
        </BoxSubPage>
    );
}
export default PrivacyPolicyOfMyTYH;