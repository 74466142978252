// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, IconButton, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { PlayArrow } from '@mui/icons-material';

// TODO: Import context
import { Context } from '../Common/Context';

// TODO: Import custom component
import { BoxGridColumnsContainer, BoxGridColumnsFullWidth } from '../Common/BoxGridColumns';
import BoxImage from '../Common/BoxImage';

const BoxContent = ({ info, onClick }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxContent Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleImageClicked = (i) => { onClick(`https://www.pohtyh.edu.hk/Files/Album/${info.eventID}/${info.images[i]}`) }

    // TODO: Return component
    return (
        <BoxGridColumnsContainer>
            <BoxGridColumnsFullWidth sx={{ gridColumn: '1 / -1' }}>
                <Typography
                    dangerouslySetInnerHTML={{ __html: info.eventContent }}
                    sx={{ color: 'black', whiteSpace: 'pre-line', }}
                    variant="body1"
                />
            </BoxGridColumnsFullWidth>
            {
                info.images &&
                info.images.map((image, i) => (
                    <Box key={i} onClick={() => { handleImageClicked(i) }} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        {

                            image.includes('mp4.jpg') ?
                                <Box sx={{ position: 'relative', width: context.Common_Image_Width, }}>
                                    <BoxImage
                                        hover={true}
                                        src={`https://www.pohtyh.edu.hk/Files/Album/${info.eventID}/thumbs/${image}`}
                                    />
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '56px',
                                            height: '56px',
                                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)', },
                                        }}
                                    >
                                        <PlayArrow />
                                    </IconButton>
                                </Box> :
                                <BoxImage
                                    hover={true}
                                    src={`https://www.pohtyh.edu.hk/Files/Album/${info.eventID}/thumbs/${image}`}
                                />
                        }
                    </Box>
                ))
            }
        </BoxGridColumnsContainer>
    );
};
export default React.memo(BoxContent);