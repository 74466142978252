// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import context

// TODO: Import custom component

const BoxVideo = () => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component BoxVideo Rendered');

    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <iframe
            width="560" height="315"
            src="https://www.youtube.com/embed/MfGzf-Bx0e8"
            style={{ aspectRatio: '16/9', border: 0, width: '100%', height: '100%' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="�H�R����A�{ģ���"
        >
        </iframe>
    );
}
export default BoxVideo;