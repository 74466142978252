// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon
import { CancelRounded, } from '@mui/icons-material';

// TODO: Import context

// TODO: Import custom component

const DialogCoverage = ({ currentItem, onClose }) => {
    if (window.location.href.startsWith('http://localhost')) console.log('Component DialogCoverage Rendered');

    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event
    const handleDialogClosed = () => {
        onClose(null);
    }

    // TODO: Return component
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(currentItem)}
            onClose={handleDialogClosed}
        >
            {
                currentItem &&
                <>
                    <DialogActions sx={{ pb: 0 }}>
                        <IconButton onClick={handleDialogClosed} sx={{ p: 0 }}>
                            <CancelRounded />
                        </IconButton>
                    </DialogActions>
                    <DialogTitle>{currentItem.Title}</DialogTitle>
                    <DialogContent>
                        <Stack>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: currentItem.Intro }}
                                variant="body1"
                            />
                            <iframe
                                src={currentItem.Filename}
                                style={{ border: 0, height: '480px', width: '100%', }}
                                title={currentItem.Title}
                            />
                        </Stack>
                    </DialogContent>
                </>
            }
        </Dialog>
    );
}

export default DialogCoverage;