// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Fab, } from '@mui/material';

// TODO: Import MUI icon
import { KeyboardDoubleArrowDown } from '@mui/icons-material';

// Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

const BoxArrowButton = ({ scrollToY }) => {
    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event
    const handleButtonClicked = () => {
        if (scrollToY) {
            window.scrollTo({ top: scrollToY, behavior: 'smooth', });
        }
    };

    // TODO: Return component
    return (
        <Fab
            color="primary"
            onClick={handleButtonClicked}
            sx={{
                bottom: 10,
                left: '50%',
                position: 'fixed',
                transform: 'translate(-50%, -50%)',
            }}
            variant="extended"
        >
            <KeyboardDoubleArrowDown sx={{
                animation: "flash 1s infinite",
                "@keyframes flash": {
                    "0%": { opacity: 1 },
                    "50%": { opacity: 0.5 },
                    "100%": { opacity: 1 }
                }
            }} />
            {Text.Home.More.Title[context.language]}
        </Fab>
    );
};
export default BoxArrowButton;