// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// TODO: Import context
import { Context } from '../Common/Context';
import { Text } from '../Common/Text';

// TODO: Import custom component

import BoxSubPage from '../Common/BoxSubPage';

export default function CarbonFootprint() {
    if (window.location.href.startsWith('http://localhost')) console.log('Component CarbonFootprint Rendered');

    // TODO: Define useContext
    const context = React.useContext(Context);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <BoxSubPage
            image={'https://www.pohtyh.edu.hk/Files/CampusDevelopment/Slide_CD.jpg'}
            title={Text.Top.Menu['CampusDevelopment'].Title[context.language]}
            subtitles={Text.Top.Menu['CampusDevelopment'].Subtitles}
        >
            <Box id="CarbonFootprint">
                <Typography
                    dangerouslySetInnerHTML={{ __html: Text.CampusDevelopment.CarbonFootprint.Content[context.language] }}
                    sx={{ color: 'black' }}
                    variant="body1"
                />
            </Box>
        </BoxSubPage>
    );
}